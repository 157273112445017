<script>
    let MainDiv;
    let SecondDiv;
    let BindText;
    export let text = 'Learn More';
    export let TextColor = 'black';
    export let HoverText = 'white';
    export let ColorHover = 'black';
    export let bgheight = '0.15vw';
    export let bgwidth = '4.167vw';
    export let HoverHeight = '1.8vw';
    export let HoverWidth = '6vw';
</script>

<div class="main-div" bind:this={MainDiv} on:mouseenter={() =>{
    SecondDiv.style.backgroundColor = ColorHover;
    SecondDiv.style.height = HoverHeight;
    SecondDiv.style.width = HoverWidth;
    SecondDiv.style.left = 0;
    BindText.style.color= HoverText;
}}
on:mouseleave={()=>{
    SecondDiv.style.height = bgheight;
    SecondDiv.style.width = bgwidth;
    BindText.style.color= TextColor;
    SecondDiv.style.left = '1.2vw';
}}
>
    <p style="font-weight:800;font-size:0.729vw; color:{TextColor}; padding-left: 0.5vw;
    padding-top: 0.7vw;position: relative; z-index:10;transition-duration: 1s;" bind:this={BindText} >
        {text}
    </p>
    <div class="second-div" bind:this={SecondDiv} style="width:{bgwidth};
    height:{bgheight}; background-color: {ColorHover};"/>
</div>

<style>
    p {
        font-family: "Orbitron";
        margin: 0;
    }
    .main-div {
        position: relative;
        width: 6.25vw;
        height: 2.083vw;
        background: transparent;
        overflow: hidden;
        cursor: pointer;
    }
    .second-div {
        position: absolute;
    transition-duration: 0.5s;
    left: 1.2vw;
    bottom: 0;
    }
</style>
