<script>
    import Button from '../../../reusableitems/learnbutton.svelte';
    import DesktopButton from '../../../reusableitems/DesktopButton.svelte';
    let state1 = '';
</script>

<div class="main">
    <div class="sections {state1}">
        <div
            on:mouseenter={() => (state1 = 'gamers-hover')}
            on:mouseleave={() =>
                (state1 = state1 == 'gamers-hover' ? '' : state1)}
            id="gamers"
        >
            <div class=" contents">
                <div class="sidetext">Gamers</div>
                <div class="h1">GAMERS</div>
            </div>
            <div class="img-wrap">
                <img class="game" src="images/section2/healer.png" alt="" />
            </div>
            <div class="p">
                <div class="text1">
                    Play in an ever changing world in which you aren’t merely a
                    player and also a creator and administrator.
                </div>
                <div
                    class="button"
                    on:click={() => {
                        location.href = '/gamers';
                    }}
                >
                    <Button
                        TextColor="white"
                        ColorHover="white"
                        HoverText="black"
                    />
                </div>
                <div class="Lbtn" style="margin-top:45vh; margin-left:-1vw;">
                    <DesktopButton
                        text="Join Games Governance Council"
                        text2="100"
                        TextColor2="transparent"
                        TextColor="white"
                        TextColorAFter="black"
                        TextSize2="0.521vw"
                        TextSize="1.111vh"
                        width="28.611vh"
                        BackColor="#transparent"
                        AnimationColor="white"
                        height="3.704vh"
                        borderClr="0.1vw solid white"
                        BorderClrAfter="0.1vw solid transparent"
                        state = "Games"
                    />
                </div>
            </div>
        </div>
        <div
            on:mouseenter={() => (state1 = 'artists-hover')}
            on:mouseleave={() =>
                (state1 = state1 == 'artists-hover' ? '' : state1)}
            id="artists"
        >
            <div class=" contents">
                <div class="sidetext">Artists & Musicians</div>
                <div class="h1">ARTISTS & <span>MUSICIANS</span></div>
            </div>
            <div
                style="margin-top: max(3vh, 16.2px);"
                class="button"
                on:click={() => {
                    location.href = '/artist';
                }}
            >
                <Button TextColor="black" ColorHover="black" HoverText="aqua" />
            </div>
            <div class="img-wrap">
                <img class="statue" src="images/section2/statue.svg" alt="" />
            </div>
            <div class="p">
                <div class="text1">
                    A future that expands your 2D canvas infinitely into a
                    multidimensional multiverse. And for the first time, the
                    canvas can almost keep up with your imagination.
                </div>
                <div class="text2">
                    Unleash your talent on this canvas to bring entire worlds
                    into existence and make your fantasy real.
                </div>
                <div class="Lbtn" style="margin-top:2vh">
                    <DesktopButton
                        text="Join Visual Art Governance Council"
                        text2="100"
                        TextColor2="transparent"
                        TextColor="black"
                        TextColorAFter="#59e7f2"
                        TextSize2="0.521vw"
                        TextSize="1.111vh"
                        width="28.611vh"
                        BackColor="transparent"
                        AnimationColor="black"
                        height="3.704vh"
                        borderClr="0.1vw solid black"
                        BorderClrAfter="0.1vw solid transparent"
                        state = "Artists & Musicians"
                    />
                </div>
            </div>
        </div>

        <div
            on:mouseenter={() => (state1 = 'storytellers-hover')}
            on:mouseleave={() =>
                (state1 = state1 == 'storytellers-hover' ? '' : state1)}
            id="storytellers"
        >
            <div class=" contents">
                <div class="sidetext">Storytellers</div>
                <div class="h1">STORYTELLERS</div>
            </div>
            <div class="assets">
                <img
                    class="book"
                    src="images/section2/storytellerflare1.svg"
                    alt=""
                />
            </div>
            <div
                class="button2"
                style="margin-top: 32.3vh;"
                on:click={() => {
                    location.href = '/story';
                }}
            >
                <Button
                    TextColor="#F7FFC8"
                    ColorHover="#F7FFC8"
                    HoverText="black"
                />
            </div>
            <div class="p2">
                <div class="text1">
                    Turn your words into a living breathing world in the blink
                    of an eye. Write scenes, characters and their dialogs and
                    watch it happen in real time.
                </div>

                <div class="text2">
                    Unleash your imagination and let our technology do the work.
                </div>
                <div class="Lbtn" style="margin-top:5vh">
                    <DesktopButton
                        text="Join Storytellers Governance Council"
                        text2="100"
                        TextColor2="transparent"
                        TextColor="white"
                        TextColorAFter="black"
                        TextSize2="0.521vw"
                        TextSize="1.111vh"
                        width="28.611vh"
                        BackColor="#transparent"
                        AnimationColor="white"
                        height="3.704vh"
                        borderClr="0.1vw solid white"
                        BorderClrAfter="0.1vw solid transparent"
                        state = "Storyteller"
                    />
                </div>
            </div>
        </div>

        <div
            on:mouseenter={() => (state1 = 'performers-hover')}
            on:mouseleave={() =>
                (state1 = state1 == 'performers-hover' ? '' : state1)}
            id="performers"
        >
            <div class=" contents">
                <div class="sidetext">Performers</div>
                <div class="h1">PERFORMERS</div>
            </div>
            <div class="img-wrap">
                <img class="spread" src="images/section2/spread.svg" alt="" />
            </div>
            <div class="p2">
                <div class="text1" style="width: 53vh;">
                    Become part of history as we build the future in which you
                    won't have to take multiple shots for a single performance,
                    rather based on a myriad performance captures,your likeness
                    will be digitized, owned and would be perpetually used into
                    any production.
                </div>
                <div class="Lbtn">
                    <DesktopButton
                        text="Join Performers Governance Council"
                        text2="100"
                        TextColor2="transparent"
                        TextColor="black"
                        TextColorAFter="#67eebb"
                        TextSize2="0.521vw"
                        TextSize="1.111vh"
                        width="30.611vh"
                        BackColor="transparent"
                        AnimationColor="black"
                        height="3.704vh"
                        borderClr="0.1vw solid black"
                        BorderClrAfter="0.1vw solid transparent"
                        state = "Performers"
                    />
                </div>
                <div
                    class="button"
                    on:click={() => {
                        location.href = '/performance';
                    }}
                >
                    <Button
                        TextColor="black"
                        ColorHover="black"
                        HoverText="#67EEBB"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .main {
        position: relative;
        height: max(100vh, 540px);
        width: max(124.444vh, 672px);
        background-color: #f4eeec;
    }
    .sections {
        position: absolute;
        font-family: "Orbitron";
        width: max(103.148vh, 557px);
        height: max(76.111vh, 411px);
        display: flex;
        align-items: flex-end;
        left: max(13.333vh, 72px);
        bottom: 0vh;
    }
    .contents {
        height: max(57.5vh, 310.5px);
        width: inherit;
        position: absolute;
        bottom: 0vh;
        left: max(-3vh, -16.2px);
    }

    .sidetext {
        position: absolute;
        font-size: max(1.852vh, 10px);
        font-weight: 500;
        top: max(3.5vh, 18.7px);
        left: max(3vh, 16.2px);
        transition: 1s;
        writing-mode: vertical-lr;
    }
    .h1 {
        position: absolute;
        z-index: +1;
        left: max(6.5vh, 35.1px);
        top: max(-8vh, -43.4px);
        font-size: max(23.148vh, 125px);
        font-weight: 900;
        overflow: hidden;
        transition: 1s;
    }
    .h1 span {
        margin-left: max(21vh, 113.4px);
    }
    .p,
    .p2 {
        width: max(33.333vh, 180px);
        height: max(11.667vh, 63px);
        font-size: max(1.396vh, 7px);
        font-weight: 400;
        line-height: max(1.967vh, 9px);
        text-align: left;
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: max(2vh, 10.8px);
        top: max(30vh, 175px);
    }
    .p {
        left: max(80vh, 432px);
    }
    .p2 {
        left: max(-50vh, -270px);
        opacity: 0;
    }
    .artists-hover .p {
        left: max(13vh, 70.2px);
        transition: 1s;
    }
    .performers-hover #performers .p2 {
        left: max(25vh, 135px);
        z-index: +1;
        opacity: 1;
        transition: 1s;
    }
    .button {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .button2 {
        position: absolute;
        opacity: 0;
        left: max(14vh, 75.6px);
        top: max(50vh, 270px);
    }
    #storytellers {
        position: relative;
        background-color: #d578ff;
        width: max(24.815vh, 134px);
        height: max(76.111vh, 411px);
        transition: 1s;
        overflow: hidden;
    }
    .assets {
        position: absolute;
        bottom: max(-4vh, -48.8px);
        left: max(-9vh, -105.6px);
        width: max(45.778vh, 247.2px);
        transition: 1s;
    }
    .assets::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0) (255, 255, 255);
    }

    #storytellers .assets .book {
        width: 100%;
        filter: brightness(1.6);
        transition: 1s;
    }

    .storytellers-hover #storytellers {
        width: max(76.574vh, 413.5px);
        height: max(63.148vh, 341px);
        transition: 1s;
    }
    .storytellers-hover #storytellers .assets {
        transition: 1s;
        left: max(16vh);
        bottom: max(19vh, 102.6px);
        transform: scale(2.9);
    }

    .storytellers-hover #storytellers .p2 {
        left: max(40vh, 216px);
        opacity: 1;
        color: white;
    }

    .storytellers-hover #storytellers .book {
        filter: brightness(1);
        bottom: 0vh;
        transition: 1s;
    }
    .storytellers-hover #gamers .img-wrap {
        width: max(9.5vh);
        height: max(14.907vh);
        transition: 1s;
    }
    .img-wrap::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0) (255, 255, 255);
    }

    .storytellers-hover #gamers,
    .artists-hover #gamers,
    .performers-hover #gamers {
        height: max(76.111vh, 411px);
    }

    .artists-hover #storytellers,
    .gamers-hover #storytellers,
    .performers-hover #storytellers {
        height: max(63.148vh, 341px);
    }

    .storytellers-hover .img-wrap {
        position: absolute;
        height: max(15.556vh, 134px);
        width: max(13.38vh, 122.3px);
        transition: 1s;
    }

    .storytellers-hover .button2 {
        opacity: 1;
        top: max(18.611vh, 100.5px);
        left: max(7.593vh, 41px);
        transition: 1s;
        transition: 1s;
    }
    .storytellers-hover #storytellers .sidetext {
        left: max(-31vh, -191.4px);
        opacity: 0;
        transition: 1s;
        font-size: max(1.296vh, 7px);
    }
    .storytellers-hover #storytellers .h1 {
        position: absolute;
        top: max(-2vh, -10.8px);
        font-size: max(9.259vh, 50px);
        top: max(-2vh, -10.8px);
        left: max(-4.2vh, -50px);
        transition: 1s;
        color: #f7ffc8;
    }

    .storytellers-hover #artists,
    .storytellers-hover #performers,
    .storytellers-hover #gamers {
        width: max(7.407vh, 40px);
        transition: 1s;
    }
    .storytellers-hover #gamers .contents {
        top: max(17vh, 91.8px);
    }

    .storytellers-hover #performers .sidetext,
    .storytellers-hover #artists .sidetext,
    .storytellers-hover #gamers .sidetext {
        font-size: max(1.296vh, 7px);
    }
    #artists {
        position: relative;
        background-color: #59e7f2;
        width: max(24.815vh, 134px);
        height: max(67.593vh, 365px);
        overflow: hidden;
        transition: 1s;
    }
    .artists-hover #artists {
        width: max(76.574vh, 413.5px);
        transition: 1s;
    }

    .artists-hover #artists .button {
        left: max(58vh, 313px);
        opacity: 1;
    }
    #artists .statue {
        width: 100%;
        height: 100%;
    }

    #artists .img-wrap {
        position: absolute;
        height: max(33.148vh, 179px);
        width: max(30.278vh, 263.5px);
        bottom: max(0vh, 0px);
        left: max(-2vh, -10.8px);
        transition: 1s;
    }
    .artists-hover #performers .img-wrap,
    .gamers-hover #performers .img-wrap,
    .storytellers-hover #performers .img-wrap {
        opacity: 0;
    }
    .artists-hover #storytellers .contents {
        top: max(4vh, 21.6px);
    }
    .artists-hover #artists .img-wrap {
        height: max(56vh, 302px);
        width: max(57.037vh, 306.1px);
        left: max(-3.5vh);
        bottom: max(-4vh, -21.6px);
        transition: 1s;
    }
    .artists-hover #artists .h1 {
        font-size: max(9.259vh, 50px);
        left: max(-4vh, -27.6px);
        transition: 1s;
    }
    .artists-hover #artists .sidetext {
        left: max(-31vh, -191.4px);
        opacity: 0;
        transition: 1s;
        font-size: max(1.296vh, 7px);
    }

    .artists-hover #artists .p {
        left: max(40vh, 216px);
        top: max(36vh, 194.4px);
        opacity: 1;
    }

    .artists-hover #gamers {
        width: max(7.407vh, 40px);
        transition: 1s;
    }

    .artists-hover #gamers .contents {
        top: max(16vh, 86.4px);
    }
    .artists-hover #storytellers {
        width: max(7.407vh, 40px);
        transition: 1s;
    }
    .artists-hover #performers {
        width: max(7.407vh, 40px);
        transition: 1s;
    }
    .artists-hover #performers .sidetext,
    .artists-hover #storytellers .sidetext,
    .artists-hover #gamers .sidetext {
        font-size: max(1.296vh, 7px);
    }
    #gamers {
        position: relative;
        background-color: #817cf9;
        transition: 1s;
        width: max(24.815vh, 134px);
        height: max(63.148vh, 341px);
        overflow: hidden;
    }
    #gamers .img-wrap {
        position: absolute;
        height: max(38.241vh, 206.5px);
        width: max(28.241vh, 152.5px);
        bottom: max(-1.111vh, -12px);
        transition: 1s;
    }
    #gamers .contents {
        top: max(3.5vh, 18.7px);
    }
    #artists .contents {
        top: max(8vh, 43.2px);
    }
    #storytellers .contents {
        top: max(7vh, 37.8px);
    }
    #performers .contents {
        top: max(8vh, 43.2px);
    }
    .game {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: 1s;
    }
    .gamers-hover #gamers {
        width: max(76.574vh, 413.5px);
        height: max(76.111vh, 411px);
        transition: 1s;
        overflow: hidden;
    }
    .gamers-hover #storytellers .assets,
    .artists-hover #storytellers .assets,
    .performers-hover #storytellers .assets {
        width: max(15vh, 81px);
        height: max(13vh, 70.2px);
        bottom: max(-3vh, -30.8px);
        left: max(-3.6vh, -45px);
        transition: 1s;
    }
    .gamers-hover #gamers .img-wrap {
        height: max(76.111vh, 411px);
        width: max(57vh, 307.8px);
        left: max(0vh, 0px);
        bottom: 0vh;
    }
    .gamers-hover #gamers .p {
        left: max(48vh);
        top: max(4.63vh, 25px);
        width: max(25.111vh, 135.5994px);
        color: white;
        opacity: 1;
    }
    .gamers-hover #gamers .h1 {
        font-size: max(23.148vh, 125px);
        top: max(32vh, 172.8px);
        font-weight: 900;
        color: white;
        left: max(-27.4vh, -328.96px);
        transition: 1s;
    }
    .gamers-hover #gamers .sidetext {
        left: max(-103vh, -556.2px);
        opacity: 0;
        transition: 1s;
        font-size: max(1.296vh, 7px);
    }

    .gamers-hover #gamers .button {
        position: relative;
        opacity: 1;
        left: max(7vh, 37.8px);
        top: max(2vh, 10.8px);
    }
    .gamers-hover #storytellers {
        width: max(7.407vh, 40px);
        transition: 1s;
    }
    .gamers-hover #artists {
        width: max(7.407vh, 40px);
        transition: 1s;
    }
    .gamers-hover #performers {
        width: max(7.407vh, 40px);
        transition: 1s;
    }
    .gamers-hover #artists .sidetext,
    .gamers-hover #storytellers .sidetext,
    .gamers-hover #performers .sidetext {
        font-size: max(1.296vh, 7px);
    }
    .gamers-hover #storytellers .contents {
        top: max(4vh, 21.6px);
    }
    .performers-hover #storytellers .contents {
        top: max(4vh, 21.6px);
    }
    #performers {
        position: relative;
        background-color: #67eebb;
        width: max(24.815vh, 134px);
        height: max(67.037vh, 362px);
        transition: 1s;
        overflow: hidden;
    }
    #performers .img-wrap {
        position: absolute;
        width: max(69.167vh, 373.5px);
        height: max(41.574vh, 224.5px);
        top: max(31vh, 167.4px);
        left: max(-40vh);
    }
    #performers .spread {
        height: 100%;
    }
    .performers-hover #performers {
        width: max(76.574vh, 413.5px);
        transition: 1s;
    }
    .performers-hover #performers .h1 {
        font-size: max(13.889vh, 75px);
        top: max(-6vh);
        left: max(-13.333vh);
        transition: 1s;
    }
    .performers-hover #performers .img-wrap {
        position: absolute;
        width: max(80.556vh, 435px);
        height: max(48.426vh, 261.5px);
        left: max(0vh, 0px);
        top: max(18vh, 97.2px);
    }
    .performers-hover #gamers .img-wrap {
        width: max(9.5vh);
        height: max(14.907vh);
        transition: 1s;
    }
    .artists-hover #gamers .img-wrap {
        width: max(9.5vh);
        height: max(14.907vh);
        transition: 1s;
    }
    .performers-hover #artists .img-wrap {
        position: absolute;
        height: max(13vh, 70.2px);
        width: max(12vh, 64.8px);
        transition: 1s;
    }
    .gamers-hover #artists .img-wrap,
    .storytellers-hover #artists .img-wrap {
        position: absolute;
        height: max(13vh, 70.2px);
        width: max(12vh, 64.8px);
        transition: 1s;
    }
    .artists-hover #performers .spread {
        width: max(14.5vh, 78px);
        height: max(13vh, 70.2px);
        margin-left: max(-1vh, -5.4px);
        transition: 1s;
    }
    .performers-hover #performers .sidetext {
        left: max(-21vh, -113.4px);
        transition: 1s;
        font-size: max(1.296vh, 7px);
    }
    .performers-hover #artists .sidetext,
    .performers-hover #storytellers .sidetext,
    .performers-hover #gamers .sidetext {
        font-size: max(1.296vh, 7px);
    }
    .performers-hover #gamers {
        width: max(7.407vh, 40px);
        transition: 1s;
    }
    .performers-hover #gamers .contents {
        top: max(17vh, 91.8px);
    }
    .performers-hover #artists {
        width: max(7.407vh, 40px);
        transition: 1s;
    }
    #storytellers .p2,
    #artists .p,
    #gamers .p,
    #performers .p2 {
        position: relative;
        opacity: 0;
        left: max(23vh, 124.2px);
    }
    .performers-hover #performers .p2 {
        position: absolute;
        opacity: 1;
        height: max(7.407vh, 40px);
        width: max(52.13vh, 281.5px);
        left: max(5.278vh, 28.5px);
        top: max(23vh, 124.2px);
    }
    .performers-hover #performers .button {
        position: absolute;
        left: max(34vh, 183.6px);
        top: max(12vh, 64.8px);
        opacity: 1;
    }
    .performers-hover #performers .Lbtn {
        position: relative;
        top: max(25vh, 135px);
        left: max(23vh, 113.4px);
    }
    .performers-hover #storytellers {
        width: max(7.407vh, 40px);
        transition: 1s;
    }
</style>
