<script>
    export let text = "Join Governance Council";
    export let height = "9.346vw";
    export let width = "60.748vw";
    export let bgcolor = "black";
    export let txtcolor = "white";
    export let border = "solid white 0.234vw";
</script>

<div class="main">
    <div
        class="btn"
        style="height:{height}; width:{width}; background-color:{bgcolor}; border:{border};"
    >
        <div class="txt" style="color:{txtcolor};">{text}</div>
    </div>
</div>

<style>
    .txt {
        font-family: "Orbitron";
        font-size: 1.869vw;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: center;
        font-size: 2.804vw;
        font-weight: 700;
        line-height: 9vw;
    }
</style>
