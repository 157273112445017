<script>
    import Alphabets from '../reusableitems/alphabets.svelte';
    export let currentWord;
    let index=0;
    let start = 0;
    const wordList = [
        {
            word: ['s', 'i', 'm', 'u', 'l', 'a', 't', 'i', 'o', 'n', 's'],
            fontColor: '#FFCC33',
        },
        {
            word: ['w', 'o', 'r', 'l', 'd', 's'],
            fontColor: '#F15F79',
        },
        {
            word: ['m', 'o', 'v', 'i', 'e', 's'],
            fontColor: '#DA22FF',
        },
        {
            word: ['g', 'a', 'm', 'e', 's'],
            fontColor: '#1C7BFF',
        },
        {
            word: ['m', 'e', 't', 'a', 'v', 'e', 'r', 's', 'e', 's'],
            fontColor: '#01C6C6',
        },
        
    ];
    const prevList = [
        {
            word: ['m', 'e', 't', 'a', 'v', 'e', 'r', 's', 'e', 'a', 's'],
        },
        {
            word: ['s', 'i', 'm', 'u', 'l', 'a', 't', 'i', 'o', 'n', 's'],
        },
        {
            word: ['w', 'o', 'r', 'l', 'd', 's'],
        },
        {
            word: ['m', 'o', 'v', 'i', 'e', 's'],
        },
        {
            word: ['g', 'a', 'm', 'e', 's', 'a' , 'a' , 'a', 'a', 'a', 'a'],
        },
        
       
    ];
$: {
    /**
    *
    *
    */
        function incrementFun() {
            if (wordList.length-1 === index) {
                index = 0;
                currentWord = index;
            } else {
                index ++;
                currentWord = index;
                start = 1;
            }
        }
        if (start === 0) {
            setTimeout(incrementFun, 1000);
        } else {
            setTimeout(incrementFun, 3000);
        }
}
</script>
    {#each wordList as data, i}
        {#if index==i}
            {#each data.word as aplha,j}
                {#if start==0}
                    <Alphabets fontColor={data.fontColor} current={aplha} prevWord={aplha}/>
                    {:else}
                    <Alphabets fontColor={data.fontColor} current={aplha} prevWord={prevList[i].word[j]}/>
                {/if}
            {/each}
        {/if}
    {/each}
<style></style>
