<script>
    import rpcjs from 'rpcjs';
    const {title} = rpcjs.props;
    title.set('Demo');
</script>
<div class="main" >
    <div class="logo">
        <img src="/images/opus_logo.svg" alt="" />
    </div>
<div class="demo">
    <!-- svelte-ignore a11y-media-has-caption -->
        <video width="100%" height="100%" controls autoplay>
        <source src="https://static.opus.ai/demo.mp4" type="video/mp4">
    </video>
    <div class="cancelBtn" on:click={() =>{
        location.href='/';
    }}>
        <p  >Close</p>
    </div>
</div>
</div>
<style>
    .main{
        position: relative;
        background-color: black;
        width: 100%;
        height:100%;

    }
    p {
        margin: 0;
    }
    .logo {
        width: max(16.481vh, 89px);
        height: max(6.852vh, 37px);
        background-color: black;
        border-radius: 0 0.926vh 0.926vh 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        padding-top: 4vh;
    }
    .logo img {
        width: max(15.537vh, 51.5px);
        height: max(6.963vh, 16px);
    }
.demo{
    position: absolute;
    width: 65.365vw;
    /*height: max(75.648vw, 408.5px);*/
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: max(15.407vh , 67px);
}
.cancelBtn {
    position: absolute;
    font-family: "Orbitron";
    color: white;
    font-size: 1.042vw;
    font-weight: 900;
    top: -4vh;
    right: 0;
    cursor: pointer;
    }

</style>