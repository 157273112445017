import landingPage from './landingPage';
// import {burl} from '../store';
import Team from './team.svelte';
import AdvertPage from './advertPage.svelte';
import InvestPage from './investPage.svelte';
import Create from '../desktop/technicalcreators.svelte';
import Demo from './landingPage/sections/demo.svelte';
import Gamers from './Gamers/section1.svelte';
import Artist from './Artist/section1.svelte';
import StoryTeller from './CreateMain/createMain.svelte';
import Performnace from './Perfomance/mainPerfomancePage.svelte';
export default {
    '/': landingPage,
    '/Team': Team,
    '/AdvertPage': AdvertPage,
    '/Create': Create,
    '/InvestPage': InvestPage,
    '/demo': Demo,
    '/gamers': Gamers,
    '/artist': Artist,
    '/performance': Performnace,
    '/story': StoryTeller,
};
