<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>

<div class="main-div">
    <div class="left-div" />

    <div class="right-div"
    >
        <div class="onediv">
            <p>3</p>
        </div>
        <div class="text1">
            <p>VFX, 3D Environment & Prop Designers</p>
        </div>
        <div class="text2-div">
            <p>
                Realism is about depth and effects. You don’t just create
                worlds, you make them real so that your audience doesn’t just
                wow at a flat image but gasps and trips. Your talent is to make
                them forget the difference between the real world and the
                imagined one.
                <br>
                <br>
                <span style="font-weight: 600;">
                    We’re building the future where your talents get truly
                    unleashed, where you don't get stuck creating walls and
                    doors, one vertex at a time but cities and planets. Learn
                    and participate.
                </span>
            </p>
        </div>

    </div>
    <div class="button">
        <DesktopButton2 
        state = "Artists & Musicians"
        text1 = "Join Visual Art Governance Council"
        text2 = "(3D Seat)"
        text3 = 25
        TextColor = "white"
        TextColor2 = "white"
        BorderColorBefore = '0.0052vw solid white'
        BorderColorAfter = '0.0052vw solid aqua'
        TextColorAfter = 'black'
        Text2Size= 0.521vw
        AnimationColor = 'aqua'
         />
    </div>
</div>

<style>
    p {
        margin: 0;
    }
    .right-div{
        height: 100vh;
        width: max(177.778vh, 960px);
        background-image: url("/images/ArtistPage/section3.png");
        background-size: cover;
        background-position: center;
        font-family: "Orbitron";
        filter: grayscale(1);
        transition-duration:1.2s;
    }
    .right-div:hover{
        filter: grayscale(0);
    }
    .left-div {
        width: max(6.481vh, 35px);
        height: 100vh;
        background-color: #faf7f6;
    }
    .main-div {
        height: 100vh;
        display: flex;
        position: relative;
    }
    .onediv {
        font-size: max(55.556vh, 300px);
        color: #f4eeec;
        font-weight: 700;
        position: absolute;
        left: max(14.259vh, 77px);
        opacity: 50%;
        top: max(5.833vh, 31.5px);
    }
    .text1 {
        width: max(83.426vh, 450.5px);
        font-size: max(5.556vh, 30px);
        line-height: max(5.556vh, 30px);
        color: white;
        font-weight: 700;
        position: absolute;
        left: max(23.333vh, 226px);
        top: max(37.13vh, 200.5px);
    }
    .text2-div{
        width: max(57.685vh, 311.5px);
        height: max(28.519vh, 154px);
        background-color: white;
        border-radius: max(3.704vh, 20px);
        position: absolute;
        top: max(51.019vh, 250.5px);
        left: max(102.685vh, 554.5px);
        padding: max(4.074vh, 22px);
    }
    .text2-div p{
        font-size: max(1.852vh ,10px);
        font-weight: 400;
        line-height: max(2.593vh , 14px);
        color: #000000;
        letter-spacing: 0.12vh;
    }
    .button{
        position: absolute;
        top: max(64.722vh, 349.5px);
        left: max(29.815vh, 161px);
    }
</style>
