<script>
  import pages from './pages';
  import { getContext } from 'svelte';

  const { url } = getContext('APP');
  let view_port_height, scroll_y;


</script>

<svelte:window bind:scrollY={scroll_y} bind:innerHeight={view_port_height} />
<section>
  <div class="main-div">	
      <svelte:component this={pages[$url.pathname]} view_port_height={view_port_height} scroll_y={scroll_y}/>
  </div>
</section>

<style>
  .main-div {
      z-index: 1;
      width: 100%;
  }
</style>
