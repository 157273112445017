<script></script>

<div style="position: relative;">
    <p class="how-heading">
        HOW <br /> IT WORKS ?
    </p>
    <p class="text1">
        Immerse audiences in new levels of storytelling and engagement.
    </p>

    <img class="img1" alt="" src="/images/HowSection/image1.svg" />
    <div class="box">
        <p class="box-text">Opus</p>
        <hr class="box-line1" />
        <p class="box-text2">
            NLP trained to extract features from any <span
                style="color: rgb(242, 89, 166);font-weight: 900;"
                >literary style</span
            > in English.
        </p>
        <p class="box-text2" style="margin-top: 3.271vw;">
            Thereʼs no special syntax to learn.
        </p>
    </div>

    <img class="img2" alt="" src="/images/HowSection/plus.svg" />
    <img
        class="img1"
        style="top: 211vw;"
        alt=""
        src="/images/HowSection/face.svg"
    />
    <div class="box2">
        <p class="box-text" style="margin-top: 5.257vw;">Opus</p>
        <hr class="box-line1" style="margin-top: 4.206vw;" />
        <p class="box-text2">
            <span style="color: rgba(70, 174, 0, 1);font-weight: 900;"
                >Text to Video</span
            > generation using AI. literary style in English.
        </p>
        <p class="box-text2" style="margin-top: 2.103vw;">
            <span style="color: rgba(70, 174, 0, 1);font-weight: 900;">
                Computational Production -
            </span>
            everything from scenes, assets, characters, dialogue and visual effects.
        </p>
    </div>

    <div class="clrDiv">
        <div>
            <p>Original Content</p>
        </div>
        <div>
            <p>1 / 10th of cost</p>
        </div>
        <div>
            <p>Unmatched Speed</p>
        </div>
    </div>
</div>

<style>
    .how-heading {
        font-size: 11.682vw;
        width: 87.15vw;
        margin-left: 8.879vw;
        font-weight: 900;
        margin-top: 13.785vw;
        font-family: "Orbitron";
    }
    .text1 {
        font-size: 3.037vw;
        margin-left: 42.29vw;
        font-weight: 400;
        width: 51.54vw;
        margin-top: 5.14vw;
        font-family: "Orbitron";
    }
    .box-text {
        position: relative;
        font-size: 3.271vw;
        margin-left: 10.572vw;
        margin-top: 10.572vw;
        font-family: "Orbitron";
        font-weight: 900;
    }
    .img1 {
        width: 25.935vw;
        height: 33.645vw;
        left: 37.15vw;
        position: absolute;
        top: 66.673vw;
        z-index: 3;
    }
    .box-line1 {
        width: 40.187vw;
        margin-left: 10.339vw;
        margin-top: 5.841vw;
        background-color: rgba(0, 0, 0, 1);
        border: 0.234vw solid rgba(0, 0, 0, 1);
    }
    .box-text2 {
        width: 44.86vw;
        margin-left: 10.339vw;
        font-size: 2.336vw;
        color: rgba(0, 0, 0, 1);
        font-family: "Orbitron";
        margin-top: 4.5vw;
    }
    .img2 {
        width: 7.477vw;
        height: 7.477vw;
        margin-top: 8.995vw;
        margin-left: 46.262vw;
    }
    .box {
        width: 65.596vw;
        border: 0.234vw solid rgba(190, 190, 190, 1);
        height: 45.093vw;
        margin-left: 16.998vw;
        margin-top: 44.042vw;
        position: relative;
    }
    .box2 {
        width: 65.596vw;
        border: 0.234vw solid rgba(190, 190, 190, 1);
        height: 45.093vw;
        margin-left: 16.998vw;
        margin-top: 8.995vw;
        position: relative;
    }
    .box::after {
        content: "";
        width: 39.019vw;
        background: white;
        border: 1.2vw solid white;
        position: absolute;
        top: -1.6vw;
        left: 12.009vw;
    }
    .box2::after {
        content: "";
        width: 39.019vw;
        background: white;
        border: 1.2vw solid white;
        position: absolute;
        bottom: -1.6vw;
        left: 12.009vw;
    }
    .clrDiv {
        margin-top: 43.341vw;
    }
    .clrDiv P {
        color: white;
        line-height: 4vw;
        text-align: center;
        font-weight: 600;
        font-size: 1.8vw;
        margin: 0;
        font-family: "Orbitron";
    }
    .clrDiv div:nth-child(1) {
        height: 4.206vw;
        width: 22.196vw;
        background-color: #d578ff;
        margin-left: auto;
    }
    .clrDiv div:nth-child(2) {
        height: 4.206vw;
        width: 20.561vw;
        background-color: #f259a6;
        margin: 0.3vh 0 0.3vh auto;
    }
    .clrDiv div:nth-child(3) {
        height: 4.206vw;
        width: 25.467vw;
        background-color: #625df0;
        margin-left: auto;
    }
</style>
