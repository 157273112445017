<script>
    import LandingPagenavbar from '../reusableitems/landingPagenavbar.svelte';
    import OnPageLoad from '../reusableitems/onPageLoad.svelte';
</script>

<section>
    <div>
        <OnPageLoad
            background="conic-gradient( white 0deg 90deg, #C451DB 90deg 270deg, white 270deg 360deg)"
        />
    </div>
    <div
        style=" position: fixed;
    width: 100%;
    z-index: 21;"
    >
        <LandingPagenavbar
            togglerclr="white"
            borderclr="white"
            bgClrAfter="black"
            textclr='white'
        />
    </div>
    <div class="main-div">
        <div class="technical">
            <p style="text-align: start;">TECHNICAL</p>
            <p style="text-align: end;">CREATORS</p>
        </div>
        <div class="intro">
            <p>
                Looking for something more exciting than a run of the mill job
                at FANG (or is it MANG now)?
            </p>
            <p>
                Look no further. We are the infrastructure company for the next
                generation of internet. We’re changing all that using technology
                from the future.
            </p>
            <p>
                We’re on a mission to turn it into a decentralized self
                sustaining community of like minded changemakers across the
                globe. Yet, we have only taken the first steps and our mission
                is to scale it for global use, creating an equitable, fair and
                democratized all 3D internet and you can help us scale it.
            </p>
            <p style="margin-bottom: 8.802vw;"><span>Intrested?</span></p>
        </div>
    </div>
</section>

<style>
    .main-div {
        width: 100%;
        background-image: url(/images/technicalcreators/OpusAmbassador-22.png);
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 8.802vw;
        padding-bottom: 4.98vw;
    }
    .technical {
        width: 72vw;
        margin: auto;
    }
    .technical p {
        font-size: 7.813vw;
        font-weight: 800;
        font-family: "Orbitron";
        color: #da22ff;
        margin: 0vw;
        line-height: 7.305vw;
    }
    .intro {
        width: 42.813vw;
        height: 19.76vw;
        text-align: justify;
        margin-left: 44.375vw;
        margin-top: 2.813vw;
    }
    .intro p {
        font-size: 1.042vw;
        font-weight: 400;
        font-family: "Orbitron";
        color: #f4eeec;
    }
    .intro p span {
        font-weight: 600;
    }
</style>
