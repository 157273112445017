import { writable, readable, derived, get } from 'svelte/store';
import rpcjs from 'rpcjs';

const { persistent } = rpcjs.props;

const BURL = '__MOD_URL__';
export function burl(path='') {return BURL + path};

export const pageWidth = writable(0);
export let NotFirstVisitLanding = persistent('NotFirstVisitLanding');
export let NotFirstVisitGamer = persistent('NotFirstVisitGamer');