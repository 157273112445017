// Import pages
import ConsumerPageMobile from './consumerPage.svelte';
// import Team from './mobile/Team/team.svelte';
import landingPage from './landingPage';
import ScrollExample from './scroll_example.svelte';
export default {
    '/': landingPage,
    '/consumerpagemobile': ConsumerPageMobile,
    '/ScrollExample':ScrollExample,
    // '/Team': Team,
};
