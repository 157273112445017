<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>


    <div class="main-div">
        <div class="second-div">
            <p class="text1">STORYTELLERS & Opus</p>
            <p class="text2">
                SCRIPT
                <br />
                WRITERS
            </p>
            <p class="text3">
                On average, under 0.1% of scripts written within the United States
                ever make it to production. Most of the blood tears written by
                scriptwriters end up in an archive, never to be looked up upon
                again. Months and often years of work is lost.
            </p>
    
            <p class="text4">
                We are on a mission to change that. If you complete it, it will be
                produced, watched and enjoyed.
            </p>
            <div style="margin-top:max(5vh, 27px);">
                <DesktopButton2
                state = "StoryTeller"
                text1="Join Storytellers Governace Council"
                text2="(Scriptwriter Seat)"
                AnimationColor = #A53BD6
                    width="max(28.611vh, 154.5px)"
                    BorderColorAfter="0.0052vw solid #A53BD6"
                />
            </div>
        </div>
        <div class="image">
            <img src="/images/createmain/section3.png" alt="" width="100%" height="100%"/>
        </div>
    </div>




<style>
    .main-div {
        width: max(113.889vh, 615px);
        height: 100vh;
        position: relative;
        
    }
    .second-div {
        width: max(77.315vh, 417.5px);
        background-color: #f4eeec;
        height: 100vh;
        padding-left: max(7.5vh, 40.5px);
    }
    p {
        font-family: "Orbitron";
        margin: 0;
    }
    .text1 {
        font-size: max(1.296vh, 7px);
        font-weight: 400;
        padding-top: max(24.352vh, 131.5px);
    }
    .text2 {
        color: #a53bd6;
        font-size: max(5.556vh, 30px);
        line-height: max(5.556vh, 30px);
        font-weight: 800;
        margin: 0;
        padding-top: max(1.481vh, 8px);
    }
    .text3 {
        font-size: max(1.296vh, 7px);
        font-weight: 400;
        width: max(37.037vh, 200px);
        padding-top: max(6.204vh, 33.5px);
    }
    .text4 {
        font-size: max(1.296vh, 7px);
        width: max(39.722vh, 214.5px);
        font-weight: 700;
        letter-spacing: 0.09vh;
        padding-top: max(5.37vh, 29px);
    }
    .image {
        position: absolute;
        left: max(51.852vh, 280px);
        top: max(24.537vh, 132.5px);
        width: max(50.926vh, 275px);
        height: max(51.296vh, 277px);
    }
    .image::before{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
</style>
