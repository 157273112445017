<script>
    import DesktopButton from '../../reusableitems/DesktopButton2.svelte';
</script>

    <div class="sectionHolder">
        <div class="contentPart">
            <h6>STORYTELLERS & Opus</h6>
            <h1>PRODUCERS</h1>
            <p style="width:max(34.352vh, 185.5px);">
                Nothing is more satisfying than seeing your production come to life.
                In the future which we’re building, it isn't a year long project
                around gathering a team, resources and equipment, rather it’s an
                exercise in narrative.
            </p>
            <p style="width:max(39.167vh, 211.5px);margin: 4vh 0 6vh;">
                <b>
                    Rather than waiting till the end to see the result, we start
                    with the production and the time is spent in tweaking and
                    refining the experience rather than hoping that the budget
                    lasts. Interested in buildlign that future alongside us?
                </b>
            </p>
            <div>
                <DesktopButton
                    state = "StoryTeller"
                    text2="(Producers Seat)"
                    text1="Join Storytellers Governace Council"
                    AnimationColor="#a53bd6"
                    BorderColorAfter="0.0052vw solid #a53bd6"
                />
            </div>
        </div>
        <div class="imgOuterWrraper">
            <div class="imgWrraper">
                <img
                    src="/images/create-section/Rectangle.png"
                    alt="arrow"
                    width="100%"
                    height="100%"
                />
            </div>
        </div>
        <div class="imgWrraper2">
            <img
                src="/images/create-section/assets.png"
                alt="assets"
                width="100%"
                height="100%"
            />
        </div>
    </div>



<style>
    .sectionHolder {
        background-color: #f4eeec;
        width: max(203.259vh, 995px);
        position: relative;
    }
    .contentPart {
        width: max(40.833vh, 220.5px);
        top: max(24.352vh, 131.5px);
        left: max(16.667vh, 90px);
        font-family: "Orbitron";
        position: absolute;
        font-style: normal;
        z-index: 1;
    }
    h6 {
        font-weight: 400;
        font-size: max(1.296vh, 7px);
        line-height: 100%;
        text-transform: uppercase;
        color: #000000;
        margin: 0;
    }
    h1 {
        font-weight: 900;
        font-size: max(5.556vh, 30px);
        line-height: 100%;
        text-transform: uppercase;
        color: #a53bd6;
        margin: 4vh 0px;
    }
    p {
        font-weight: 400;
        font-size: max(1.296vh, 7px);
        line-height: 140%;
        letter-spacing: 0.09em;
        color: #000000;
    }
    .imgOuterWrraper {
        width: max(144.398vh, 779.75px);
        overflow: hidden;
        margin-left: auto;
    }
    .imgOuterWrraper::after{
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background-color: #ffffff00;
        left: 0;
        top: 0;
    }
    .imgWrraper {
        width: max(144.398vh, 779.75px);
        height: max(100vh, 540px);
        transform: translateX(10vh);
    }
    .imgWrraper2 {
        width: max(114.167vh, 616.5px);
        height: max(64.259vh, 347px);
        right: 10vh;
        top: max(17.407vh, 94px);
        position: absolute;
        filter: grayscale(100%);
        transition-duration: 0.5s;
    }
    .imgWrraper2:hover {
        filter: grayscale(0%);
        transition-duration: 0.5s;
    }
    .imgWrraper2::after{
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background-color: #ffffff00;
        left: 0;
        top: 0;
    }
</style>
