<script>
    import Performers from './Performers.svelte';
    import Section2 from './section2.svelte';
    import Section3 from './section3.svelte';
    import Section4 from './section4.svelte';
    import Section5 from './section5.svelte';
    import LandingPagenavbar from '../../reusableitems/landingPagenavbar.svelte';
    import OnPageLoad from '../../reusableitems/onPageLoad.svelte';
    import rpcjs from 'rpcjs';
    const {title} = rpcjs.props;
    title.set('Performers');
</script>

<section>
    <div class="main-div">
        <div
            style="position: fixed;
        width: 100vw;
        z-index: 2;"
        >
            <LandingPagenavbar togglerclr="black" textClrAfter='white' textclr='black' borderclr='black' bgClrAfter='black'/>
            </div>
        <div>
            <OnPageLoad background='conic-gradient( white 0deg 90deg, #67EEBB 90deg 270deg, white 270deg 360deg)'/>
        </div>
        <Performers />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
    </div>
</section>

<style>
    .main-div {
        display: flex;
    }
</style>
