<script>
    import LandingPagenavbar from '../../reusableitems/landingPagenavbar.svelte';
    import OnPageLoad from '../../reusableitems/onPageLoad.svelte';
    import Section1 from './section1.svelte';
    import Section2 from './section2.svelte';
    import Section3 from './section3.svelte';
    import Section4 from './section4.svelte';
    import Section5 from './section5.svelte';
    import Gif from '../../reusableitems/gif.svelte';
    import rpcjs from 'rpcjs';
    const {title} = rpcjs.props;
    title.set('Storytellers');
</script>

<div class="navbar">      
    <LandingPagenavbar bgClrAfter='black'/>
</div>
<div>
    <OnPageLoad background='conic-gradient( white 0deg 90deg, #D578FF 90deg 270deg, white 270deg 360deg)'/>
</div>
<div>
    <Gif />
</div>
<div class="sectionWrraper">
    <div>
        <Section1/>
    </div>
    <div>
        <Section2/>
    </div>
    <div>
        <Section3/>
    </div>
    <div>
        <Section4/>
    </div>
    <div>
        <Section5/>
    </div>
</div>

<style>
    .sectionWrraper{
        display: flex;
    }
    .navbar{
        position: fixed;
        width: 100%;
        z-index: 2;
    }
</style>