<script>
       import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>


<div class="main-div">

    <div class="left-div">
        <p class="text1">STORYTELLERS & Opus</p>
        <p class="text2">
            FANFICTION
            <br />
            WRITERS
        </p>
        <p class="text3">
            Whether you are particular about how it should have ended or disagree on how little action was given to your favorite character in a series, you don’t idly sit, you alter, extend and add depth to your favorite story and characters.         
        </p>

        <p class="text4">
            We’re building a platform that makes producing movies and games just as much a collaborative process for everyone as fanfiction writing is. More importantly, the derivatives are just as real as the original. Fix that ending, join our governing council now!

        </p>
        <div style="margin-top:max(5vh, 27px);">
            <DesktopButton2 
                state = "StoryTeller"
                text1="Join Storytellers Governace Council"
                text2="(Fanfiction Seat)"
                width = 'max(28.611vh, 154.5px)'
                AnimationColor = #A53BD6
                BorderColorAfter = '0.0052vw solid #A53BD6'
            />
        </div>
        <div class="scroll">
            <div class="imageWrraper">
                <img src="/images/createmain/arrow.svg" alt="arrow" width="100%" height="100%">
            </div>
            <div>
                <p>Scroll / Drag</p>
            </div>
            
        </div>
    </div>

    <div class="right-div">
    </div>
</div>

<style>

.main-div{
    display: flex;
    height: 100vh;
    width: 131vh;
    position: relative;

}

.left-div{
    width: max(62.778vh, 339px);
    padding-left: max(15.741vh, 85px);
}
.right-div{
    width: max(52.037vh, 281px);
    background-image: url('/images/createmain/section5.png');
        background-size: cover;
        background-position: center;
        background-color: black;
}
p {
        font-family: "Orbitron";
    }
    .text1 {
        font-size: max(1.296vh, 7px);
        font-weight: 400;
        padding-top: max(24.352vh, 131.5px);
    }
    .text2{
        color: #A53BD6;
        font-size: max(5.556vh, 30px);
        line-height: max(5.556vh, 30px);
        font-weight: 800;
        margin: 0;
    }
    .text3{
        font-size: max(1.296vh, 7px);
        font-weight: 400;
        width: max(37.037vh, 200px);
        margin-top:max(6.204vh, 33.5px) ;
    }
    .text4{
        font-size: max(1.296vh, 7px);
        width: max(39.722vh, 214.5px);
        font-weight: 700;
    }
    .scroll{
        position: absolute;
        color: rgb(0, 0, 0);
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: right;
        top: max(93.796vh, 506.5px);
        left: max(18.556vh, 109px);
    }
    .scroll p{
        font-family: 'Orbitron';
        font-style: normal;
        font-weight: 700;
        font-size: max(0.938vw, 9px);
    }
    .scroll .imageWrraper{
        width: max(3.698vw, 35.5px);
        height: fit-content;
        margin-right: max(2.593vh, 14px);
    }
</style>