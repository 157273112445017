<script>
    let image;
    let imagediv;
    let heading;
    let sportImg;
    let textclr;
    let arrowtext;
    let arrow;
    let button;
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>

<section>
    <div
        class="maindiv"
        on:mouseenter={() => {
            image.style.transform = 'scale(1.5)';
            imagediv.style.opacity = '1';
            heading.style.top = '43.12vh';
            imagediv.style.transform = 'scale(1.3)';
            sportImg.style.opacity = '1';
            sportImg.style.transform = 'scale(1) rotate(-90deg)';
            sportImg.style.left='-34vh';
            textclr.style.color = '#FFF000';
            arrowtext.style.color = '#F4EEEC';
            arrow.style.fill = '#F4EEEC';
            button.style.opacity = '1';
        }}
        on:mouseleave={() => {
            imagediv.style.transform = 'scale(0.4)';
            image.style.transform = 'scale(1)';
            imagediv.style.opacity = '0';
            heading.style.top = '32.130vh';
            sportImg.style.opacity = '0';
            sportImg.style.transform = 'scale(0.7) rotate(-90deg)';
            sportImg.style.left='0';
            textclr.style.color = '#F4EEEC';
            arrowtext.style.color = 'black';
            arrow.style.fill = 'black';
            button.style.opacity = '0';
        }}
    >
        <div class="backClr" bind:this={imagediv} />
        <div class="imgdiv">
            <img
                class="image"
                bind:this={image}
                src="/images/Gamer/OpusAmbassador.png"
                alt=""
                width="100%"
                height="100%"
            />
        </div>
        <div class="Text" bind:this={heading}>
            <p class="heading">
                Forget PvP and 1v1.
                <br /><br />
                Play on an infinite dynamic landscape where the only constant is
                change and an evergoing war for supremacy.
            </p>
            <p class="pre">
                Build, recruit and manage planet scale armies of real players
                using
                <span bind:this={textclr} style="font-weight: 700;"
                    >CommonCoin (www.commoncoin.club)</span
                >
                or buy platoons of android garrisons.
                <br /><br />
                Fight for control of solar systems and engage in the devious game
                of politics against other players, never seen in games before. More
                of a solo fighter? Take bounties and assassination contracts to dethrone
                empires. Be with the shadows or a storm of destruction.
            </p>
        </div>
        <div class="btn" bind:this={button}>
            <DesktopButton2
            state = "Games"
            text2 = '(Competitive Seat)'
                TextColor="#F4EEEC"
                borderclr=" 0.0052vw solid #F4EEEC"
                BorderColorBefore="0.0052vw solid #F4EEEC"
                TextColor2="#F4EEEC"
            />
        </div>
        <div class="scroll_div">
            <p class="scrollText" bind:this={arrowtext}>scroll / drag</p>
            <svg
                width="6.185vh"
                height="0.741vh"
                viewBox="0 0 72 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="img"
                bind:this={arrow}
            >
                <path
                    d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
                />
            </svg>
        </div>
        <div class="sport" bind:this={sportImg}>
            <p>
                E - SPORTS
            </p>
        </div>
    </div>
</section>

<style>
    .maindiv {
        width: max(178.333vh, 963px);
        height: max(100vh, 540px);
        background-color: #f4eeec;
        position: relative;
        font-family: "Orbitron";
        overflow: hidden;
    }
    .imgdiv {
        position: absolute;
        top: 19.537vh;
        left: 27.037vh;
        width: max(124.907vh, 674.5px);
        height: max(62.315vh, 336.5px);
        transition-duration: 1s;
        opacity: 1;
    }
    .backClr {
        width: max(178.333vh, 963px);
        height: max(99.85vh, 535px);
        transform: scale(0.4);
        background-image: radial-gradient(
            50% 50% at 50% 50%,
            #1d0b3e 0%,
            #0f0513 100%
        );
        position: absolute;
        transition-duration: 1s;
        top: 0vh;
        left: 0vh;
    }
    .image {
        transform: scale(1);
        transition-duration: 1s;
        pointer-events: none;
    }
    p {
        margin: 0;
    }
    .Text {
        position: absolute;
        top: max(32.13vh, 173.5px);
        left: max(51.389vh, 277.5px);
        transition-duration: 1.5s;
    }
    .heading {
        width: max(78.204vh, 411.5px);
        font-family: "Orbitron";
        font-weight: 800;
        font-size: max(2.778vh, 15px);
        line-height: 3.889vh;
        text-align: center;
        color: #f4eeec;
        letter-spacing: 0.2vh;
    }
    .pre {
        margin-top: max(5.093vh, 27.5px);
        width: max(76.056vh, 367.5px);
        font-family: "Orbitron";
        font-weight: 400;
        font-size: max(1.481vh, 8px);
        line-height: max(2.093vh, 11.1px);
        text-align: center;
        color: #f4eeec;
        letter-spacing: 0.2vh;
        margin-left: max(4.722vh, 25.5px);
    }
    .sport {
        /* width: 15.093vh;
        height: 83.426vh; */
        position: absolute;
        /* transform: scale(0.8) translate(45vh, 8vh); */
        transition-duration: 1.5s;
        opacity: 0;
        font-size: 12.037vh;
        font-weight: 700;
        color: #F4EEEC;
        transform: scale(0.6) rotate(-90deg);
        top: 43vh;
        letter-spacing: 1vh;
        left: 0vh;
    }
    .scroll_div {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 2.5vh;
        right: 4vh;
        transition-duration: 1s;
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 500;
        line-height: max(1.857vh, 8px);
        font-family: "Orbitron";
        text-transform: capitalize;
        color: black;
        transition-duration: 1s;
    }
    .scroll_div .img {
        margin-left: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
        transition-duration: 1s;
        fill: black;
    }
    .btn {
        position: absolute;
        bottom: 3.2vh;
        left: 75vh;
        opacity: 0;
        transition-duration: 1s;
    }
</style>
