<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>

<section>
    <div class="main-div">
        <div class="images-div">
            <div class="mobile-div">
                <img
                    src="images/Perfomance/Group10000025751.png"
                    alt=""
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="back-div">
                <img
                    src="images/Perfomance/back4.svg"
                    alt=""
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="text-div">
                <p>Performers & Opus</p>
                <p>Choreography</p>
                <p>
                    Choreography is as old as the human subspecies itself. It’s
                    part of our genetic composition and something that enthralls
                    us all alike.
                </p>
                <p>
                    Our motion recording app turns a simple smartphone into a
                    performance capture studio without any specialized
                    equipment. Become part of the future of democratized
                    entertainment in the making.
                </p>
                <div class="btn-div">
                    <DesktopButton2
                        state = "Performer"
                        width="max(24.074vh, 130px)"
                        text1 = "Join Artist Governace Council"
                        text2="(Choreographer Seat)"
                        AnimationColor="black"
                        TextColorAfter="#67eebb"
                        BorderColorAfter="black"
                        text3="15"
                    />
                </div>
            </div>
            
        </div>
        <div class="scroll_div">
            <svg
            width="6.185vh"
            height="0.741vh"
            viewBox="0 0 72 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="img"
        >
            <path
                d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
            />
        </svg>
            <p class="scrollText" >scroll / drag</p>
           
        </div>
    </div>
</section>

<style>
    .main-div {
        width: max(121.389vh, 655.5px);
        height: max(100vh, 540px);
        overflow: hidden;
        position: relative;
    }
    .images-div {
        display: flex;
        position: relative;
    }
    .back-div {
        position: absolute;
        left: max(29.63vh, 160px);
        width: max(86.111vh, 465px);
        height: max(100vh, 540px);
        pointer-events:none ;
        z-index: -1;
    }
    .mobile-div {
        right: max(24vh, 81px);
        position: absolute;
        transform: scale(1.1);
        width: max(134.63vh, 808px);
        pointer-events:none ;
    }
    .text-div {
        position: absolute;
        right: max(14.63vh, 79px);
        width: max(64.537vh, 348.5px);
        font-family: "Orbitron";
        top: max(22.963vh, 124px);
    }
    .text-div p:nth-child(1) {
        font-size: max(1.296vh, 7px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 400;
        margin-left: max(24vh, 129.6px);
    }
    .text-div p:nth-child(2) {
        font-size: max(5.556vh, 30px);
        line-height: max(5.556vh, 30px);
        margin-top: max(1.111vh, 6px);
        margin-bottom: max(6.667vh, 36px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 700;
        margin-left: max(24vh, 129.6px);
        text-transform: uppercase;
    }
    .text-div p:nth-child(3) {
        margin-top: max(5.833vh, 61.5px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        letter-spacing: max(0.058vh, 0.5px);
        margin-left: max(28vh, 206.65px);
        width: max(35.278vh, 190.5px);
        font-weight: 400;
    }
    .text-div p:nth-child(4) {
        margin-top: max(18.148vh, 98px);
        margin-left: max(15vh, 110.7px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        letter-spacing: max(0.058vh, 0.5px);
        width: max(49.63vh, 268px);
        font-weight: 700;
    }
    .btn-div {
        margin-top: max(4.167vh, 22.5px);
        margin-left: max(15vh, 110.7px);
    }
    .scroll_div {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 2.5vh;
        left: 0vh;
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 500;
        line-height: max(1.857vh, 8px);
        font-family: "Orbitron";
        color: black;
        text-transform: capitalize;
    }
    .scroll_div .img {
        margin-right: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
        fill: black;
        transform: rotate(180deg);
}
</style>
