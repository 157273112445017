<script>
  	import pages from 'pages';
  	import { getContext } from 'svelte';

	const allpages = {...pages}
	const { url } = getContext('APP');
	let height;
	let screen = 'large';

	function dragMe(node) {
		let moving = false;
		let left = 0;
		node.style.position = 'absolute';
		node.style.userSelect = 'none';

		node.addEventListener('mousedown', () => {
			moving = true;
		});
		addEventListener('mousemove', (e) => {
			if (moving) {
				node.scrollBy(-e.movementX,0);
			}
		});	
		addEventListener('mouseup', () => {
			 moving = false;
		 });
	
	}
	$: if(height<540) {
		screen = 'small';
	} else {
		screen = 'large';
	}
</script>

<svelte:window bind:innerHeight={height} />

<section>
	<div class="main-div main-div-{screen}" >
		<div class="child-div child-div-{screen}" use:dragMe>	
			<svelte:component this={pages[$url.pathname]} />
		</div>
	</div>
		<div class="overlay-scroll-{screen}"></div>
</section>

<style>
	.main-div {
		z-index: 1;
		width: 100%;
		/* height: 120%; */
		overflow-x: hidden;
	}
	.child-div {
		width: 100%;
		/* height: 110%; */
		overflow-x: scroll;
	}
	.overlay-scroll-small {
		position: fixed;
		height: 15px;
		background-color: black;
		z-index: 2;
		bottom: 0;
		width: 100%;
		border-radius: 3px;
	}
	.child-div-small {
		overflow-y: scroll;
		/* height: 100%; */
	}
	.child-div-large {
		overflow: scroll;
		height: 100%;
	}
	.child-div-large::-webkit-scrollbar {
  display: none;}
	.main-div-small {
		overflow-y: scroll;
	}
	.main-div-large {
		overflow-y: hidden;
	}
</style>
