<script>
    import LandingPagenavbar from '../reusableitems/landingPagenavbar.svelte';
    import OnPageLoad from '../reusableitems/onPageLoad.svelte';
    import TeamCard from '../reusableitems/teamCard.svelte';
    import rpcjs from 'rpcjs';
    const {title} = rpcjs.props;
    title.set('Team');
    const card = [
        {
            imgsrc: '/images/team/adnan.png',
            name: 'Adnan Yunus',
            title: 'Founder',
            desigination: 'CEO',
            role: 'Data Scientist',
            href: 'https://twitter.com/udnaan',
            linked: '/images/team/twiter.svg',
        },
        {
            imgsrc: '/images/team/asra.png',
            name: 'Asra Nadeem',
            title: 'Co - Founder',
            desigination: 'COO',
            role: 'Business Development',
            href: 'https://www.linkedin.com/in/asranadeem/',
            linked: '/images/team/linkden.svg',
        },
        {
            imgsrc: '/images/team/zaeem.png',
            name: 'Zaeem Zoqi',
            title: '',
            desigination: 'Head of Design & Creative',
            role: '',
            href: 'https://www.linkedin.com/in/zaeem-zoqi-19671189/',
            linked: '/images/team/linkden.svg',
        },
        {
            imgsrc: '/images/team/umair.png',
            name: 'Umair Khalil',
            title: '',
            desigination: 'Head of Technical &<br> Development',
            role: '',
            href: 'https://www.linkedin.com/in/umair-khalil-4a4b621a6/',
            linked: '/images/team/linkden.svg',
        },
    ];
    const design = [
        {
            imgsrc: '/images/team/abdullah.png',
            name: 'Abdullah Qamar',
            title: '',
            desigination: 'Visual Artist, 3D Design',
            role: '',
            href: 'https://www.linkedin.com/in/abdullah-qamar-618057206/',
        },
        {
            imgsrc: '/images/team/tahir.png',
            name: 'Tahir',
            title: '',
            desigination: 'User Interface / User Experience',
            role: '',
            href: 'https://www.linkedin.com/in/tahir-mubeen-531b40199/',
        },
        {
            imgsrc: '/images/team/ibraheem.png',
            name: 'Ibrahim Zoqi',
            title: '',
            desigination: 'User Interface / User Experience,<br>Graphic Design',
            role: '',
            href: 'https://www.linkedin.com/in/ibrahim-zoqi-a97b20233/',
        },
    ];
    const development = [
        {
            imgsrc: '/images/team/zain.png',
            name: 'Muhammad Zain',
            title: '',
            desigination: 'Software Engineer',
            role: '',
            href: 'https://www.linkedin.com/in/muhammad-zain-47412a1b3',
        },
        {
            imgsrc: '/images/team/usjad.png',
            name: 'M. Usjad',
            title: '',
            desigination: 'Associate Software Engineer',
            role: '',
            href: 'https://www.linkedin.com/in/muhammadusjadchaudhry',
        },
        {
            imgsrc: '/images/team/rizwana.png',
            name: 'Rizwana Younas',
            title: '',
            desigination: 'Associate Software Engineer',
            role: '',
            href: 'https://www.linkedin.com/in/rizwana-younas-50514956',
        },
        {
            imgsrc: '/images/team/awais.png',
            name: 'M. Awais',
            title: '',
            desigination: 'Associate Software Engineer',
            role: '',
            href: 'https://www.linkedin.com/in/muhammad-awais-a9245016a',
        },
        {
            imgsrc: '/images/team/hafsa.png',
            name: 'Hafsa Khalil',
            title: '',
            desigination: 'Associate Software Engineer',
            role: '',
            href: 'https://www.linkedin.com/in/hafsa-khalil',
        },
        {
            imgsrc: '/images/team/ashar.png',
            name: 'Ashar Khan',
            title: '',
            desigination: 'Associate Software Engineer',
            role: '',
            href: 'https://www.linkedin.com/in/ashar-khan-71703023b/',
        },
        {
            imgsrc: '/images/team/rehman.png',
            name: 'Rehman Afzal',
            title: '',
            desigination: 'Associate Software Engineer',
            role: '',
            href: 'https://www.linkedin.com/in/rehman-afzal-1285b320b',
        },
        {
            imgsrc: '/images/team/sami.png',
            name: 'Sami Mazhar',
            title: '',
            desigination: 'Associate Software Engineer',
            role: '',
            href: 'https://www.linkedin.com/in/sami-mazhar-60905b185',
        },
        {
            imgsrc: '/images/team/umer.png',
            name: 'Muhammad Umar',
            title: '',
            desigination: 'Associate Software Engineer',
            role: '',
            href: 'https://www.linkedin.com/in/muhammad-umer-8b162720a/',
        },
    ];
    const GROWTH = [
        {
            imgsrc: '/images/team/khudija.png',
            name: 'Khudija Tariqr',
            title: '',
            desigination: 'Content Producer',
            role: '',
            href: 'https://www.linkedin.com/in/khudija-tariq-60a94a172/',
        },
        {
            imgsrc: '/images/team/ibraheem2.png',
            name: 'Ibraheem Zahid',
            title: '',
            desigination: 'Organic Growth Manager',
            role: '',
            href: 'https://www.linkedin.com/in/ibraheem-zahid-a706b8180/',
        },
    ];
    const GOVERNMENTRELATIONS = [
        {
            imgsrc: '/images/team/umerDaud.png',
            name: 'Umar Daud',
            title: '',
            desigination: 'Federal Conctracts & Grant Proposal Writer',
            role: '',
            href: 'https://www.linkedin.com/in/umardaud/',
        },
        {
            imgsrc: '/images/team/danial.png',
            name: 'Daniyal Ahmed',
            title: '',
            desigination: 'Scientific Research Associate',
            role: '',
            href: 'https://www.linkedin.com/in/dak-li',
        },
    ];
</script>

<div class="navbar">
    <LandingPagenavbar textClrAfter='white' textclr='black' borderclr='black' bgClrAfter='black'/>
</div>
<div>
    <OnPageLoad
    background='conic-gradient( white 0deg 90deg, #FDC55E 90deg 270deg, white 270deg 360deg)'
     />
</div>
<div class="teamPageWrraper">
    <div class="theTeam">
        <div class="theTeamContentHOlder">
            <h1>THE TEAM</h1>
        </div>
    </div>
    <div class="teamMembers">
        <div class="card">
            {#each card as data}
                <TeamCard
                    src={data.imgsrc}
                    title={data.title}
                    desigination={data.desigination}
                    role={data.role}
                    name={data.name}
                    href = {data.href}
                    linked = {data.linked}
                />
            {/each}
            <div class="scroll_div">
            
                <p class="scrollText" >scroll / drag</p>
                <svg
                width="6.185vh"
                height="0.741vh"
                viewBox="0 0 72 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="img"
            >
                <path
                    d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
                />
            </svg>
            </div>
        </div>
        
        <div class="theDesignTeam">
            <div class="theDesignTeamContentHOlder">
                <h1>DESIGN</h1>
            </div>
        </div>
        <div class="card">
            {#each design as data}
                <TeamCard
                    src={data.imgsrc}
                    title={data.title}
                    desigination={data.desigination}
                    role={data.role}
                    name={data.name}
                    href = {data.href}
                />
            {/each}
            <div class="scroll_div" style="right: 18vh;">
            
                <p class="scrollText" >scroll / drag</p>
                <svg
                width="6.185vh"
                height="0.741vh"
                viewBox="0 0 72 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="img"
            >
                <path
                    d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
                />
            </svg>
            </div>
        </div>
        
        <div class="theDevelopmentTeam">
            <div class="theDevelopmentTeamContentHOlder">
                <h1>DEVELOPMENT</h1>
            </div>
        </div>
        <div class="card">
            {#each development as data}
                <TeamCard
                    src={data.imgsrc}
                    title={data.title}
                    desigination={data.desigination}
                    role={data.role}
                    name={data.name}
                    href = {data.href}
                />
            {/each}
            <div class="scroll_div" style="right: 30vh;">
            
                <p class="scrollText" >scroll / drag</p>
                <svg
                width="6.185vh"
                height="0.741vh"
                viewBox="0 0 72 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="img"
            >
                <path
                    d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
                />
            </svg>
            </div>
        </div>
        <div class="theGROWTHTeam">
            <div class="theGROWTHTeamContentHOlder">
                <h1>GROWTH & MARKETING</h1>
            </div>
        </div>
        <div class="card">
            {#each GROWTH  as data}
                <TeamCard
                    src={data.imgsrc}
                    title={data.title}
                    desigination={data.desigination}
                    role={data.role}
                    name={data.name}
                    href = {data.href}
                />
            {/each}
            <div class="scroll_div" style="right:8vh;">
            
                <p class="scrollText" >scroll / drag</p>
                <svg
                width="6.185vh"
                height="0.741vh"
                viewBox="0 0 72 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="img"
            >
                <path
                    d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
                />
            </svg>
            </div>
        </div>
        <div class="theGOVERNMENTRELATIONSTeam">
            <div class="theGOVERNMENTRELATIONSTeamContentHOlder">
                <h1>GOVERNMENTRELATIONS</h1>
            </div>
        </div>
        <div class="card">
            {#each GOVERNMENTRELATIONS as data}
                <TeamCard
                    src={data.imgsrc}
                    title={data.title}
                    desigination={data.desigination}
                    role={data.role}
                    name={data.name}
                    href = {data.href}
                />
            {/each}
        </div>
    </div>
</div>

<style>
    .navbar {
        position: fixed;
        width: 100%;
        z-index: 10;
    }
    .teamPageWrraper {
        position: relative;
        height: 100%;
        display: flex;
    }
    .theTeam {
        width: max(17.037vh, 92px);
        height: max(100vh, 540px);
        position: fixed;
        z-index: 7;
        background-color:white;
    }
    .theTeam h1 {
        font-size: max(9.259vh, 50px);
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 700;
        width: max(56.574vh, 305.5px);
        margin: 0;
    }
    .theTeamContentHOlder {
        position: relative;
        transform: rotate(-90deg);
        top: max(69.519vh, 235px);
    }
    .teamMembers {
        background-color: #f4eeec;
        width: max(204.4vh , 960px);
        /* overflow-x: scroll; */
        display: flex;
        margin-left: 17vh;
    }
    .card {
        height: max(82.75vh, 436.5px);
        width: fit-content;
        display: flex;
        background-color: #f4eeec;
        position: relative;
        top: 17vh;
        gap: 0vh 7vh;
        padding: 0vh 8vh;
    }
    .theDesignTeam {
        width: max(28.056vh,151.5px);
        height: max(100vh, 540px);
        margin: 0 0.5vh;
        background-color: rgba(169, 255, 112, 0.7);
    }
    .theDesignTeam h1 {
        font-size: max(5.556vh, 30px);
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 700;
        width: max(56.574vh, 305.5px);
        margin: 0;
    }
    .theDesignTeamContentHOlder {
        position: relative;
        transform: rotate(-90deg);
        top: max(35.111vh, 195px);
        left: max(10.093vh, 27.5px);
    }
    .theDevelopmentTeam {
        width: max(28.056vh,151.5px);
        height: max(100vh, 540px);
        margin: 0 0.5vh;
        background-color:  #FF9B9B;
    }
    .theDevelopmentTeam h1 {
        font-size: max(5.556vh, 30px);
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 700;
        width: max(56.574vh, 305.5px);
        margin: 0;
    }
    .theDevelopmentTeamContentHOlder {
        position: relative;
        transform: rotate(-90deg);
        top: max(60.111vh, 195px);
        left: max(10.093vh, 27.5px);
    }
    .theGROWTHTeam {
        width: max(28.056vh,151.5px);
        height: max(100vh, 540px);
        margin: 0 0.5vh;
        background-color: #FDC55E;
    }
    .theGROWTHTeam h1 {
        font-size: max(5.556vh, 30px);
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 700;
        width: max(77.315vh, 417.5px);
        margin: 0;
    }
    .theGROWTHTeamContentHOlder {
        position: relative;
        transform: rotate(-90deg);
        top: max(72.111vh, 195px);
        left: max(10.093vh, 27.5px);
    }
    .theGOVERNMENTRELATIONSTeam {
        width: max(28.056vh,151.5px);
        height: max(100vh, 540px);
        margin: 0 0.5vh;
        background-color: #75B5F8;
    }
    .theGOVERNMENTRELATIONSTeam h1 {
        font-size: max(5.556vh, 30px);
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 700;
        width: max(77.315vh, 417.5px);
        margin: 0;
    }
    .theGOVERNMENTRELATIONSTeamContentHOlder {
        position: relative;
        transform: rotate(-90deg);
        top: max(72.111vh, 195px);
        left: max(10.093vh, 27.5px);
    }
    .scroll_div {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 2.5vh;
        right: 43vh;
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 500;
        line-height: max(1.857vh, 8px);
        font-family: "Orbitron";
        color: black;
        text-transform: capitalize;
    }
    .scroll_div .img {
        margin-left: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
        fill: black;
    }
</style>