<script>
    import Mobilebtn2 from '../mobilebtn2.svelte';
</script>

<div class="gamerWrraper">
    <div class="imageWrraper">
        <img
            src="/images/HealerPolished.png"
            alt="robot"
            width="auto"
            height="100%"
        />
    </div>
    <div class="heading">
        <h1>GAMERS</h1>
        <div class="para">
            <p>
                Play in an ever changing world in which you aren’t merely a
                player and also a creator and administrator.
            </p>
            <Mobilebtn2
                bgcolor="transparent"
                text="Learn More"
                width="23.364vw"
                height="7.477vw"
            />
        </div>
    </div>
</div>

<style>
    .gamerWrraper {
        width: 100vw;
        height: 216.355vw;
        overflow: hidden;
        position: relative;
        font-family: "Orbitron";
        background-color: #807cf9;
    }
    .imageWrraper {
        left: 0vw;
        top: 20vw;
        width: 152.57vw;
        height: 133.645vw;
        position: relative;
    }
    .heading h1 {
        color: #f4eeec;
        margin-bottom: 0;
        font-weight: 900;
        font-size: 21.028vw;
        line-height: 26.402vw;
        text-transform: uppercase;
    }
    .para {
        margin: auto;
        width: 73.832vw;
        margin-top: -5vw;
    }
    .heading p {
        font-weight: 400;
        color: #e4e3ff;
        line-height: 140%;
        font-size: 3.271vw;
        letter-spacing: 0.06em;
    }
</style>
