<script>
    import DesktopButton from '../../reusableitems/DesktopButton.svelte';
</script>
<section>
        <div class="maindiv">
            <div class="onediv">
                <p>6</p>
            </div>
            <div class="virtualhead">
                <p>Musicians & <br>Sound Artists</p>
            </div>
            <div class="descriptiontext" >
                <p>No party is complete without music. 
                </p>
                    <p style="padding: 1.85vh 0;">We’re gathering the most innovative creative  around the globe to tinker, converse and decide the future of entertainment and gaming. </p>
                    
                   <p style="font-weight: 700;">You're awarded an exclusive ticket to the party. Govern the rules of community and vote on the direction of the technology and get rewarded for it.</p>  
                   <p style="padding: 1.85vh 0;">And while you’re at it, let's agree on an official soundtrack (we won’t stop you if you want to compose one, promise!). 
                </p>
             </div>
             <div class="btn">
                <DesktopButton
                state = "Artists & Musicians"
                text="Join Musicians Governance Council"
                TextColor='black'
                BackColor= 'white'
                borderClr= '0.1vw solid black'
                AnimationColor= 'aqua'
                TextColor2='black' 
                TextColorAFter='black'
                BorderClrAfter='0.1vw  aqua'/>
             </div>
             <div class="sound">
                <img src="/images/Artist/sound.png" alt="" width="100%" height="100%">
             </div>
             <div class="soundvec">
                <img src="/images/Artist/vectorsound.png" alt="" width="100%" height="100%">
             </div>
        </div>
</section>
<style>
    .maindiv{
        position: relative;
        width: max(165.370vh , 893px);
        background-color: white;
        font-family: 'Orbitron';
        height: max(100vh, 540px);
    }
    p{
        margin: 0;
    }
    .virtualhead{
        font-weight: 700;
        font-size: max(5.556vh , 30px);
        line-height: max(5.556vh , 30px);
        text-transform: uppercase;
        color: #000000;
        position: absolute;
        left: max(23.704vh , 128px);
        top: max(25.093vh , 135.5px);
    }
    .onediv{
        font-size: max(55.556vh , 300px);
        color: rgba(244, 238, 236, 0.5);
        text-transform: uppercase;
        font-weight: 700;
        line-height: max(55.556vh , 300px);
        position: absolute;
        left: max(8.148vh , 44px);
    }
    .descriptiontext{
        position: absolute;
        right: max(4.907vh , 26.5px);
        top: max(9.815vh , 53px);
        width: max(72.963vh , 392px);
        font-size: max(1.852vh ,10px);
        font-weight: 400;
        line-height: max(2.593vh , 14px);
        color: #000000;
        letter-spacing: 0.12vh;
    }
    .btn{
        position: absolute;
        right: max(4.907vh , 26.5px);
        top: max(46.019vh , 248.5px);
    }
    .sound{
        position: absolute;
        bottom: 0;
        width: max(165.370vh , 893px);
    }
    .sound::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    .soundvec{
        position: absolute;
        bottom: 2.5vh;
        width: max(53.463vh , 192.5px);
        left: 54.1vh;
        animation: move 1.2s infinite;
    }
    .soundvec::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    @keyframes move {
        0% {
            transform:scale(1.03);
        }
        100% {
            transform:scale(1);
        }
    }
    </style>