<script>
    const info = [
        {
            image: 'images/Lighting.png',
            number: '01',
            name: 'Lighting',
        },
        {
            image: 'images/Camera.png',
            number: '02',
            name: 'Camera Control',
        },
        {
            image: 'images/Terrain.png',
            number: '03',
            name: 'Terrain',
        },
        {
            image: 'images/Trees.png',
            number: '04',
            name: 'Trees. grass, flora.',
        },
        {
            image: 'images/House.png',
            number: '05',
            name: 'Houses & Buildings',
        },
        {
            image: 'images/Roads.png',
            number: '06',
            name: 'Roads',
        },
        {
            image: 'images/Humanoid.png',
            number: '07',
            name: 'Humanoid Characters',
        },
        {
            image: 'images/Animations.png',
            number: '08',
            name: 'Animations',
        },
    ];
    const animate = [
        {
            gif: 'images/landingPage/videos/video1.mp4',
        },
        {
            gif: 'images/landingPage/videos/video2.mp4',
        },
        {
            gif: 'images/landingPage/videos/video3.mp4',
        },
    ];
    const socialInfo = [
        {
            icon: 'images/twiter.svg',
            name: '@OpusAIInc',
            link: 'https://twitter.com/OpusAIInc',
        },
        {
            icon: 'images/linkedin.svg',
            name: '@OpusAI',
            link: 'https://www.linkedin.com/company/18782408/admin/',
        },
        {
            icon: 'images/youtube.svg',
            name: '@OpusAI',
            link: 'https://www.youtube.com/c/opusai',
        },
        // {
        //     icon: 'images/discord.svg',
        //     name: 'Join Discord',
        //     link: 'https://discord.gg/Mz3t8NsN',
        // },
    ];
</script>

<div class="mainDiv">
    <div class="headingDiv">
        <h4>FEATURES</h4>
    </div>
    <div class="imgsection">
        {#each info as data}
            <div class="imgdiv">
                <div class="imgcss">
                    <img src={data.image} alt="" width="100%" height="100%" />
                </div>
                <div class="textDiv">
                    <div class="number">
                        <p>{data.number}</p>
                    </div>
                    <div class="number">
                        <p>{data.name}</p>
                    </div>
                </div>
            </div>
        {/each}
    </div>
    <div class="lastSection">
        <div class="keep">
            <p>KEEP UP TO DATE</p>
        </div>
        <div style="display: flex;    margin-top: 2.778vh;">
            <div class="Follow">
                <p>FOLLOW US</p>
            </div>
            <div class="community">
                <h4>
                    The latest from <br />
                    Opus community
                </h4>
            </div>
        </div>
        <div class="gifDiv">
            {#each animate as data}
                <div class="gifimg">
                    <video width="100%" height="100%"  autoplay loop muted>
                        <source src= {data.gif} type="video/mp4">
                    </video>
                </div>
            {/each}
        </div>
        {#each socialInfo as info}
         <a href={info.link} target="_blank">
            <div class="socialDiv">
                <div class="icon">
                    <img src={info.icon} alt="" width="100%" height="100%" />
                </div>
                <div class="link">
                    <p>{info.name}</p>
                </div>
            </div>
        </a>
        {/each}
    </div>
</div>

<style>
    .mainDiv {
        position: relative;
        font-family: "Orbitron";
        display: flex;
        height:max(100vh, 540px);
    }
    .imgsection {
        background-color: #f4eeec;
        padding-top: max(18.519vh , 100px);
        padding-left: 3.426vh;
        padding-right: 18.519vh;
        display: flex;
    }
    .imgdiv {
        position: relative;
        width: max(54.444vh, 294px);
        height: max(67.593vh , 365px);
        background-color: white;
        margin-right: 4.63vh;
        padding: 3.519vh 3.519vh 0vh;
        box-shadow: 0px 0.37vh 2.963vh rgba(0, 0, 0, 0.25);
    }
    .imgdiv::after {
        content: "";
        position: absolute;
        width: max(19.074vh, 103px);
        height: max(10.4vh , 75px);
        background-color: inherit;
        left: max(20vh , 103.5px);
        top: max(71.112vh, 365px);
    }
    .imgcss {
        height: max(53.519vh , 289px);
        width: 100%;
        overflow: hidden;
    }
    .imgcss::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    .imgdiv:nth-child(1) .imgcss img {
        transition: 1.5s ease;
        transform: scale(1.2) translate(-20vh, 5vh);
        width:  max(71vh , 380px);
        height: max(55vh , 289px) ;
    }
    .imgdiv:nth-child(1):hover .imgcss img {
        transform: scale(1.75) translate(14vh, -6vh);
    }
    .imgdiv:nth-child(2) .imgcss img {
        transition: 1.5s ease;
        transform: scale(0.6) translate(-56vw, -43vh);
        width: 100vw;
        height: 100vh;
    }
    .imgdiv:nth-child(2):hover .imgcss img {
        transform: scale(0.6) translate(-42vw, -32vh);
        width: 100vw;
        height: max(92vh , 671px);
    }
    .imgdiv:nth-child(3) .imgcss img {
        transition: 1.5s ease;
        transform: scale(1) translate(0vh, -3vh);
        width: max(95vh , 350px);
        height: max(57vh , 305px);
    }
    .imgdiv:nth-child(3):hover .imgcss img {
        transform: scale(0.8) translate(-73vh, -32vh);
        width: max(134vh , 592px);
        height: max(92vh , 478px);
    }
    .imgdiv:nth-child(4) .imgcss img {
        transition: 1.5s ease;
        transform: scale(0.9) translate(-47vh, -2.8vw);
        width: max(102vh , 543px);
        height: max(61.8vh , 376px);
    }
    .imgdiv:nth-child(4):hover .imgcss img {
        transform: scale(0.8) translate(-23vh, -16vh);
        width: max(180vh , 350px);
        height: max(90vh , 367px);
    }
    .imgdiv:nth-child(5) .imgcss img {
        transition: 1.5s ease;
        transform: scale(0.7) translate(-67vh, -22.5vh);
        width: max(150vh , 515px);
        height: 100vh;
    }
    .imgdiv:nth-child(5):hover .imgcss img {
        transform: scale(1.25) translate(-55vh, -21vh);
        width: max(180vh , 400px);
        height: max(90vh , 350px);
    }
    .imgdiv:nth-child(6) .imgcss img {
        transition: 1.5s ease;
        transform: scale(2.2);
    }
    .imgdiv:nth-child(6):hover .imgcss img {
        transform: scale(1.2);
    }
    .imgdiv:nth-child(7) .imgcss img {
        transition: 1.5s ease;
    }
    .imgdiv:nth-child(7):hover .imgcss img {
        transform: scale(1.7);
    }
    .imgdiv:nth-child(8) .imgcss img {
        transition: 1.5s ease;
        transform: scale(0.73) translate(-42vh, -20vh);
        width: max(150vh , 350px);
        height: 100vh;
    }
    .imgdiv:nth-child(8):hover .imgcss img {
        transform: scale(0.73) translate(-94vh, -20.3vh);
        width: max(180vh , 350px);
        height: 100vh;
    }
    .headingDiv {
        background-color: black;
        width: max(19.074vh, 103px);
        padding: 0vh 3.519vh;
    }
    .headingDiv h4 {
        margin: 0;
        color: black;
        font-size: max(9.259vh , 50px );
        font-weight: 900;
        line-height: max(9.259vh , 50px );
        text-transform: capitalize;
        -webkit-text-stroke: 0.185vh white;
        transform: rotate(-90deg);
        margin-top: max(64vh , 330.5px);
    }
    .textDiv {
        display: flex;
        justify-content: space-between;
        margin-top: 1.204vh;
    }
    p {
        margin: 0;
    }
    .number {
        font-size: max(2.778vh , 15px);
        color: #000000;
    }
    .lastSection {
        background-color: black;
        height:max(100vh, 540px);
        padding-left: 5.648vh;
        z-index: 10;
        position: relative;
    }
    .keep {
        width: max(91.204vh, 536px);
        font-size: max(1.111vh , 6px);
        color: white;
        margin-top: 18.519vh;
        text-transform: capitalize;
    }
    .Follow {
        font-size: max(1.111vh , 6px);
        color: white;
        margin-top: 5.111vh;
        margin-bottom: 1vw;
        text-transform: capitalize;
        transform: rotate(-90deg);
    }
    .community h4 {
        margin: 0;
        font-weight: 900;
        font-size: max(5.556vh , 30px);
        line-height: max(5.556vh , 30px);
        color: #ffffff;
        border: 0.093vh solid #000000;
    }
    .gifDiv {
        padding-left: 5.833vh;
        display: flex;
        margin-top: 6.667vh;
        padding-bottom: max(7.6vh , 40.5px);
    }
    .gifimg {
        width: max(23.333vh, 126px);
        height: max(23.333vh, 126px);
        margin-right: 3.611vh;
    }
    .gifimg img {
        object-fit: cover;
    }
    .socialDiv {
        margin-left: 5.833vh;
        padding: 1.759vh 0vh;
        width: max(33.981vh, 183.5px);
        border-top: 0.093vh solid #fff;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .icon {
        width: max(5.556vh, 30px);
    }
    .icon img {
        width: max(2.778vh, 15px);
    }
    .link {
        font-size: max(1.111vh , 6px);
        color: white;
        text-transform: capitalize;
    }
</style>
