<script>
    import MobileButton from '../reusableitems/mobileButton.svelte';
    let sideMenu;
    let sideMenuInner;
    export let Color = 'white';
</script>

    <nav>
        <div class="maindiv" style="border-bottom: 0.234vw solid {Color};">
            <div class="logoHeading" on:click={()=>{
                location.href='/MobileLandingPage';
            }}>
                <h4 style="color: {Color};">
                    opus<span style="font-weight: 400; font-size:3.271vw;"
                        >AI</span
                    >
                </h4>
            </div>
            
            <div
                class="icon"
                on:click={() => {
                    sideMenu.style.left = '0';
                    sideMenu.style.transitionDuration = '1s';
                    sideMenuInner.style.left = '33vw';
                    sideMenuInner.style.transitionDuration = '1s';
                }}
            >
                <svg
                    width="4.832vw"
                    height="3.037vw"
                    viewBox="0 0 21 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.318359"
                        width="20.6818"
                        height="1.18182"
                        fill={Color}
                    />
                    <rect
                        x="0.318359"
                        y="5.90909"
                        width="20.6818"
                        height="1.18182"
                        fill={Color}
                    />
                    <rect
                        x="0.318359"
                        y="11.8182"
                        width="20.6818"
                        height="1.18182"
                        fill={Color}
                    />
                </svg>
            </div>
        </div>
    <div
        class="menu_back"
        bind:this={sideMenu}
        on:click={() => {
            sideMenu.style.left = '100vw';
            sideMenu.style.transitionDuration = '1s';
            sideMenu.style.top = '0vw';
            sideMenuInner.style.left = '100vw';
            sideMenuInner.style.transitionDuration = '1s';
            sideMenuInner.style.top = '0vw';
        }}
    >
        <div class="menu_Section" bind:this={sideMenuInner}>
            <div class="logo">
                <img
                    src="/images/opus_logo.svg"
                    alt=""
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="text" >
                <p on:click={() => {
                    location.href='/consumerpagemobile';
              }}>Consumer</p>
            </div>
            <div class="text">
                <p>Advertisement</p>
            </div>
            <div class="text">
                <p>Investors</p>
            </div>
            <div class="text">
                <p  on:click= {() => {
                    location.href='/Team';
                }}>Team</p>
            </div>
            <div class="btnMenu">
                <MobileButton title="Join Community" textClr="black" />
            </div>
        </div>
    </div>
</nav>
<style>
    
    nav {
        position: fixed;
        z-index: 15;
        width: 100%;
    }
    .maindiv {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 17.757vw 0vw 4.439vw 0vw;
    }
    h4,
    p {
        margin: 0;
        font-family: "Orbitron";
    }
    .logoHeading {
        width: 100vw;
        text-align: center;
    }
    .logoHeading h4 {
        font-size: 7.009vw;
        text-transform: uppercase;
        color: white;
        font-weight: 900;
        text-align: center;
        padding-left: 5.2vw;
    }
    .icon {
        padding-right: 1.105vw;
    }
    .menu_back {
        background-color: #cdd4e11f;
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 100vw;
        z-index: 3;
        top: 0vw;
    }
    .menu_Section {
        position: fixed;
        height: 100vh;
        width: 73.364vw;
        background-color: #ffffff;
        z-index: 3;
        right: 0vw;
        cursor: pointer;
        left: 100vw;
        top: 0vw;
    }
    .logo {
        width: 100vw;
        height: 19.86vw;
        background-color: black;
    }
    .logo img {
        width: 24.065vw;
        margin-left: 22.196vw;
    }
    .text {
        font-size: 2.804vw;
        color: black;
        padding-top: 11.215vw;
        text-align: center;
        font-weight: 700;
    }
    .btnMenu {
        left: 24.299vw;
        position: absolute;
        bottom: 18.166vw;
    }
</style>
