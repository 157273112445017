<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>

<div class="main-div">
    <div class="left-div">
        <p class="text1">CASUAL</p>
        <p class="text2">GAMERS</p>

        <p class="text3">
            More into occasional matching of bejewels, popping of virtual
            balloons and restaurant simulation games? <br /> <br />

            Juggling your daily routine, job and family alongside an unrealized
            passion for games that you just didn’t have the time to grow into a
            serious hobby?
        </p>

        <p class="text4">
            Escape into the dynamic world created by players like you, tailored
            for injecting fun into a busy life. We’re building the future of
            casual gaming narrated and produced by players like you. Step into
            it.
        </p>
        <div class="btn">
            <DesktopButton2 
            state = "Games"/>
        </div>
    </div>

    <div class="right-div">
        <img
            src="/images/Gamer/controller.png"
            width="100%"
            height="100%"
            alt=""
        />
    </div>
</div>

<style>
    .main-div {
        display: flex;
        height: max(100vh, 540px);
        overflow: hidden;
        justify-content: space-between;
    }
    .left-div {
        margin-left: max(11.944vh, 64.5px);
        width: max(53vh, 465px);
        padding-top: max(16.759vh, 90.5px);
    }
    p {
        font-family: "Orbitron";
    }
    .text1 {
        font-size: max(7.407vh, 40px);
        line-height: max(7.407vh, 40px);
        color: #615bff;
        font-weight: 700;
        margin: 0;
    }
    .text2 {
        padding-left: max(15.463vh, 83.5px);
        font-size: max(7.407vh, 40px);
        line-height: max(7.407vh, 40px);
        color: #615bff00;
        font-weight: 700;
        -webkit-text-stroke: 0.185vh black;
        margin: 0;
    }
    .text3 {
        font-size: max(1.481vh, 8px);
        color: black;
        line-height: max(2.074vh , 11.2px);
        width: max(52vh, 288px);
        font-weight: 400;
        letter-spacing: 0.04vw;
        margin-top: max(9.093vh, 27.5px);
    }
    .text4 {
        font-size: max(1.852vh, 10px);
        color: black;
        width: max(51.333vh, 288px);
        font-weight: 700;
        line-height: max(2.593vh , 14px);
        margin-top: max(5.278vh, 28.5px);
        letter-spacing: 0.04vw;
    }
    .right-div {
        width: max(119.222vh, 552px);
        height: 99.5vh;
        transform: scale(1.05) translate(-1vh, -2vh);
    }
    .btn {
        margin-top: max(7.13vh, 38.5px);
    }
    .right-div::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
</style>
