<script>
    import {fade} from 'svelte/transition';
    import {NotFirstVisitLanding} from '../store';
    let show = true;
    setTimeout(()=> {
        show=false;
        NotFirstVisitLanding.update((n) => true);
    }, 3600);
</script>

{#if show}
    <div class="animationWrraper" transition:fade="{{duration: 1090}}">
        <div class="typeText">
            <h1>TYPE & PLAY</h1>
        </div>
        <div class="overLayDiv" />
        <div class="opus">
            <h1>O</h1>
            <h1>P</h1>
            <h1>U</h1>
            <h1>S</h1>
        </div>
        {#if $NotFirstVisitLanding}
            <div class="skipButton" on:click={()=>{
                show=false;
                }}>
                <h1>Skip</h1>
            </div>
        {/if}
    </div>
{/if}
<style>
    .skipButton{
        position: absolute;
        bottom: 2vh;
        right: 2vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: fit-content;
        text-align: center;
        height: 5vh;
        width: fit-content;
        padding: 0.1vh 3vh;
        cursor: pointer;
    }
    .skipButton h1{
        font-size: 1vw;
        color:black;
        font-family: "Roboto Mono";
        font-weight: 800;
    }
    .animationWrraper {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 100;
    }
    .typeText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .typeText h1 {
        font-family: "Roboto Mono";
        font-style: normal;
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 6.667vw;
        letter-spacing: 0.741vw;
        animation: textclr 0.5s 1s forwards;
    }
    @keyframes textclr {
        0% {
            color: black;
        }
        100% {
            color: #e3e3e3;
        }
    }
    .overLayDiv {
        position: absolute;
        top: 50%;
        left: 0%;
        width: 34vw;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 3.75vw;
        border-left: 0.313vw solid black;
        animation: type 0.5s 0.5s forwards, linebar 0.6s 2.5s forwards,
            linewidth 0.9s 3s forwards;
    }
    @keyframes type {
        0% {
            left: 50%;
            width: 34vw;
        }
        10% {
            left: 53%;
        }
        13% {
            left: 53%;
        }
        20% {
            left: 56%;
        }
        23% {
            left: 56%;
        }
        30% {
            left: 59%;
        }
        33% {
            left: 59%;
        }
        40% {
            left: 62%;
        }
        43% {
            left: 62%;
        }
        50% {
            left: 70%;
        }
        53% {
            left: 70%;
        }
        60% {
            left: 75%;
        }
        63% {
            left: 75%;
        }
        70% {
            left: 78%;
        }
        73% {
            left: 78%;
        }
        80% {
            left: 81%;
        }
        83% {
            left: 81%;
        }
        90% {
            left: 83%;
            width: 34vw;
        }
        100% {
            left: 65.8%;
            width: 0vw;
        }
    }
    @keyframes linebar {
        0% {
            height: 4.667vw;
            border-left: 0.313vw solid black;
        }
        100% {
            height: 100vw;
            border-left: 0.781vw solid black;
        }
    }
    @keyframes linewidth {
        0% {
            width: 0vw;
            left: 65.8%;
            background-color: black;
        }
        100% {
            width: 100vw;
            background-color: black;
            left: 50%;
        }
    }
    .opus {
        position: absolute;
    }
    .opus h1 {
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 900;
        font-size: 31.25vw;
        line-height: 752px;
    }
    .opus h1:nth-child(1) {
        margin-top: 16vw;
        margin-left: -7vw;
        opacity: 0%;
        animation: o 0.18s 1s forwards;
    }
    @keyframes o {
        0% {
            opacity: 0%;
        }
        95% {
            opacity: 100%;
        }
        100% {
            opacity: 0%;
        }
    }
    .opus h1:nth-child(2) {
        margin-top: -88vw;
        margin-left: 20vw;
        opacity: 0%;
        animation: p 0.18s 1.3s forwards;
    }
    @keyframes p {
        0% {
            opacity: 0%;
        }
        95% {
            opacity: 100%;
        }
        100% {
            opacity: 0%;
        }
    }
    .opus h1:nth-child(3) {
        margin-top: -55vw;
        margin-left: 72vw;
        opacity: 0%;
        animation: u 0.18s 1.6s forwards;
    }
    @keyframes u {
        0% {
            opacity: 0%;
        }
        95% {
            opacity: 100%;
        }
        100% {
            opacity: 0%;
        }
    }
    .opus h1:nth-child(4) {
        margin-top: -38.5vw;
        margin-left: 65vw;
        opacity: 0%;
        animation: s 0.18s 1.9s forwards;
    }
    @keyframes s {
        0% {
            opacity: 0%;
        }
        95% {
            opacity: 100%;
        }
        100% {
            opacity: 0%;
        }
    }
</style>
