<script>
    import Mobilebtn2 from '../mobilebtn2.svelte';

    const menulist = [
        {Menu: 'Dreamers'},
        {Menu: 'Gamers'},
        {Menu: 'Artists'},
        {Menu: 'Storytellers'},
        {Menu: 'Musicians'},
        {Menu: 'Advertisers'},
        {Menu: 'Investors'},
        {Menu: ' & Technical Creators'},
    ];
    let state1;
    let state2;
</script>

<div class="mainDiv">
    <div class="navbar">
        <div class="logosideWrraper">
            <div class="logoWrraper">
                <img
                    src="/images/mobileNavLogo.png"
                    alt="logo"
                    width="100%"
                    height="100%"
                />
            </div>
        </div>
        <div class="baricon">
            <svg
                width="5.076vw"
                height="5.076vw"
                viewBox="0 0 40 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="7.47363"
                    y1="2.5"
                    x2="37.4998"
                    y2="2.5"
                    stroke="black"
                    stroke-width="5"
                    stroke-linecap="round"
                />
                <line
                    x1="18.3438"
                    y1="15.1821"
                    x2="37.4997"
                    y2="15.1821"
                    stroke="black"
                    stroke-width="5"
                    stroke-linecap="round"
                />
                <line
                    x1="2.64258"
                    y1="27.8638"
                    x2="37.5"
                    y2="27.8638"
                    stroke="black"
                    stroke-width="5"
                    stroke-linecap="round"
                />
            </svg>
        </div>
    </div>
    <div class="mobilescreen" bind:this={state1}>
        <div class="text-content">
            <div>
                <p>Our</p>
                <h1>TECHNOLOGY</h1>
                <p>turns text to</p>
            </div>
        </div>
        <div class="alphabetanimation" />
        <div
            class="imgwrraper"
            on:click={() => {
                state1.style.opacity = '0%';
                state2.style.top = '0vw';
                state2.style.opacity = '100%';
                state1.style.zIndex = '0';
                state2.style.zIndex = '1';
            }} role="presentation"
        >
            <img src="/images/men.png" alt="img" width="100%" height="100%" />
        </div>
        <div class="text">
            <p>
                Let’s build the future of decentralized entertainment together.
            </p>
        </div>
        <div class="demobutton">
            <div class="playwrraper">
                <img
                    src="/images/playButton.svg"
                    alt="play"
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="watchButton">
                <p>Watch Demo</p>
            </div>
        </div>
    </div>
</div>
<div
    class="mobilescreen2"
    bind:this={state2}
    on:click={() => {
        state2.style.opacity = '0%';
        state2.style.top = '29vw';
        state1.style.opacity = '100%';
        state2.style.zIndex = '0';
        state1.style.zIndex = '1';
    }}
>
    <div class="textSecondIterration">
        <h6>We are a self sustaining decentralized global community of ....</h6>
    </div>
    <div class="textButton">
        <div class="menulist">
            {#each menulist as data}
                <h1>
                    {data.Menu}
                </h1>
            {/each}
        </div>
        <div class="joinbutton">
            <Mobilebtn2 />
        </div>
    </div>
</div>

<div class="swipe">
    <p>Swipe up to continue.</p>
</div>

<style>
    .mainDiv {
        position: relative;
        font-family: "Orbitron";
        height: 216.355vw;
        width: 100vw;
        box-sizing: border-box;
    }
    .navbar {
        display: flex;
        align-items: center;
        padding: 10vw 0 5vw;
    }
    .logosideWrraper {
        width: 80%;
    }
    .logoWrraper {
        width: 27.57vw;
        height: 8.645vw;
        overflow: hidden;
        margin-left: 35vw;
    }
    .baricon {
        width: 20%;
        text-align: center;
    }
    .mobilescreen {
        bottom: 0;
        width: 100vw;
        padding: 10vw;
        height: 187.15vw;
        position: absolute;
        box-sizing: border-box;
        border-radius: 14.019vw 14.019vw 0 0;
        background-color: rgb(0, 0, 0, 1);
        opacity: 100%;
        transition-duration: 1s;
        z-index: 1;
    }
    .text-content {
        font-family: "Orbitron";
        width: 84.813vw;
        margin: auto;
        color: #ffffff;
    }
    .text-content,
    p,
    h1 {
        margin: 0 auto;
        text-transform: uppercase;
    }
    .text-content p {
        font-weight: 900;
        font-size: 3.738vw;
        line-height: 4.673vw;
    }
    .text-content p:nth-child(1) {
        margin-left: 12vw;
    }
    .text-content p:nth-child(3) {
        margin-left: 40.5vw;
    }
    .text-content h1 {
        color: black;
        font-weight: 900;
        text-align: center;
        font-size: 7.009vw;
        line-height: 8.879vw;
        letter-spacing: 0.06em;
        -webkit-text-stroke: 0.234vw white;
    }
    .alphabetanimation {
        height: 8.645vw;
        width: 100%;
    }
    .imgwrraper {
        width: 80.374vw;
        height: 73.131vw;
        margin: auto;
    }
    .text {
        color: white;
        width: 84.813vw;
        text-align: center;
        margin: auto;
        margin-top: 12vw;
    }
    .text p {
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 400;
        font-size: 2.804vw;
        letter-spacing: 0.06em;
        color: #ffffff;
        text-transform: none;
    }
    .demobutton {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8vw;
        position: relative;
    }
    .playwrraper {
        width: 7.009vw;
        height: 7.009vw;
    }
    .watchButton p {
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 500;
        font-size: 2.336vw;
        line-height: 140%;
        letter-spacing: 0.06em;
        color: #ffffff;
        padding-left: 5vw;
    }
    .watchButton p::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 14.5vw;
        border-bottom: 0.5vw solid white;
        left: 36vw;
    }
    .mobilescreen2 {
        padding: 5vw;
        width: 100vw;
        height: 187.15vw;
        top: 29vw;
        overflow: hidden;
        position: absolute;
        box-sizing: border-box;
        font-family: "Orbitron";
        background-color: #000000;
        border-radius: 0 0 14.019vw 14.019vw;
        opacity: 0%;
        transition-duration: 1s;
        z-index: 0;
    }
    .swipe p {
        font-family: "Orbitron";
        font-weight: 400;
        font-size: 2.336vw;
        line-height: 3.037vw;
        letter-spacing: 0.06em;
        color: #000000;
        position: absolute;
        bottom: 10vw;
        left: 32vw;
    }
    .textSecondIterration {
        position: absolute;
        top: 28vw;
        left: 15vw;
        z-index: 1;
        /* opacity: 0%; */
        width: 70vw;
    }
    .textSecondIterration h6 {
        font-weight: 400;
        font-size: 4.673vw;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.06em;
        color: #ffffff;
    }
    .textButton {
        position: absolute;
        left: 5vw;
        bottom: 15vw;
    }
    .menulist h1 {
        font-weight: 900;
        font-size: 5.607vw;
        line-height: 140%;
        font-style: normal;
        text-align: center;
        letter-spacing: 0.06em;
        background: linear-gradient(90deg, #9796f0 0%, #fbc7d4 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    .joinbutton {
        width: fit-content;
        margin: 15vw auto;
    }
</style>
