<script>
    import LandingPageFirstSection from './landingPageFirstSection.svelte';
    import LandingPageGamer from './landingPageGamer.svelte';
    import LandingPageHowSection from './landingpage_HowSection.svelte';
    import NavBar from '../navBar.svelte';
    import LandingPageStoryTeller from './landingPageStoryTeller.svelte';
</script>

<div>
    <NavBar />
</div>
<div class="mainDiv">
    <div>
        <LandingPageFirstSection />
    </div>
    <div class="section2">
        <LandingPageGamer />
    </div>
    <div style="margin-top: 11.449vw;">
        <LandingPageStoryTeller />
    </div>
    <div>
        <LandingPageHowSection />
    </div>
</div>

<style>
    .mainDiv {
        overflow: hidden;
        font-family: "Orbitron";
    }
    .section2 {
        padding: 20vw 0;
        background-color: #f4eeec;
    }
</style>
