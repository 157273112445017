<script>
    import { logout } from 'rpcjs/src/utils';
import Popup from './popup.svelte';
    let first;
    let second;
    let third;
    let join1;
    let join2;
    let secondtext;
    let thirdtext;
    let thirddis;
    let seconddis;
    let edit = false;
    let state;
</script>

<div class="main-div">
    <div class="first" bind:this={first} on:click={()=>{
        second.style.opacity ='1';
        second.style.zIndex ='5';
        third.style.zIndex ='5';
        third.style.opacity = '1';
        third.style.left = '9.219vw';
        first.style.opacity ='0';
    }}>
        <p style="padding-top: 0.5vw;">
            Join Governance Council
        </p>
    </div>
    <div class="second" bind:this={second} on:mouseenter={()=>{
        second.style.backgroundColor = 'white';
        second.style.color = 'black';
        join1.style.fontWeight=900;
        secondtext.style.color='white';
        secondtext.style.top='3vw';
        secondtext.style.opacity = '1';
        seconddis.style.marginTop = '0.5vw';
    }}
    on:mouseleave={()=>{
        second.style.backgroundColor = 'transparent';
        second.style.color = 'white';
        join1.style.fontWeight=600;
        secondtext.style.color='black';
        secondtext.style.top='2vw';
        secondtext.style.opacity = '0';
        seconddis.style.marginTop = '-2vw';
    }}
      on:click={() => {
        edit = true;
        state = 'Join Governance Council (Youth)';
    }}>
        <p style="padding-top: 0.5vw;"> Youth</p>
        <div class="joinas" bind:this={join1}>
            <p> Join as</p>
        </div>


        <div class="secondtext" bind:this={secondtext}  
      >
            <p class="secondhead"> <span class="span">1000</span><br>
                seats remaining</p>
                <p class="seconddiscription" bind:this={seconddis}>
                    You are actively enrolled in school or college and/or under 21
                </p>
        </div>


    </div>
    <div class="third" bind:this={third} on:mouseenter={()=>{
        third.style.backgroundColor = 'white';
        third.style.color = 'black';
        join2.style.fontWeight=900;
        thirdtext.style.color='white';
        thirdtext.style.top='3vw';
        thirdtext.style.opacity = '1';
        thirddis.style.marginTop = '0.5vw';
    }}
    on:mouseleave={()=>{
        third.style.backgroundColor = 'transparent';
        third.style.color = 'white';
        join2.style.fontWeight=600;
        thirdtext.style.color='black';
        thirdtext.style.top='2vw';
        thirdtext.style.opacity = '0';
        thirddis.style.marginTop = '-2vw';
    }}
     on:click={() => {
        edit = true;
        state = 'Join Governance Council (Professionals)';
    }}>
        <p style="padding-top: 0.5vw;"> Professionals</p>
        <div class="joinas" bind:this={join2}>
            <p> Join as</p>
        </div>

        <div class="secondtext" bind:this={thirdtext}>
            <p class="secondhead"> <span class="span">900</span><br>
                seats remaining</p>
                <p class="seconddiscription" bind:this={thirddis}>
                    You are a creative or technical professional or an investor
                </p>
        </div>
    </div>
</div>

{#if edit}
    <Popup
    JoinAs={state};
        on:click={() => {
            edit = false;
        }}
    />
{/if}

<style>
    p{ 
        margin: 0; 
    }
.main-div{
    position: relative;
    cursor: pointer;
}
.first{
        position: absolute;
        width: 13.542vw;
        height: 2.083vw;
        border: 0.052vw solid white;
        background-color: transparent;
        font-size: 0.781vw;
        font-weight: 700;
        color: white;
        text-align: center;
        z-index: 1;
        font-family: "Orbitron";
        opacity: 1;
        transition-duration: 0.8s;
}
.first:hover{
    border: 0.052vw solid black;
    color: black;
    background-color: white;
}
.second{
    position: absolute;
    width: 7vw;
    height: 2.083vw;
    font-size: 0.781vw;
    font-weight: 700;
    color: white;
    text-align: center;
    border: 0.052vw solid white;
    font-family: "Orbitron";
    opacity: 0;
    transition-duration: 0.8s;
    animation: move 2.5s linear;

}
.third{
    position: absolute;
    width: 7vw;
    height: 2.083vw;
    font-size: 0.781vw;
    font-weight: 700;
    color: white;
    text-align: center;
    border: 0.052vw solid white;
    font-family: "Orbitron";
    opacity: 0;
    transition-duration: 2.5s;
    left: 8vw;
}
.joinas{
    width: 3.125vw;
    height: 0.677vw;
    background-color: black;
    font-size: 0.521vw;
    font-weight: 600;
    font-family: "Orbitron";
    position: absolute;
    color: white;
    top: -0.3vw;
    left: 0;
}
.secondtext{
    position: absolute;
    text-align: left;
    color: black;
    top: 2vw;
    opacity: 0;
    transition-duration: 0.8s;
}
.span{
    color:#DA22FF;
    font-size: 0.833vw;
    font-family: "Orbitron";
    font-weight: 700;
}
.secondhead{
    font-size: 0.417vw;
    font-family: "Orbitron";
}
.seconddiscription{
    width: 10.042vw;
    font-size: 0.521vw;
    font-family: "Orbitron";
    line-height: 0.729vw;
    transition-duration: 0.75s;
}
@keyframes move{
    0%{
        width: 10vw;
    }
    100%{
        width: 7vw;
    }
}
</style>