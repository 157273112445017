<script>
    export let text1 = 'Join Games Governance Council';
    export let text2 = '(Casual Seat)';
    export let text3 = '100';
    export let TextColor2 = 'black';
    export let TextColor = 'black';
    export let TextColorAfter = 'white';
    export let TextSize2 = '0.521vw';
    export let TextSize = '0.625vw';
    export let width = '16.406vw';
    export let BackColor = 'transparrent';
    export let AnimationColor = '#615BFF';
    export let height = '2.865vw';
    export let Maindiv;
    export let BorderColorBefore = '0.0052vw solid #000000';
    export let BorderColorAfter = '0.0052vw solid aqua';
    export let Text2Size = '0.625vw';
    import Popup from './popup.svelte';
    export let state = 'temp';
    let spam;
    let BoxTextColor;
    let SecondDIv;
    let edit = false;
    let ThirdDiv;
</script>

<div style="width:{width};"
on:click={() => {
    edit = true;
}}
>
    <div
        bind:this={Maindiv}
        class="main-div"
        style="width:{width}; background-color:{BackColor}; height:{height} ; border: {BorderColorBefore}"
        on:mouseenter={() => {
            SecondDIv.style.width = width;
            SecondDIv.style.opacity = '1';
            ThirdDiv.style.opacity = '1';
            Maindiv.style.border = BorderColorAfter;
            BoxTextColor.style.color = TextColorAfter;
            spam.style.fontSize=Text2Size;
            spam.style.fontWeight='700';
        }}
        on:mouseleave={() => {
            SecondDIv.style.width = '0vw';
            SecondDIv.style.opacity = '0';
            ThirdDiv.style.opacity = '0';
            Maindiv.style.border = BorderColorBefore;
            BoxTextColor.style.color = TextColor;
            spam.style.fontSize='0.521vw';
            spam.style.fontWeight='400';
        }}
    >
        <p
        bind:this={BoxTextColor}
        class="text1"
        style="position:absolute; color:{TextColor}; font-size:{TextSize};"
    >
        {text1} <br>
        <span bind:this={spam} style="font-size: 0.521vw;font-weight:400;transition-duration:1s;">
            {text2}
        </span>
    </p>
       
        <div
            bind:this={SecondDIv}
            class="second-div"
            style="width:0vw; background-color:{AnimationColor}; height:{height}"
        />
    </div>
        <div style="transition-duration:1s; opacity:0;" bind:this={ThirdDiv}>
            <p class="text2" style="color:{TextColor2}; font-size:{TextSize2};">
                <span style="font-size: 0.729vw;">{text3}</span> seats remaining
            </p>
        </div>
</div>

{#if edit}
<Popup
JoinAs = {state}
    on:click={() => {
        edit = false;
    }}
/>
{/if}

<style>
    p{margin: 0;}
    .main-div {
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        border: 0.0052vw solid #000000;
        transition-duration:1s ;
    }
    .second-div {
        margin: auto;
        opacity: 0;
        transition-duration: 1s;
    }
    .text1 {
        font-weight: 700;
        font-family: "Orbitron";
        font-weight: 700;
        font-family: "Orbitron";
        z-index: 1;
        text-align: center;
        top: 0.573vw;
        margin: auto;
        left: 0;
        right: 0;
        transition-duration:1s;
    }
    .text2 {
        font-weight: 600;
        font-family: "Orbitron";
        text-align: center;
        margin-top:0.3vw ;
    }
</style>
