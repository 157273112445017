<script>
    let image;
    import LandingPagenavbar from '../../reusableitems/landingPagenavbar.svelte';
    import DesktopButton from '../../reusableitems/DesktopButton.svelte';
    import Section3 from './section3.svelte';
    import Section2 from './section2.svelte';
    import Section4 from './section4.svelte';
    import Section5 from './section5.svelte';
    import Section6 from './section6.svelte';
    import OnPageLoad from '../../reusableitems/onPageLoad.svelte';
    import rpcjs from 'rpcjs';
    const {title} = rpcjs.props;
    title.set('Artists');
</script>
<section>
    
    <div class="navbar">
        
        <LandingPagenavbar bgClrAfter='black'/>
    </div>
    <div>
        <OnPageLoad background='conic-gradient( white 0deg 90deg, #59E7F2 90deg 270deg, white 270deg 360deg)' />
    </div>
    <div style="display: flex;">
        <div class="maindiv">
            <div class="firstdiv">
                <img src="/images/Artist/artist.png" alt="" width="100%" height="100%"> 
            </div>
            <div class="secnddiv">
                <div class="onediv">
                    <p>1</p>
                </div>
                <div class="virtualhead">
                    <p>Visual<br>ARTISTS</p>
                </div>
                <div class="scroll_div">
                    <p class="scrollText" >scroll / drag</p>
                    <svg
                        width="6.185vh"
                        height="0.741vh"
                        viewBox="0 0 72 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="img"
                    >
                        <path
                            d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
                        />
                    </svg>
                </div>
                <div class="btn">
                    <DesktopButton
                    state = "Artists & Musicians"
                    text = 'Join Visual Art Governance Council'
                    TextColor='black'
                    BackColor= 'white'
                    borderClr= '0.1vw solid black'
                    AnimationColor= 'aqua'
                    TextColor2='black' 
                    TextColorAFter='black'
                    BorderClrAfter='0.1vw solid aqua'/>
                </div>
            </div>
            <div class="descriptiontext" >
                    <p>Colors, shapes and layout is the name of the game and you are the player. You see it in your head before it materializes and you know what goes with what. </p>
    
                       <p style="font-weight: 700;padding: 1.85vh 0;">We’re building the future of entertainment. A future that expands your 2D canvas infinitely into a multidimensional multiverse. And for the first time, the canvas can almost keep up with your imagination.</p>  
                        <p>
                            We want you to be the first to experience it and to help us choose the right color scheme and layout for it.
                        </p>
                </div>
            <div class="thirddiv">
            </div>
            <div class="welcomeImg" on:mouseenter={()=>{
                image.style.filter='none';
                image.style.width = 'max(118.426vh , 639px)';
                image.style.height = 'max(72.963vh , 394px)';
                image.style.top='max(0vh , 0px)';
                image.style.left='max(0vh , 0px)';
            }}
            on:mouseleave={() =>{
               image.style.filter='grayscale(100%)';
                image.style.width = 'max(56.296vh , 304px)';
                image.style.height = 'max(42.130vh , 227.5px)';
                image.style.top='max(12vh , 66.5px)';
                image.style.left='max(15.15vh , 95px)';
            }}>
                <img src="/images/Artist/welcome.svg"  alt="" width="100%" height="100%" >
                <div class="afterimg"  bind:this={image}>
                    <img src="/images/Artist/afterclick.png" alt="" width="100%" height="100%" >
                </div>
            </div>
        </div>
        <div>
            <Section2 />
        </div>
        <div>
            <Section3 />
        </div>
        <div>
            <Section4 />
        </div>
        <div>
            <Section5 />
        </div>
        <div>
            <Section6 />
        </div>
    </div>
   
</section>
<style>
      .navbar{
        position: fixed;
        width: 100%;
        z-index: 2;
    }
    .maindiv{
        display: flex;
        position: relative;
        font-family: 'Orbitron';
    }
    .firstdiv{
        width: max(96.111vh , 519px);
        height: max(100vh , 540px);
    }
    .firstdiv::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    p{
        margin: 0;
    }
    .secnddiv{
        width: max(81.944vh , 442.5px);
        height: 100vh;
        position: relative;
    }
    .onediv{
        font-size: max(55.556vh , 300px);
        color: rgba(244, 238, 236, 0.5);
        text-transform: uppercase;
        font-weight: 700;
        line-height: max(55.556vh , 300px);
        position: absolute;
        left: 0;
        top: max(2.315vh , 12.5px);
    }
    .thirddiv{
        width: max(97.222vh ,  525px);
        height: 100vh;
        position: relative;
        background-color: #F4EEEC;
    }
    .virtualhead{
        font-weight: 700;
        font-size: max(5.556vh , 30px);
        line-height: max(5.556vh , 30px);
        text-transform: uppercase;
        color: #000000;
        position: absolute;
        left: max(10.926vh , 59px);
        top: max(30.926vh , 167px);
    }
    .descriptiontext{
        position: absolute;
        top: max(48.407vh, 256px);
        width: max(57.685vh , 311.5px);
        padding: 2.87vh 2.315vh 0 2.315vh;
        background-color: white;
        border-radius: 4.63vh;
        border-top-left-radius: 0vh;
        border-bottom-right-radius: 0vh;
        left: 107vh;
        z-index: 1;
    }
    .descriptiontext p{
        font-size: max(1.852vh ,10px);
        font-weight: 400;
        line-height: max(2.593vh , 14px);
        color: #000000;
        letter-spacing: 0.12vh;
    }
    .welcomeImg{
        border: 1.5vh solid white;
        border-radius: 1.852vw;
        position: absolute;
        top: max(11.315vh , 66.5px);
        width: max(118.426vh , 639px);
        height: max(72.963vh , 394px);
        left: 136vh;
    }
    .welcomeImg::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    .afterimg{
        position: absolute;
        top: max(12vh , 66.5px);
        width: max(56.296vh , 304px);
        height: max(42.13vh , 227.5px);
        left: max(15.15vh , 95px);
        filter: grayscale(100%);
        transition-duration: 1s;
    }
    .afterimg::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    .scroll_div {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 2.5vh;
        right: 4vh;
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 500;
        line-height: max(1.857vh, 8px);
        font-family: "Orbitron";
        color: black;
        text-transform: capitalize;
    }
    .scroll_div .img {
        margin-left: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
        fill: black;
    }
    .btn {
        position: absolute;
        bottom: 4.2vh;
        left: 13.1vh;
    }
</style>