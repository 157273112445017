<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>

<section>
    <div class="main-div">
        <div class="images-div">
            <div class="mobile-div">
                <img
                    src="images/Perfomance/Vectarytexture3.png"
                    alt=""
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="back-div">
                <img
                    src="images/Perfomance/back3.svg"
                    alt=""
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="text-div">
                <p>Performers & Opus</p>
                <p>Voice Over Artists</p>
                <p>
                    No play is complete without dialogs. No character is
                    complete without a voice.
                </p>
                <p>
                    Our game changer technology enables you to turn your voice
                    into an everlasting intellectual property.
                </p>
                <p>
                    Whenever a director or producer wants to include it in their
                    end production, you get paid without having to step into a
                    studio and to memorize a single line.
                </p>
                <p>
                    We’re inviting you to become part of making history, vote on
                    important societal issues arising for it and of course, get
                    rewarded for your time.
                </p>
                <div class="btn-div">
                    <DesktopButton2
                        state = "Performer"
                        width="max(24.074vh, 130px)"
                        text1 = "Join Artist Governace Council"
                        text2="(Voice Over Seat)"
                        AnimationColor="black"
                        TextColorAfter="#67eebb"
                        BorderColorAfter="black"
                        text3="15"
                    />
                </div>
            </div>
        </div>
        <div class="scroll_div">
            
            <p class="scrollText" >scroll / drag</p>
            <svg
            width="6.185vh"
            height="0.741vh"
            viewBox="0 0 72 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="img"
        >
            <path
                d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
            />
        </svg>
        </div>
    </div>
</section>

<style>
    .main-div {
        width: max(161.944vh, 874.5px);
        height: max(100vh, 540px);
        position: relative;
    }
    .images-div {
        display: flex;
        position: relative;
    }
    .back-div {
        position: absolute;
        right: 0vh;
        width: max(111.944vh, 604.5px);
        height: max(100vh, 540px);
        pointer-events:none ;
        z-index: -1;
    }
    .mobile-div {
        left: -40vh;
        position: absolute;
        width: max(168.611vh, 910.5px);
        height: max(100vh, 540px);
        pointer-events:none ;
    }
    .text-div {
        position: absolute;
        right: max(14.63vh, 79px);
        width: max(64.537vh, 348.5px);
        font-family: "Orbitron";
        top: max(7.222vh, 39px);
    }
    .text-div p:nth-child(1) {
        font-size: max(1.296vh, 7px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 400;
        margin-left: max(3vh, 16.2px);
    }
    .text-div p:nth-child(2) {
        font-size: max(5.556vh, 30px);
        line-height: max(5.556vh, 30px);
        margin-top: max(2.407vh, 13px);
        margin-bottom: max(6.667vh, 36px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 700;
        width: max(43.889vh, 237px);
        margin-left: max(3vh, 16.2px);
    }
    .text-div p:nth-child(3) {
        margin-top: max(2.315vh, 12.5px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 400;
    }
    .text-div p:nth-child(4) {
        margin-top: max(2.315vh, 12.5px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 700;
    }
    .text-div p:nth-child(5) {
        margin-top: max(2.315vh, 12.5px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        font-weight: 400;
        letter-spacing: max(0.058vh, 0.5px);
    }
    .text-div p:nth-child(6) {
        margin-top: max(2.315vh, 12.5px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        font-weight: 400;
        letter-spacing: max(0.058vh, 0.5px);
    }
    .btn-div {
        margin-top: max(4.537vh, 24.5px);
    }
    .scroll_div {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 2.5vh;
        left: -11vh;
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 500;
        line-height: max(1.857vh, 8px);
        font-family: "Orbitron";
        color: black;
        text-transform: capitalize;
    }
    .scroll_div .img {
        margin-left: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
        fill: black;
}
</style>
