<script>
    import Popup from './popup.svelte';
    export let text = 'Join Games Governance Council';
    export let text2 = '100';
    export let TextColor2 = '#E36374';
    export let TextColor = 'white';
    export let TextColorAFter = 'white';
    export let TextSize2 = '0.521vw';
    export let TextSize = '0.729vw';
    export let width = '16.094vw';
    export let BackColor = '#615BFF';
    export let AnimationColor = 'black';
    export let height = '2.083vw';
    export let borderClr = '0.1vw solid transparent';
    export let BorderClrAfter = '0.1vw solid transparent';
    export let state = 'temp';
    let text1;
    let SecondDIv;
    let FirstDiv;
    let ThirdDiv;
    let edit = false;
</script>

<div
    style="width:{width};"
    on:click={() => {
        edit = true;
    }}
>
    <div
        bind:this={FirstDiv}
        class="main-div"
        style="width:{width}; background-color:{BackColor}; height:{height}; border:{borderClr};"
        on:mouseenter={() => {
            SecondDIv.style.width = width;
            SecondDIv.style.opacity = '1';
            ThirdDiv.style.opacity = '1';
            text1.style.color = TextColorAFter;
            FirstDiv.style.border = BorderClrAfter;
        }}
        on:mouseleave={() => {
            SecondDIv.style.width = '0vw';
            ThirdDiv.style.opacity = '0';
            text1.style.color = TextColor;
            FirstDiv.style.border = borderClr;
        }}
    >
        <p
            bind:this={text1}
            class="text1"
            style="position:absolute; color:{TextColor}; font-size:{TextSize};line-height:1vh;"
        >
            {text}
        </p>

        <div
            bind:this={SecondDIv}
            class="second-div"
            style="width:0vw; background-color:{AnimationColor}; height:{height}"
        />
    </div>
    <div
        style="transition-duration:1s; opacity:0; margin-top:0.3vw;"
        bind:this={ThirdDiv}
    >
        <p class="text2" style="color:{TextColor2}; font-size:{TextSize2};">
            <span style="font-size: 0.729vw;">{text2}</span> seats remaining
        </p>
    </div>
</div>

{#if edit}
    <Popup
    JoinAs = {state}
        on:click={() => {
            edit = false;
        }}
    />
{/if}

<style>
    p {
        margin: 0;
    }
    .main-div {
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition-duration: 0.5s;
    }
    .second-div {
        margin: auto;
        opacity: 1;
        transition-duration: 1s;
    }
    .text1 {
        font-weight: 700;
        font-family: "Orbitron";
        z-index: 0;
        text-align: center;
        top: 0.573vw;
        margin: auto;
        left: 0;
        right: 0;
        transition-duration: 0.8s;
    }
    .text2 {
        font-weight: 600;
        font-family: "Orbitron";
        text-align: center;
    }
</style>
