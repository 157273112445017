<script>
    let anim;
    export let background ='conic-gradient( white 0deg 90deg, #807CF9 90deg 270deg, white 270deg 360deg)';
    $: {
        setTimeout(() => {
            anim.style.top='-100vh';
        });
        setTimeout(() => {
            anim.style.right = '100vw';
            anim.style.transitionDuration = '0.5s';
        }, 1000);
}
</script>

<section>
    <div class="anim" bind:this={anim} style="background: {background};"></div>
</section>

<style>
    .anim{
    width: 100%;
    height: 200vh;
    z-index: 20;
    position: fixed;
    top: 0vh;
    transition-duration:0.4s;
    right: 0vh;

    }
</style>