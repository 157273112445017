<script>
    import Button from '../reusableitems/DesktopButton.svelte';
    import LandingPagenavbar from '../reusableitems/landingPagenavbar.svelte';
    import OnPageLoad from '../reusableitems/onPageLoad.svelte';
    import rpcjs from 'rpcjs';
    const {title} = rpcjs.props;
    title.set('Advertisers');
    let flag = false;
    let image;
    let circle;
    const box = [];
</script>

<div class="navbar">
    <LandingPagenavbar textClrAfter='white' textclr='black' bgClrAfter='black'/>
</div>
<div>
    <OnPageLoad background='conic-gradient( white 0deg 90deg, #66FF00 90deg 270deg, white 270deg 360deg)'/>
</div>
<div class="main-div">
    <div
        class="left"
        on:click={() => {
            if (flag === true) {
                image.style.transform = 'scale(1)';
                flag = false;
                circle.style.opacity = '1';
                box[0].style.opacity = '0';
                box[1].style.opacity = '0';
                box[2].style.opacity = '0';
                box[3].style.opacity = '0';
                box[4].style.opacity = '0';
            }
        }}
    >
        <div class="text1">
            <p>A new paradigm for advertising</p>
        </div>

        <hr class="hrcss" />
        <h4 class="heading">
            Imagine dynamic placement of 3D+ ads never seen before.
        </h4>
        <p class="text2">
            We’re all familiar with the automated bidding process that the
            advent of digital media and more specifically, internet advertising
            brought. Like it or hate it, it significantly broadened the
            advertisement industry for all participants.
            <br />
            <br />
            <br />
            <b>
                We’re taking it to the next level by dynamically placing
                accessories, props and apparel of beloved characters. Coupling
                it with anonymous privacy respecting personalized product
                placement and the ad world will never be the same again.
            </b><br /><br />
            <br />
            That future is being build and you can be part of it by joining our advertiser
            governing council which grants you access to alpha testing, feature suggestions
            and one-to-one conversation with our team.
        </p>
        <div class="btn">
            <Button
            state = "Advertiser"
            text = 'Join Ad Governing Council'
            TextColor2 = 'black'
            TextColor = 'black'
            TextSize = 0.625vw
            BackColor = 'transparrent'
            borderClr = '0.1vw solid black'
            TextColorAFter = '#54F862'
            />
        </div>
    </div>

    <div class="right">
        <div class="img-class" bind:this={image}>
            <img
                src="images/consumerPage/advert.png"
                alt=""
                width="100%"
                height="100%"
                on:click={() => {
                    if (flag === true) {
                        image.style.transform = 'scale(1)';
                        flag = false;
                        circle.style.opacity = '1';
                        box[0].style.opacity = '0';
                        box[1].style.opacity = '0';
                        box[2].style.opacity = '0';
                        box[3].style.opacity = '0';
                        box[4].style.opacity = '0';
                    }
                }}
            />
        </div>
        <div bind:this={circle} style="transition-duration: 2s;" >
        <div
            class="circle"
            on:click={() => {
                if (flag === false) {
                    image.style.transform =
                        'scale(2.8) translate(12vw, 14.5vw)';
                    flag = true;
                    circle.style.opacity = '0';
                    box[0].style.opacity = '1';
                }
            }}
        />

        <div
            class="circle"
            style="top: max(28.704vh, 155px);left: max(54.444vh, 294px);"
            on:click={() => {
                if (flag === false) {
                    image.style.transform = 'scale(2.5) translate(-7vw, 9vw)';
                    flag = true;
                    circle.style.opacity = '0';
                    box[1].style.opacity = '1';
                }
            }}
        />

        <div
            class="circle"
            style="top: max(50.833vh, 274.5px);left: max(58.333vh, 315px);"
            on:click={() => {
                if (flag === false) {
                    image.style.transform = 'scale(2.5) translate(-8vw, 1.5vw)';
                    flag = true;
                    circle.style.opacity = '0';
                    box[2].style.opacity = '1';
                }
            }}
        />

        <div
            class="circle"
            style=" top: max(62.407vh, 337px);left: max(28.056vh, 151.5px);"
            on:click={() => {
                if (flag === false) {
                    image.style.transform = 'scale(2) translate(5.5vw, -8.5vw)';
                    flag = true;
                    circle.style.opacity = '0';
                    box[3].style.opacity = '1';
                }
            }}
        />

        <div
            class="circle"
            style="top: max(78.889vh, 426px);left: max(61.759vh, 333.5px);"
            on:click={() => {
                if (flag === false) {
                    image.style.transform ='scale(2) translate(-9.9vw, -11vw)';
                    flag = true;
                    circle.style.opacity = '0';
                    box[4].style.opacity = '1';
                }
            }}
        />
        </div>
        <div class="box1" bind:this={box[0]}>
            <div class="star">
                <img src="/images/star.svg" alt="" width="100%" height="100%" />
            </div>
            <div>
                <p
                    style="font-size: max(1.852vh,10px);
                padding-left: 0.833vh;
                font-weight: 700;
                padding-top: max(2.778vh, 15px);"
                >
                    Advertise
                </p>

                <p
                    style="font-size: max(1.111vh, 6px);
                padding-left: 0.8vh;
                paddig-top: max(0.741vh, 4px)"
                >
                    your product / brand
                </p>
            </div>
        </div>

        <div
            class="box1"
            style=" top: max(50vh,25px);left: max(41.296vh, 223px);"
            bind:this={box[1]}
        >
            <div class="star">
                <img src="/images/star.svg" alt="" width="100%" height="100%" />
            </div>
            <div>
                <p
                    style="font-size: max(1.852vh,10px);
                padding-left: 0.833vh;
                font-weight: 700;
                padding-top: max(2.778vh, 15px);"
                >
                    Advertise
                </p>

                <p
                    style="font-size: max(1.111vh, 6px);
                padding-left: 0.8vh;
                paddig-top: max(0.741vh, 4px)"
                >
                    your product / brand
                </p>
            </div>
        </div>

        <div
            class="box1"
            style="top: max(64.537vh, 348.5px);left: max(42.130vh, 227.5px);"
            bind:this={box[2]}
        >
            <div class="star">
                <img src="/images/star.svg" alt="" width="100%" height="100%" />
            </div>
            <div>
                <p
                    style="font-size: max(1.852vh,10px);
                padding-left: 0.833vh;
                font-weight: 700;
                padding-top: max(2.778vh, 15px);"
                >
                    Advertise
                </p>

                <p
                    style="font-size: max(1.111vh, 6px);
                padding-left: 0.8vh;
                paddig-top: max(0.741vh, 4px)"
                >
                    your product / brand
                </p>
            </div>
        </div>

        <div
            class="box1"
            style="top: max(45.463vh, 245.5px);left: max(41.296vh, 223px);"
            bind:this={box[3]}
        >
            <div class="star">
                <img src="/images/star.svg" alt="" width="100%" height="100%" />
            </div>
            <div>
                <p
                    style="font-size: max(1.852vh,10px);
                padding-left: 0.833vh;
                font-weight: 700;
                padding-top: max(2.778vh, 15px);"
                >
                    Advertise
                </p>

                <p
                    style="font-size: max(1.111vh, 6px);
                padding-left: 0.8vh;
                paddig-top: max(0.741vh, 4px)"
                >
                    your product / brand
                </p>
            </div>
        </div>

        <div
            class="box1"
            style="top: max(58.519vh, 316px);left: max(44.259vh, 239px);"
            bind:this={box[4]}
        >
            <div class="star">
                <img src="/images/star.svg" alt="" width="100%" height="100%" />
            </div>
            <div>
                <p
                    style="font-size: max(1.852vh,10px);
                padding-left: 0.833vh;
                font-weight: 700;
                padding-top: max(2.778vh, 15px);"
                >
                    Advertise
                </p>

                <p
                    style="font-size: max(1.111vh, 6px);
                padding-left: 0.8vh;
                paddig-top: max(0.741vh, 4px)"
                >
                    your product / brand
                </p>
            </div>
        </div>
    </div>
</div>

<style>
      .navbar{
        position: fixed;
        width: 100%;
        z-index: 2;
    }
    p {
        margin: 0;
    }
    .main-div {
        font-family: "Orbitron";
        display: flex;
        background-color: #f4eeec;
        width: max(177.778vh, 960px);
        height: 100vh;
    }
    .left {
        background-color: #d0f9b4;
        width: max(75.278vh, 406.5px);
        margin-left: max(11.389vh, 61.5px);
    }
    .text1 {
        font-size: max(1.852vh, 10px);
        font-weight: 400;
        position: absolute;
        top: max(13.796vh, 74.5px);
        left: max(23.611vh, 127.5px);
        width: max(50.648vh, 273.5px);
    }
    .hrcss {
        width: max(43.333vh, 234px);
        border: 0.093vh solid #000000;
        position: absolute;
        top: max(20vh, 94.5px);
        left: max(23.611vh, 127.5px);
    }

    .heading {
        font-size: max(4.63vh, 25px);
        width: max(52.87vh, 285.5px);
        top: max(19.352vh, 104.5px);
        line-height: max(4.63vh, 25px);
        left: max(23.611vh, 127.5px);
        position: absolute;
        letter-spacing: 0.06em;
    }

    .text2 {
        width: max(48.704vh, 263px);
        font-size: max(1.296vh, 7px);
        line-height: max(1.815vh, 9.8px);
        font-weight: 400;
        position: absolute;
        top: max(46.296vh, 250px);
        left: max(23.611vh, 127.5px);
    }
    .btn {
        position: absolute;
        top: max(83.426vh, 450.5px);
        left: max(23.611vh, 127.5px);
    }

    .right {
        width: max(79.907vh, 431.5px);
        position: relative;
        overflow: hidden;
    }
    .img-class {
        width: max(82.037vh, 443px);
        height: 100vh;
        transition-duration: 2s;
    }
    .circle {
        position: absolute;
        background: #ff007f;
        box-shadow: 0px 0px 2.778vh #ff007f;
        width: max(2.778vh, 15px);
        height: 2.778vh;
        top: max(10.3vh, 48px);
        border-radius: 50%;
        left: max(14.63vh, 79px);
        cursor: pointer;
        opacity: 1;
    }

    .box1 {
        width: max(17.963vh, 97px);
        height: max(9.074vh, 49px);
        background-color: white;
        border-radius: 1.111vh;
        display: flex;
        position: absolute;
        top: max(21.019vh, 113.5px);
        left: max(30.926vh, 162px);
        transition-duration: 2s;
        opacity: 0;
    }
    .star {
        width: max(1.481vh, 8px);
        height: max(1.481vh, 8px);
        padding-left: max(1.481vh, 8px);
        padding-top: max(2.963vh, 16px);
    }
</style>
