<script>
    import LandingPagenavbar from '../../reusableitems/landingPagenavbar.svelte';
    import Feature from './sections/feature.svelte';
    import Section3 from './sections/section3.svelte';
    import Story from './sections/story.svelte';
    import Section2 from './sections/section2.svelte';
    import rpcjs from 'rpcjs';
    import StartingAnimation from '../startingAnimationDesktop.svelte';
    const {title} = rpcjs.props;
    title.set('Home');
</script>
<div class="desktop">
    <!-- <Create /> -->
    <div class="navbar">
        
        <LandingPagenavbar textClrAfter='white' textclr='black' borderclr='black' bgClrAfter='black' />
    </div>
    <StartingAnimation />
    <section>
        <div><Story /></div>
        <div><Section2 /></div>
        <div><Section3 /></div>
        <div><Feature /></div>
    </section>
</div>

<style>
    section{
        display: flex;
    }
    .navbar{
        position: fixed;
        width: 100%;
        z-index: 10;
    }
    
   
</style>
