<script>
    import DesktopButton from '../../reusableitems/DesktopButton.svelte';
</script>

<div class="main-div">
    <div class="image">
        <img src="/images/createMainDesktop/unsplash.png" width="100%" height="100%"
        alt="">
    </div>
   
    <div>
        <p class="heading1">UNLEASH</p>
    </div>
    
    <div class="heading2">
        <p >YOUR</p>
    </div>
    
    <div class="heading3">
        <p >IMAGINATION</p>
    </div>
    
    <div class="heading4">
        <p >
            & let our <b> TECHNOLOGY </b> do the work.
        </p>
    </div>
    <div class="bottomText">
        <p>
            We’re building the future where storytellers no longer are a passive
            wordslinger, rather an active orchestrator interacting with a live
            3D canvas which turns your words into a living breathing world in
            the blink of an eye.
            <br /><br />
            Write scenes, characters and their dialogs and watch it happen in real
            time.
        </p>
        <div style="margin-top:2vw;">
            <DesktopButton
            state = "StoryTeller"
                text="Join Storytellers Governace Council"
                TextColor2="white"
                AnimationColor="#F4DCFF"
                TextColor="#F4DCFF"
                borderClr="0.1vw solid #F4DCFF"
                BorderClrAfter="0.1vw #F4DCFF"
                BackColor="transparent"
                TextColorAFter="black"
            />
        </div>
        <div class="scroll">
            <div>
                <p>Scroll / Drag</p>
            </div>
            <div class="imageWrraper">
                <img
                    src="/images/create-section/Line14.png"
                    alt="arrow"
                    width="100%"
                    height="100%"
                />
            </div>
        </div>
    </div>
</div>

<style>
    p {
        margin: 0;
        font-family: "Orbitron";
    }
    .main-div {
        position: relative;
        height: 100vh;
        width: 100vw;  
    }
    .image{
        height: 100vh;
        width: 100vw;  
    }
    .image::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    .heading1 {
        position: absolute;
        left: max(11.759vh, 63.5px);
        top: max(21.296vh, 115px);
        font-weight: 900;
        font-size: max(5.556vh, 30px);
        color: #ffffff;
    }
    .heading2 {
        position: absolute;
        left: max(27.13vh, 146.5px);
        top:max(27.130vh, 146.5px);
        font-weight: 900;
        font-size: max(5.556vh, 30px);
        color: #ffffff;
    }
    .heading3 {
        position: absolute;
        left: max(11.759vh, 63.5px);
        top: max(33.481vh, 170px);
        font-weight: 900;
        font-size: max(13.889vh, 75px);
        line-height: max(13.889vh, 75px);
        color: #f4dcff;
    }
    .heading4 {
        position: absolute;
    left: max(51.833vh, 247.5px);
    top: max(47.833vh, 247.5px);
    font-weight: 400;
    font-size: max(3.704vh, 20px);
    line-height:  max(3.704vh, 20px);
    color: #ffffff;
    float: right;
    }
    .bottomText {
        width: max(59.531vw, 571.5px);
        height: max(19.896vw, 191px);
        background: #000000;
        opacity: 0.8;
        border-radius: max(5.208vw, 50px) 0vw 0vw 0vw;
        position: absolute;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        padding: 3vw;
    }
    .bottomText p {
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 500;
        font-size: max(1.042vw, 10px);
        line-height: 140%;
        letter-spacing: 0.06em;
        color: #ffffff;
    }
    .scroll {
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: right;
        width: 12vw;
    }
    .scroll p {
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 700;
        font-size: max(0.938vw, 9px);
    }
    .scroll .imageWrraper {
        width: max(3.698vw, 35.5px);
        height: fit-content;
    }
    /* .createMain {
        width: 100%;
        height: 100%;
        position: absolute;
        background-size: cover;
        background-position: bottom;
        background-image: url("/images/createMainDesktop/unsplash.png");
    }
    .headText {
        width: max(65.052vw, 624.5px);
        font-family: "Orbitron";
        position: absolute;
        top: max(21.296vh, 115px);
        left: 7vw;
    }
    .headText h2 {
        width: max(65.052vw, 190px);
        font-weight: 900;
        font-size: max(3.125vw, 30px);
        letter-spacing: 0.1em;
        color: #ffffff;
        margin: 0;
        margin-top: -1vw;
    }
    .headText h2:nth-child(2) {
        margin-left: 8.6vw;
    }
    .headText h1 {
        font-weight: 900;
        font-size: max(7.813vw, 75px);
        line-height: 100%;
        letter-spacing: 0.1em;
        color: #f4dcff;
        margin: 0;
    }
    .headText h3 {
        font-weight: 400;
        font-size: max(2.083vw, 20px);
        line-height: 140%;
        letter-spacing: 0.06em;
        color: #ffffff;
        float: right;
        margin: 0;
    }
    .bottomText {
        width: max(59.531vw, 571.5px);
        height: max(19.896vw, 191px);
        background: #000000;
        opacity: 0.8;
        border-radius: max(5.208vw, 50px) 0vw 0vw 0vw;
        position: absolute;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        padding: 3vw;
    }
    .bottomText p {
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 500;
        font-size: max(1.042vw, 10px);
        line-height: 140%;
        letter-spacing: 0.06em;
        color: #ffffff;
    }
    .scroll {
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: right;
        width: 12vw;
    }
    .scroll p {
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 700;
        font-size: max(0.938vw, 9px);
    }
    .scroll .imageWrraper {
        width: max(3.698vw, 35.5px);
        height: fit-content;
    } */
</style>
