<script>
    import MobileButton from '../reusableitems/mobileButton.svelte';
    import NavBar from './navBar.svelte';
    import {fly} from 'svelte/transition';
    const gifData = [
        {
            gif: 'images/consumerPage/gif1.gif',
        },
        {
            gif: 'images/consumerPage/gif2.gif',
        },
        {
            gif: 'images/consumerPage/gif3.gif',
        },
        {
            gif: 'images/consumerPage/gif4.gif',
        },
        {
            gif: 'images/consumerPage/gif5.gif',
        },
        {
            gif: 'images/consumerPage/gif6.gif',
        },
        {
            gif: 'images/consumerPage/gif7.gif',
        },
    ];
    let clr = false;
    let flag = false;
</script>
<div>
    <NavBar Color="black" />
</div>
{#if clr === false}
{ 
setTimeout(() => {
    clr = true;
    flag = true;
}, 2000)}

<div class="clrdiv" in:fly="{{y: -500, duration: 500}}"  >
</div>
<div class="white" in:fly="{{x: 500, delay: 500, duration: 500}}"  >
</div>
{/if}

{#if flag === true}
<div class="maindiv">
    <div class="backImg">
        <div class="consumer">
            <h4>CONSUMER</h4>
        </div>
    </div>
    <div class="textDiv">
        <p class="text1">
            You <br /> create <br /> the story,<br /> we have built <br />
            the <b>home</b> for it !
        </p>
        <div class="arrowImg">
            <img
                src="images/consumerPage/arrow.svg"
                alt=""
                width="100%"
                height="100%"
            />
        </div>
        <p class="pretext">An arena for your content, fans, and community.</p>
        <div class="btn">
            <MobileButton title="Start Watching" textClr="black" />
        </div>
    </div>
    <div class="gif">
        {#each gifData as data}
            <div class="gifImg">
                <img src={data.gif} alt="" width="100%" height="100%" />
            </div>
        {/each}
    </div>
    <div class="sec3">
        <div class="gif2">
            <img
                src="images/consumerPage/gif8.gif"
                alt=""
                width="100%"
                height="100%"
            />
        </div>
        <div class="description" style="">
            <p>
                Dedicated platform for reaching your targeted audience directly
                & amplify their content and build an engaged audience through
                their own video platform.
            </p>
        </div>
    </div>
    <div class="sec4">
        <div class="description2">
            <p>Control over your content, brand and audience experience.</p>
        </div>
        <div class="image">
            <img
                src="images/consumerPage/experience.png"
                alt=""
                width="100%"
                height="100%"
            />
        </div>
    </div>
</div>
{/if}
<style>
    .clrdiv{
        position: absolute;
        background-color: #59E7F2;
        width: 100%;
        height: 100%;
        top: 0vw;
        z-index: 6;
    }
    .white{
        position: absolute;
        background-color: white;
        width: 100%;
        height: 100%;
        top: 0vw;
        z-index: 6;
    }
    .maindiv {
        overflow: hidden;
        font-family: "Orbitron";
    }
    .backImg {
        background-image: url("images/consumerPage/backImgMbl.png");
        background-size: cover;
        background-position: center;
        height: 166.822vw;
        position: relative;
    }
    .consumer {
        position: absolute;
        bottom: -4.2vw;
        left: -1.7vw;
    }
    .consumer h4 {
        margin: 0;
        font-size: 15.421vw;
        font-weight: 700;
        text-transform: uppercase;
        color: white;
    }
    .textDiv {
        margin: 10.514vw 0vw;
        padding-left: 8.879vw;
    }
    .text1 {
        margin: 0;
        font-size: 8.645vw;
        line-height: 8.645vw;
    }
    .arrowImg {
        margin-top: 8.645vw;
        width: 73.364vw;
    }
    .pretext {
        margin: 7.243vw 0;
        font-size: 3.271vw;
    }
    .gif {
        display: flex;
    }
    .gifImg {
        width: 14.266vw;
        height: 8.586vw;
    }
    .sec3 {
        display: flex;
        margin: 15.012vw 6.542vw 8.879vw 9.112vw;
    }
    .gif2 {
        width: 44.626vw;
        height: 43.458vw;
    }
    p {
        margin: 0;
    }
    .description {
        width: 33.112vw;
        font-size: 2.804vw;
        font-weight: 400;
        line-height: 4.206vw;
        margin: 2.8vw 0 0 5.607vw;
    }
    .sec4 {
        display: flex;
        margin-bottom: 8.645vw;
    }
    .description2 {
        width: 33.112vw;
        font-size: 2.804vw;
        font-weight: 400;
        line-height: 4.206vw;
        margin: auto 6.607vw auto 7.944vw;
        text-align: end;
    }
    .image {
        width: 39.72vw;
        height: 68.458vw;
    }
</style>
