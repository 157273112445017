<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>

<div class="main-div">
    <div class="image">
        <img src="/images/Artist/section4.png" alt="" width="100%" height="100%">
    </div>
    <div class="onediv">
        <p>4</p>
    </div>
    <div class="virtualhead">
        <p>Character, <br>Virtual MuA &<br> Animation Artists</p>
    </div>
    <div class="text2-div">
        <p>
            Life is about motion and humans are about intricate details. You put life into a scene through utilizing your deep understanding of organic anatomy and motion to build realer than life characters. You put the personality into a person. 
            <br>
            <br>
            <span style="font-weight: 600;">
                We’re building the future where you will build armies with each soldier having unique personal traits rather than one character a week. 
            </span>
            <br>
            <br>
            Join us for a sneak peek on what it is like and while you’re there, show off your latest creations and voice your opinion on what you would like to see added to that future.
        </p>
    </div>
    <div class="button">
        <DesktopButton2 
        state = "Artists & Musicians"
        text1 = "Join Visual Art Governance Council"
        text2 = "(Character Seat)"
        text3 = 25
        TextColor = "black"
        TextColor2 = "black"
        BorderColorBefore = '0.0052vw solid black'
        BorderColorAfter = '0.0052vw solid aqua'
        TextColorAfter = 'black'
        Text2Size= 0.521vw
        AnimationColor = 'aqua'
         />
    </div>
    <div class="scroll_div">
        <p class="scrollText" >scroll / drag</p>
        <svg
            width="6.185vh"
            height="0.741vh"
            viewBox="0 0 72 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="img"
        >
            <path
                d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
            />
        </svg>
    </div>
</div>

<style>
    p{
        margin: 0;
        font-family: 'Orbitron';
    }
.main-div{
    height:100vh;
    width:max(165.370vh,893px);
    position: relative;
}
.image{
    position: absolute;
    left:max(4.074vh,22px) ;
    width: max(81.481vh, 440px);
}
.image::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
.onediv {
    font-size: max(55.556vh, 300px);
    color: #f4eeec;
    font-weight: 700;
    position: absolute;
    left: max(52.407vh, 283px);
    opacity: 50%;
    line-height: max(55.556vh , 300px);
    }
    .virtualhead{
        font-weight: 700;
        font-size: max(5.556vh , 30px);
        line-height: max(5.556vh , 30px);
        text-transform: uppercase;
        color: #000000;
        position: absolute;
        left: max(67.963vh , 367px);
        top: max(24.352vh , 131.5px);
    }
    .text2-div p{
        font-size: max(1.852vh ,10px);
        font-weight: 400;
        line-height: max(2.593vh , 14px);
        color: #000000;
        letter-spacing: 0.12vh;
        position: absolute;
        left: max(89.259vh, 482px);
        top: max(46.481vh, 251px);
        width: max(66.019vh, 356.5px);
    }
    .button{
        position: absolute;
        top: max(85vh, 459px);
        left: max(89.259vh, 482px);
    }
    .scroll_div {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 3.704vh;
        left: 141.204vh;
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 700;
        line-height: max(1.857vh, 8px);
        font-family: "Orbitron";
        text-transform: capitalize;
        color: black;
    }
    .scroll_div .img {
        margin-left: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
        fill: black;
    }
</style>