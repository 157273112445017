<script></script>

<section>
    <div class="main-div">
        <div class="image">
            <img
            src="images/Perfomance/unsplash_TK_WT3dl2tw.png"
            width="100%"
            height="100%"
            alt=""
        />
        </div>
        <div class="title">
            <p class="text1">Performers</p>
            <p class="text2">
                We are building the future in which you won't have to take
                multiple shots for a single performance, rather based on a
                myriad performance captures, your likeness will be digitized,
                owned and would be perpetually used into any production.
            </p>
        </div>
        <div class="scroll_div">
            
            <p class="scrollText" >scroll / drag</p>
            <svg
            width="6.185vh"
            height="0.741vh"
            viewBox="0 0 72 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="img"
        >
            <path
                d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
            />
        </svg>
        </div>
    </div>
</section>

<style>
    .main-div {
        position: relative;
        width: 100vw;
        height: 100vh;
        font-family: "Orbitron";
    }
    .image {
        width: 100vw;
        height: 100vh;
        filter: grayscale(0);
        animation-name: grey;
        animation-duration: 5s;
        pointer-events: none;
    }
    .title {
        position: absolute;
        bottom: 7.479vw;
        left: 6.813vw;
    }
    .text1 {
        color: #67eebb;
        font-weight: 800;
        font-size: 6.25vw;
        margin: 0vw;
        animation-name: text1;
        position: absolute;
        animation-duration: 4s;
        left: 0vw;
        bottom: 6vw;
        text-transform: uppercase;
    }
    .text2 {
        color: white;
        font-size: 1.042vw;
        font-weight: 400;
        text-transform: uppercase;
        width: 49.063vw;
        animation-name: text2;
        animation-duration: 5s;
    }

    @keyframes text1 {
        from {
            font-size: 10.417vw;
            left: -0.8vw;
            bottom: 0vw;
        }

        to {
            font-size: 6.25vw;
            left: 0vw;
            bottom: 6vw;
        }
    }
    @keyframes text2 {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes grey {
        0% {
            filter: grayscale(1);
        }
        100% {
            filter: grayscale(0);
        }
    }
    .scroll_div {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 2.5vh;
        right: 3vh;
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 500;
        line-height: max(1.857vh, 8px);
        font-family: "Orbitron";
        color: white;
        text-transform: capitalize;
    }
    .scroll_div .img {
        margin-left: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
        fill: white;
}
</style>
