<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
    let image;
</script>
<section>
    <div class="maindiv">
            <div class="leftdiv" on:mouseenter={() =>{
                image.style.filter='none';
                image.style.transform='scale(1.1)';
            }}
            on:mouseleave={() =>{
                image.style.filter='grayscale(100%)';
                image.style.transform='scale(1)';
            }}>
                <img bind:this={image} src="/images/Artist/SPACEPROJECT.png" alt="" width="100%" height="100%">
            </div>
        <div class="rightdiv">
            <div class="onediv">
                <p>2</p>
            </div>
            <div class="virtualhead">
                <p>Concept Artists<br> & Illustrators</p>
            </div>
            <div class="descriptiontext" >
                <p>You’re part of an elite tribe whose job is to give birth to imaginary characters, fantastical environments and bring entire worlds into existence. You make fantasy real. </p>
                   <p style="font-weight: 700;padding: 1.85vh 0 0vh 0vh;">We’re building a platform worthy of your talents and you are invited to the front row seats.</p>  
            </div>
            <div class="btn">
                <DesktopButton2
                state = "Artists & Musicians"
                text1='Join Visual Art Governance Council'
                text2='(Concept Art Seat)'
                AnimationColor='#00FFFF'
                text3='25'
                TextColorAfter='black'
                />
            </div>
            <div class="scroll_div">
                <p class="scrollText" >scroll / drag</p>
                <svg
                    width="6.185vh"
                    height="0.741vh"
                    viewBox="0 0 72 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="img"
                >
                    <path
                        d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
                    />
                </svg>
            </div>
        </div>
    </div>
</section>
<style>
    .maindiv{
        display: flex;
        position: relative;
        font-family: 'Orbitron';
    }
    .leftdiv {
        width: max(71.296vh ,385px);
        overflow: hidden;
        transition-duration: 1s;
    }
    .leftdiv img{
        width: max(71.296vh ,385px);
        filter: grayscale(100%);
        transition-duration: 1s;
        
    }
    .leftdiv::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    p{
        margin: 0;
    }
    .rightdiv{
        width: max(87.037vh , 470px);
        position: relative;
    }
    .onediv{
        font-size: max(55.556vh , 300px);
        color: rgba(244, 238, 236, 0.5);
        text-transform: uppercase;
        font-weight: 700;
        line-height: max(55.556vh , 300px);
        position: absolute;
        left: 0;
        top: max(2.315vh , 12.5px);
    }
    .virtualhead{
        font-weight: 700;
        font-size: max(5.556vh , 30px);
        line-height: max(5.556vh , 30px);
        text-transform: uppercase;
        color: #000000;
        position: absolute;
        left: max(10.926vh , 59px);
        top: max(30.926vh , 167px);
    }
    .descriptiontext{
        position: absolute;
        left: max(10.926vh , 59px);
        top: max(57.685vh , 311.5px);
        width: max(57.685vh , 311.5px);
        font-size: max(1.852vh ,10px);
        font-weight: 400;
        line-height: max(2.593vh , 14px);
        color: #000000;
        letter-spacing: 0.12vh;
    }
    .scroll_div {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 2.5vh;
        right: 4vh;
        transition-duration: 1s;
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 500;
        line-height: max(1.857vh, 8px);
        font-family: "Orbitron";
        color: black;
        transition-duration: 1s;
        text-transform: capitalize;
    }
    .scroll_div .img {
        margin-left: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
        transition-duration: 1s;
        fill: black;
    }
    .btn{
        position: absolute;
        left: max(10.926vh , 59px);
        top: max(80.370vh , 434px);
    }
</style>