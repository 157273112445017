<script>
    export let title = 'Watch Demo';
    export let textClr = 'white';
</script>
<section>
    <div>
        <button class="btn" style="color: {textClr}; border: 0.234vw solid {textClr}; ">
            {title}
        </button>
    </div>
</section>
<style>
    .btn{
        width: 24.766vw;
        height: 7.009vw;
        font-size: 2.336vw;
        font-weight: 700;
        padding-left: 1.820vw;
        background-color: transparent;
        text-align: start;
    }
</style>