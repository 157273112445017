<script>
    import Mobilebtn2 from '../mobilebtn2.svelte';
</script>

<div class="gamerWrraper">
    <div class="heading1">
        <p>
            ARTISTS <span style="font-weight: 400;">&</span>
        </p>
        <p>MUSICIANS</p>
    </div>
    <div class="imageWrraper">
        <img
            src="/images/colorful.png"
            alt="colorFull"
            width="100%"
            height="auto"
        />
    </div>
    <div class="text">
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit, at
            arcu at praesent sociis dignissim.
        </p>
    </div>
    <div class="btn">
        <Mobilebtn2
            bgcolor="transparent"
            text="Learn More"
            width="23.364vw"
            height="7.477vw"
            txtcolor="black"
            border="black"
        />
    </div>
</div>

<style>
    .gamerWrraper {
        width:100vw;
        overflow: hidden;
        position: relative;
        font-family: "Orbitron";
        background-color: #59e7f2;
        height: max(216.355vw, 100vh);
    }
    p {
        margin: 0;
        font-family: "Orbitron";
    }
    .heading1 {
        color: black;
        font-weight: 900;
        position: absolute;
        left: max(1.168vw, 0.54vh);
        top: max(24.215vw, 10.739vh);
        font-size: max(11.682vw, 5.4vh);
    }
    .heading1 p:nth-child(2){
        margin-left: max(10.2vw, 4.204vh);
    }
    .imageWrraper {
        left: 0;
        width: 104.956vw;
        position: relative;
        top: max(42.888vw, 20.898vh);
        height: max(130.808vw, 53.065vh);
    }
    .text {
        color: black;
        width:71.963vw;
        position: absolute;
        font-size:2.804vw;
        line-height:3.897vw;
        left: max(8.879vw, 4.104vh);
        bottom: max(23.474vw, 10.799vh);
    }
    .btn {
        position: absolute;
        left: max(8.178vw, 3.780vh); 
        bottom: max(9.346vw, 4.320vh);
    }
</style>
