<script>
import rpcjs from 'rpcjs';
import {getContext, setContext} from 'svelte';
// import pages from './mobileview/pages';
// import pages from './desktop/pages';
import {pageWidth} from './store';
import Desktop from './desktop';
import Mobile from './mobile';
// combine all pages
// const allpages = {...pages}
// import Adventure from 'bhoot-svelte-components/adventure';
// import IndexPage from './mobileview/indexPage.svelte';
const {api_init, title, createUrlStore} = rpcjs.props;
export let ssrUrl = '';
setContext('APP', {url: createUrlStore(ssrUrl)});
const {url} = getContext('APP');
const pathprefix = $url.pathname.match(/^\/[^\/]*/).at(0);


	let height, width;
	$: $pageWidth = width;
	$: console.log('app_url', $pageWidth);
</script>

<svelte:head>
	<title> Opus | {$title}</title>
</svelte:head>

<svelte:window bind:innerHeight={height} bind:innerWidth={width}/>
<main>
	<!-- <div class="desktop">
		<Desktop />
	</div>
	<div class="mobile">
		<Mobile />
	</div> -->
	<Desktop/>
</main>

<style>
	:global(body) {
		font-family: "Red Hat Text";
		margin: 0;
		padding: 0;
	}
	:global(a) {
		text-decoration: none;
		color: inherit;
	}
	/* :global(a:hover) {
		text-shadow: .1vw .3vw .2vw lightgrey;
	} */
	.mobile {
        display: none;
    }
    .desktop {
        display: block;
    }
    @media screen and (max-width: 1024px) {
        .desktop {
            display: none;
        }
        .mobile {
            display: block;
        }

    }
</style>
