<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>

<section>
    <div class="main-div">
        <div class="images-div">
            <div class="mobile-div">
                <img
                    src="images/Perfomance/Vectarytexture.png"
                    alt=""
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="back-div">
                <img
                    src="images/Perfomance/back1.svg"
                    alt=""
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="text-div">
                <p>Performers & Opus</p>
                <p>ACTORS & MODELS</p>
                <p>
                    As an actor or model, your likeness is your brand, your
                    product and your intellectual property. Traditionally
                    however, the path to achieve fame and to truly reap the
                    reward of the talent is a never ending, tedious and most of
                    the time an unrewarding grind.
                </p>
                <p>
                    Using <span style="color: #1C7BFF;"
                        >our performance capture app,</span
                    > you can immortalize your likeness as it is today and retain
                    it digitally forever. The likeness can be endlessly used by a
                    producer and you make money whenever it does.
                </p>
                <p>
                    Also, as an early believer, you will be given a limited time
                    pass to the Governance Council. Take part in making history,
                    vote on important issues and get rewarded for it.
                </p>

                <div class="btn-div">
                    <div>
                        <DesktopButton2
                            state = "Performer"
                            width="max(24.074vh, 130px)"
                            text1 = "Join Artist Governace Council"
                            text2="(Actor Seat)"
                            AnimationColor="black"
                            TextColorAfter="#67eebb"
                            BorderColorAfter="black"
                            text3="15"
                        />
                    </div>
                    <div>
                        <DesktopButton2
                            state = "Performer"
                            width="max(24.074vh, 130px)"
                            text1 = "Join Artist Governace Council"
                            text2="(Model Seat)"
                            AnimationColor="black"
                            TextColorAfter="#67eebb"
                            BorderColorAfter="black"
                            text3="15"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll_div">
            
            <p class="scrollText" >scroll / drag</p>
            <svg
            width="6.185vh"
            height="0.741vh"
            viewBox="0 0 72 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="img"
        >
            <path
                d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
            />
        </svg>
        </div>
    </div>
</section>

<style>
    .btn-div {
        display: flex;
        width: max(51.389vh, 277.5px);
        justify-content: space-between;
        align-items: center;
        margin-top: max(4.074vh, 22px);
    }
    .main-div {
        width: max(136.481vh, 737px);
        height: max(100vh, 540px);
        position: relative;
    }
    .images-div {
        display: flex;
        position: relative;
    }
    .back-div {
        position: absolute;
        right: 0vh;
        width: max(89.722vh, 484.5px);
        height: max(100vh, 540px);
        pointer-events:none ;
        z-index: -1;
    }
    .mobile-div {
        left: 0vh;
        position: absolute;
        width: max(117.037vh, 632px);
        height: max(100vh, 540px);
        pointer-events:none ;
    }
    .text-div {
        position: absolute;
        right: max(13.148vh, 71px);
        width: max(57.685vh, 311.5px);
        font-family: "Orbitron";
        top: max(14.722vh, 79.5px);
    }
    .text-div p:nth-child(1) {
        font-size: max(1.296vh, 7px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 400;
    }
    .text-div p:nth-child(2) {
        font-size: max(5.556vh, 30px);
        line-height: max(5.556vh, 30px);
        margin-top: max(2.407vh, 13px);
        margin-bottom: max(5.741vh, 31px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 700;
    }
    .text-div p:nth-child(3) {
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 400;
    }
    .text-div p:nth-child(4) {
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 600;
    }
    .text-div p:nth-child(5) {
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        font-weight: 400;
        letter-spacing: max(0.058vh, 0.5px);
    }
    .scroll_div {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 2.5vh;
        right: -9vh;
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 500;
        line-height: max(1.857vh, 8px);
        font-family: "Orbitron";
        color: black;
        text-transform: capitalize;
    }
    .scroll_div .img {
        margin-left: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
        fill: black;
}
</style>
