<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>

<section>
    <div class="main-div">
        <div class="images-div">
            <div class="mobile-div">
                <img
                    src="images/Perfomance/Group1000002574.png"
                    alt=""
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="back-div">
                <img
                    src="images/Perfomance/back2.svg"
                    alt=""
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="text-div">
                <p>Performers & Opus</p>
                <p>Motion Recording Artists</p>
                <p>The stage is the play.</p>
                <p>
                    Bringing realism to a production requires more than just the
                    main characters. From background crowds to that gnarly
                    character making a single time appearance, it takes a lot to
                    tell a story effectively.
                </p>
                <p>
                    You don’t have to be an actor to record motions. The
                    symphony is in the variety of motions from everyone
                </p>
                <p>
                    Our
                    <span style="color:#1C7BFF ;"> motion recording app</span> turns
                    a simple smartphone into a production capture studio.
                </p>
                <p>
                    Be the part of early adopters, vote on what features you
                    would like to see first or simply join the discussion on the
                    future of democratized entertainment.
                </p>
                <div class="btn-div">
                    <DesktopButton2
                        state = "Performer"
                        width="max(24.074vh, 130px)"
                        text1 = "Join Artist Governace Council"
                        text2="(Actor Seat)"
                        AnimationColor="black"
                        TextColorAfter="#67eebb"
                        BorderColorAfter="black"
                        text3="15"
                    />
                </div>
            </div>
        </div>
       
    </div>
</section>

<style>
    .main-div {
        width: max(138.796vh, 749.5px);
        height: max(100vh, 540px);
        overflow: hidden;
        position: relative;
    }
    .images-div {
        display: flex;
        position: relative;
        width: max(138.796vh, 749.5px);
        height: max(100vh, 540px);
    }
    .back-div {
        position: absolute;
        right: 0vh;
        width: max(101.852vh, 550px);
        height: max(100vh, 540px);
        pointer-events:none ;
        z-index: -1;
    }
    .mobile-div {
        left: -20vh;
        position: absolute;
        width: max(108.611vh, 586.5px);
        height: max(100vh, 540px);
        top: 3vh;
        pointer-events:none ;
    }
    .text-div {
        position: absolute;
        right: max(15.648vh, 84.5px);
        width: max(50.093vh, 270.5px);
        font-family: "Orbitron";
        top: max(7.222vh, 39px);
    }
    .text-div p:nth-child(1) {
        font-size: max(1.296vh, 7px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 400;
    }
    .text-div p:nth-child(2) {
        font-size: max(5.556vh, 30px);
        line-height: max(5.556vh, 30px);
        margin-top: max(1.759vh, 9.5px);
        margin-bottom: max(4.167vh, 22.5px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 700;
    }
    .text-div p:nth-child(3) {
        margin-top: max(2.315vh , 12.5px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 700;
    }
    .text-div p:nth-child(4) {
        margin-top: max(2.315vh , 12.5px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        letter-spacing: max(0.058vh, 0.5px);
        font-weight: 400;
    }
    .text-div p:nth-child(5) {
        margin-top: max(2.315vh , 12.5px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        font-weight: 400;
        letter-spacing: max(0.058vh, 0.5px);
    }
    .text-div p:nth-child(6) {
        margin-top: max(2.315vh , 12.5px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        font-weight: 400;
        letter-spacing: max(0.058vh, 0.5px);
    }
    .text-div p:nth-child(7) {
        margin-top: max(2.315vh , 12.5px);
        font-size: max(1.852vh, 10px);
        line-height: max(2.593vh, 14px);
        font-weight: 400;
        letter-spacing: max(0.058vh, 0.5px);
    }
    .btn-div {
        margin-top: max(4.537vh, 24.5px);
    }
</style>
