<script></script>

<div class="mainContentHolder">
    <div class="pos">
        <div class="head">
            <div class="heading">
                <h1>
                    HOW <br />
                    IT WORKS ?
                </h1>
            </div>
            <div class="para">
                <p>
                    Immerse audiences in new levels of storytelling and
                    engagement.
                </p>
            </div>
        </div>
        <div class="bottom">
            <div class="discription">
                <div>
                    <h1>Story</h1>
                    <p style="padding-top: 3vh;">
                        NLP trained to extract features from any <span
                            >literary style</span
                        > in English.
                    </p>
                    <p>Thereʼs no special syntax to learn.</p>
                </div>
                <div class="img-wrraper">
                    <img
                        src="/images/landginpageSection3/scriptText.png"
                        alt="script"
                        height="100%"
                        width="100%"
                    />
                </div>
            </div>
            <div class="plusWrraper">
                <img
                    src="/images/landginpageSection3/PLUS.svg"
                    alt="plus"
                    width="100%"
                    height="100%"
                />
            </div>
            <div class="discription" >
                <div style="padding-left: 2.2vw; width: 11.7vw;">
                    <h1>Opus</h1>
                    <p style="padding-top: 0.5vh;"><span>Text to Video</span> generation using AI.</p>
                    <p>
                        <span>Computational Production</span>- everything from
                        scenes, assets, characters, dialogue and visual effects.
                    </p>
                </div>
                <div class="img-wrraper">
                    <img
                        src="/images/landginpageSection3/textMen.png"
                        alt="textmen"
                        height="100%"
                        width="100%"
                    />
                </div>
            </div>
        </div>
        <div class="clrDiv">
            <div>
                <p>Original Content</p>
            </div>
            <div>
                <p>1 / 10th of cost</p>
            </div>
            <div>
                <p>Unmatched Speed</p>
            </div>
        </div>
    </div>
    <div class="scroll_div">
        <p class="scrollText">scroll / drag</p>
        <svg
            width="6.185vh"
            height="0.741vh"
            viewBox="0 0 72 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="img"
        >
            <path
                d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
                fill="black"
            />
        </svg>
    </div>
</div>

<style>
    .mainContentHolder {
        width: max(126.296vh, 682px);
        height:max(100vh, 540px);
        position: relative;
        font-family: "Orbitron";
        background-color: white;
    }
    .pos {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0vh;
        left: 0vh;
    }
    .head {
        width:max(62.315vh, 336.5px);
        position: absolute;
        top: 15vh;
        left: 15vh;
    }
    .heading h1 {
        font-size:max(9.259vh, 50px);
        font-weight: 900;
        margin: 0;
        line-height: 100%;
    }
    .para {
        width: max(36.852vh, 199px);
        float: right;
    }
    .para p {
        font-size:max(1.852vh, 10px);
        color: black;
        font-weight: 400;
        line-height: max(2.315vh, 12.5px);
    }
    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 65%;
        position: absolute;
        bottom: 25vh;
        left: 18vh;
    }
    .bottom .plusWrraper {
        height: max(2.963vh, 16px);
        width: max(2.963vh, 16px);
    }
    .bottom .discription {
        width: max(33.796vh, 182.5px);
        height: max(25.556vh, 138px);
        border: 0.093vh solid #BEBEBE;
        position: relative;
    }
    .bottom .discription div {
        padding: 3vh;
        width: 12.5vw;
        padding-left: 5.5vh;
    }
    .bottom .discription h1 {
        width: max(15.926vh, 86px);
        border-bottom: 0.093vh solid #000000;
        margin: 0;
        padding-bottom: 0.5vw;
        font-size: max(1.852vh, 10px);
    }
    .discription P {
        font-size: max(1.296vh, 7px);
        color: black;
        font-weight: 400;
        letter-spacing: 0.013vw;
    }
    .discription:nth-child(1) p span {
        color: rgba(242, 89, 166, 1);
        font-weight: 600;
    }
    .discription:nth-child(1) p:nth-child(3) {
        margin-top: 3vh;
    }
    .discription:nth-child(3) p span {
        color: rgba(70, 174, 0, 1);
        font-weight: 600;
    }
    .discription:nth-child(3) p:nth-child(2) span {
        color: rgba(70, 174, 0, 1);
        font-weight: 600;
    }
    .discription:nth-child(3) p:nth-child(3) {
        margin-top: 3vh;
    }
    .discription:nth-child(1) .img-wrraper{
        position: absolute;
        top: -6.5vh;
        left: -11.2vh;
        width: max(13.426vh, 72.5px);
        height: max(17.5vh, 94.2px);
        overflow: hidden;
        border: 1vh solid white;
        padding: 0vh !important;
    }
    .discription:nth-child(3) .img-wrraper{
        position: absolute;
        right: -10.5vh;
        top: -7vh;
        width: max(13.611vh, 73.5px);
        height: max(17.435vh, 94.15px);
        overflow: hidden;
        border: 1vh solid white;
        padding: 0vh !important;
    }
    .clrDiv{
        position: absolute;
        bottom: 0vh;
        right: 0vh;
        width: fit-content;
    }
    .clrDiv P{
        color: white;
        line-height: 5vh;
        text-align: center;
        font-weight: 600;
        font-size: max(1.852vh, 10px);
        margin: 0;
    }
    .clrDiv div:nth-child(1){
        height: max(4.630vh, 25px);
        width: max(26.296vh, 142px);
        background-color:  #D578FF;
        margin-left: auto;
    }
    .clrDiv div:nth-child(2){
        height: max(4.630vh, 25px);
        width: max(24.444vh, 132px);
        background-color:  #F259A6;
        margin: 0.3vh 0 0.3vh auto;
    }
    .clrDiv div:nth-child(3){
        height: max(4.630vh, 25px);
        width: max(30.185vh, 163px);
        background-color:  #625DF0;
        margin-left: auto;
    }
    .img-wrraper::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    .scroll_div {
        display: flex;
        align-items: center;
        justify-content: end;
        position: absolute;
        bottom: 0;
        left: 41.296vh;
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 500;
        line-height: max(1.857vh, 8px);
        color: black;
        text-transform: capitalize;
    }
    .scroll_div .img {
        margin-left: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
    }
</style>