<script>
    export let btnTitle = 'Proceed';
    export let JoinAs;
    let emailAdd;
    let CloseButton;
    let proceed = false;
    let showdiv = false;
    async function saveData() {
        const functionUrl = 'https://us-central1-opusai.cloudfunctions.net/updateWorkSheet';
        await fetch(functionUrl, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: [emailAdd, JoinAs],
            }),
        }).then((res) => {
            if(res.status === 401){
                proceed = false;
                showdiv.style.display = 'block';
            }else if(res.status === 200){
                proceed = true;
                showdiv.style.display = 'none';
            }
        });
    }
</script>

<section>
    <div class="box" on:click />

    {#if proceed === false}
        <div class="secondBox" style="">
            <p>
                <span style="font-size: 1.2vw;font-weight: 900;">
                    We're not ready yet :(
                </span> <br>Join our waitlist
            </p>
            <div
                style="
                padding-left: 12.240vw;
            "
            ><form on:submit|preventDefault={saveData}>
                <div class="input-field">
                    <input bind:value={emailAdd} type="text" required placeholder="Enter your Email..." />
                </div>
                <div class="error-div" bind:this={showdiv}>
                    <p style=" color: red; font-size: 0.5vw;">Please Enter Vaild Email</p>
                </div>                
                <div class="btn">
                    <button type = "submit"
                        >{btnTitle}</button
                    >
                </div>
            </form>
            </div>
        </div>
    {:else if proceed === true}
    {CloseButton.style.top = '59%'}
        <div class="thankyou-box">
            <p class="thankyou-text1">Awesome! Thanks for submitting your email.</p>
            <p class="thankyou-text2">
                We'll notify you as we roll out invites in the coming weeks!
            </p>
        </div>
    {/if}
    <div bind:this={CloseButton}
        style="
        position: fixed;
        left: 49%;
        top: 66%;
        z-index: 10;
        cursor:pointer

    "
    >
        <p class="cancelBtn" on:click>close</p>
    </div>
</section>

<style>
    .error-div{
        margin-top: -3vw;
        margin-left: -21.7vw; 
        margin-bottom: -0.8vw;
        display: none;
    }
    .input-field {
        margin-top: 1vw;
    }
    .thankyou-box {
        width: 40.885vw;
        height: 6.927vw;
        background-color: white;
        position: fixed;
        background-color: white;
        margin: auto;
        z-index: 12;
        top: 45%;
        left: 30%;
        z-index: 14;
    }
    .thankyou-box .thankyou-text1 {
        padding-top: 1.2vw;
        color: black;
        font-family: "Orbitron";
        font-size: 1.042vw;
        font-weight: 900;
        text-align: center;
    }
    .thankyou-box .thankyou-text2 {
        color: black;
        font-size: 0.6vw;
        font-weight: 400;
        font-family: "Orbitron";
        position: absolute;
        right: 12vw;
        bottom: 1vw;
    }
    .secondBox {
        position: fixed;
        width: 40.885vw;
        height: 15.573vw;
        background-color: white;
        margin: auto;
        z-index: 12;
        top: 35%;
        left: 30%;
    }
    .cancelBtn {
        color: white;
        font-size: 1.042vw;
        font-weight: 400;
        margin-top: 1.667vw;
        font-family: "Orbitron";
    }
    .box {
        width: 100%;
        position: relative;
        background: rgba(0, 0, 0, 0.8);
        height: 100%;
        text-align: center;
        position: fixed;
        top: 0vw;
        z-index: 10;
        left: 0vw;
    }
    .secondBox p {
        font-size: 1.042vw;
        font-weight: 500;
        width: 33.281vw;
        margin: auto;
        color: black;
        padding-top: 3.2vw;
        text-align: center;
        line-height: 1.306vw;
        font-family: "Orbitron";
    }
    .secondBox button {
        font-family: "Orbitron";
        width: 16.094vw;
        height: 2.083vw;
        background-color: #ff007f;
        color: white;
        border: none;
        font-size: 0.625vw;
        font-weight: 800;
        cursor: pointer;
    }
    .secondBox input {
        font-family: "Orbitron";
        width: 14.7vw;
        height: 2.083vw;
        color: rgb(0, 0, 0);
        border: 0.052vw solid #b2b2b2;
        font-size: 0.521vw;
        font-weight: 400;
        padding-left: 1.042vw;
    }
    .secondBox button:hover {
        font-family: "Orbitron";
        width: 16.094vw;
        height: 2.083vw;
        background-color: black;
        color: white;
        border: none;
        font-size: 0.625vw;
        font-weight: 800;
        transition-duration: 1s;
        cursor: pointer;
    }
    .btn {
        margin-top: 1vw;
    }
</style>
