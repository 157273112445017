<script>
    import Section2 from '../Gamers/section2.svelte';
    import Section3 from '../Gamers/section3.svelte';
    import LandingPagenavbar from '../../reusableitems/landingPagenavbar.svelte';
    import DesktopButton from '../../reusableitems/DesktopButton.svelte';
    import OnPageLoad from '../../reusableitems/onPageLoad.svelte';
    import rpcjs from 'rpcjs';
    const {title} = rpcjs.props;
    title.set('Gamers');
</script>

<section>
    <div class="navbar">
        <LandingPagenavbar bgClrAfter="black" />
    </div>
    <div>
        <OnPageLoad
            background="conic-gradient( white 0deg 90deg, #807CF9 90deg 270deg, white 270deg 360deg)"
        />
    </div>
    <div style="display: flex;">
        <div class="maindiv">
            <div class="leftDiv">
                <div class="heading">
                    <p>GAMERS</p>
                </div>
            </div>
            <div class="secndDiv">
                <div>
                    <p class="text">
                        We are <span style="font-weight: 900;"
                            >building the future of games</span
                        > where games are not merely designed and published by producers
                        but are a collaborative narrative agreed upon and voted on
                        by the community.
                    </p>
                    <p class="text1">
                        Our technology turns that narrative into living
                        breathing worlds that change dynamically as the
                        narrative grows and changes.
                    </p>
                    <p class="text1" style="margin-top: max(1.852vh , 10px);">
                        Learn what it is like to play in an ever changing world
                        in which you aren’t merely a player and also a creator
                        and administrator.
                    </p>
                </div>
                <div class="btn">
                    <DesktopButton
                    state = "Games"
                        TextColor2="black"
                        borderClr="0.1vw transparent"
                        BorderClrAfter="0.1vw transparent"
                    />
                </div>
                <div class="scroll_div">
                    <p class="scrollText">scroll / drag</p>
                    <svg
                        width="6.185vh"
                        height="0.741vh"
                        viewBox="0 0 72 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="img"
                    >
                        <path
                            d="M71.3536 4.35355C71.5488 4.15829 71.5488 3.84171 71.3536 3.64645L68.1716 0.464466C67.9763 0.269204 67.6597 0.269204 67.4645 0.464466C67.2692 0.659728 67.2692 0.976311 67.4645 1.17157L70.2929 4L67.4645 6.82843C67.2692 7.02369 67.2692 7.34027 67.4645 7.53553C67.6597 7.7308 67.9763 7.7308 68.1716 7.53553L71.3536 4.35355ZM0 4.5H71V3.5H0V4.5Z"
                            fill="black"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <div>
            <Section2 />
        </div>
        <div>
            <Section3 />
        </div>
    </div>
</section>

<style>
    .maindiv {
        padding-right: 37vh;
        height: max(100vh, 540px);
        background-color: #f4eeec;
        display: flex;
        position: relative;
        font-family: "Orbitron";
    }
    .leftDiv {
        background-image: url("/images/Gamer/gamesBG.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: max(137.87vh, 744.5px);
    }
    p {
        margin: 0;
    }
    .heading{
        position: absolute;
        bottom: 0vh;
        font-weight: 800;
        font-size: max(25.926vh ,140px);
        color: #F4EEEC;
        line-height: 19.296vh;
        overflow: hidden;
    }
    .secndDiv {
        position: absolute;
        top: max(22.13vh, 119.5px);
        left: max(123.889vh, 669px);
    }
    .text {
        width: max(31.87vh, 177.5px);
        font-weight: 500;
        font-size: max(2.222vh, 12px);
        line-height: max(3.111vh, 16.8px);
        color: #000000;
    }
    .text1 {
        width: max(33.944vh, 199.5px);
        font-weight: 400;
        font-size: max(1.296vh, 7px);
        line-height: max(1.815vh, 9.8px);
        margin-left: max(5.556vh, 30px);
        margin-top: max(7.13vh, 38.5px);
        color: #000000;
    }
    .scroll_div {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-left: max(28.056vh, 65.3px);
        margin-top: max(16.13vh, 48.5px);
    }
    .scroll_div .scrollText {
        font-size: max(1.481vh, 8px);
        font-weight: 700;
        line-height: max(1.857vh, 8px);
        color: black;
        text-transform: capitalize;
    }
    .scroll_div .img {
        margin-left: 1.778vh;
        width: max(6.185vh, 28px);
        height: max(0.741vh, 8px);
    }
    .btn {
        margin-top: max(6.204vh, 33.5px);
        margin-left: max(7.45vh, 39.3px);
    }
    .navbar {
        position: fixed;
        width: 100%;
        z-index: 2;
    }
</style>
