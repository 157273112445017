<script>
    export let src = '/images/team/adnan.png';
    export let name = 'Adnan Yunus';
    export let title = 'Founder';
    export let desigination = 'CEO';
    export let role = 'Cheif Data Scientist';
    export let href;
    export let linked = '/images/team/linkden.svg';
    let hover;
</script>

<div class="cardContentHolder">
    <div class="imgWrraper" on:mouseenter={()=>{
        hover.style.transform='scale(1.15)';
        hover.style.transition='0.5s ease-in-out';
    }}
    on:mouseleave={()=>{
        hover.style.transform='scale(1)';
    }}>
        <img bind:this={hover} {src} alt="Team" width="100%" height="100%" />
    </div>
    <div class="contentWrraper">
        <div class="content">
            <div class="linkdenWrraper">
                <div style="width:max(36.056vh,151.5px);">
                    <h1>{name}</h1>
                    <p>{title}</p>
                    <p>{@html desigination}</p>
                    <p>{role}</p>
                </div>
                <a href={href} target="_blank">
                    <img
                        src={linked}
                        alt="linkden"
                        width="100%"
                        height="100%"
                    />
                </a>  
            </div>
        </div>
    </div>
</div>

<style>
    a{
        position: absolute;
        right: 3vh;
        bottom: 3vh;
    }
    .cardContentHolder {
        width: max(41.852vh, 226px);
        height: max(55.463vh, 299.5px);
        background-color: white;
        border-radius: max(1.852vh, 10px);
        filter: drop-shadow(0vh 0vh 2.963vh rgba(0, 0, 0, 0.25));
        overflow: hidden;
        margin-top: 11vh;
    }
    .cardContentHolder:nth-last-child(1) {
        margin-right: 5vh;
    }
    .imgWrraper {
        width: max(41.852vh, 226px);
        height: max(36.019vh, 194.5px);
        background-color: white;
        border-top-right-radius: max(1.852vh, 10px);
        border-top-left-radius: max(1.852vh, 10px);
        overflow: hidden;
    }
    .imgWrraper::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    .contentWrraper {
        font-family: "Orbitron";
        width: fit-content;
        margin: auto;
    }
    .content h1 {
        font-size: max(3.704vh, 20px);
        font-weight: 700;
        margin-bottom: 1vh;
    }
    .content p {
        font-weight: 400;
        font-size: max(1.852vh, 10px);
        line-height: max(2.315vh, 12.5px);
        margin: 0;
    }
    .linkdenWrraper {
        display: flex;
        align-items: end;
        justify-content: space-between;
    }
    .linkdenWrraper img{
        width: max(2.778vh , 15px);
        height: max(2.778vh , 15px);
    }
</style>
