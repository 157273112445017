<script>
export let title = 'watch demo';
export let hover;
export let bgclrHover= 'transparent';
export let borderHover = '0.093vh solid black';
export let colorHover = 'black';
export let bgclr= 'black';
export let border = '0.093vh solid white';
export let color= 'white';
export let width = 'max(16.389vh, 88.5px)';
</script>

<section>
    <div class="btn">
        <button bind:this={hover}
        style="background-color: {bgclr}; border: {border}; color:{color}; width:{width}"
        on:mouseenter={()=>{
            hover.style.backgroundColor=bgclrHover;
            hover.style.paddingRight='0.4vh';
            hover.style.border=borderHover;
            hover.style.color= colorHover;
            hover.style.transitionDuration='1.3s';
        }}
        on:mouseleave={()=>{
            hover.style.backgroundColor=bgclr;
            hover.style.paddingRight='4vh';
            hover.style.border=border;
            hover.style.color=color;
            hover.style.transitionDuration='1.3s';
        }}>{title}</button>
    </div>
</section>

<style>
    .btn button{
        width: max(16.389vh, 88.5px);
        height: max(3.704vh, 20px);
        text-transform: capitalize;
        font-size: max(1.296vh , 7px);
        line-height: max(1.626vh , 8.78px);
        font-weight: 700;
        font-family: 'Orbitron';
        padding-right: max(4vh , 21.6px);
        cursor: pointer;
    }
</style>