<script>
    import Button2 from '../reusableitems/DesktopButton.svelte';
    import LandingPagenavbar from '../reusableitems/landingPagenavbar.svelte';
    import OnPageLoad from '../reusableitems/onPageLoad.svelte';
    import rpcjs from 'rpcjs';
    const {title} = rpcjs.props;
    title.set('Investors');
</script>

<div class="main-div">
    <div
        style="position: fixed;
    width: 100vw;
    z-index: 21;"
    >
        <LandingPagenavbar togglerclr="white" textClrAfter='black' textclr='white' bgClrAfter='white' borderclr='white'/>
    </div>
    <div>
        <OnPageLoad background='conic-gradient( white 0deg 90deg, #2F97C5 90deg 270deg, white 270deg 360deg)'/>
    </div>
    <div class="left-box">
        <div class="inner-div">
            <p
                style="float: right;margin-right:4.219vw;font-size:1.042vw;font-weight:400;padding-top:8vw;"
            >
                *
            </p>
            <div class="top-text">
                <p>
                    The world is changing at a rapid pace and we’re at the
                    forefront of where it is headed.
                </p>
                <p class="bottom-text" style="padding:2.604vw 0 2vw 0;">
                    <span>
                        With a global decentralized community of young artists,
                        creators, dreamers and visionary, we’re changing the
                        meaning of what it means to create, to advertise and to
                        experience
                    </span>
                </p>
                <p class="bottom-text" style="">
                    We started with the vision of a technology that will change
                    the world, with the knowledge that if <span>
                        `content is king`, then 3D content is `the king of kings
                    </span>
                </p>
                <p class="bottom-text" style="padding:1vw 0;">
                    Now we’re jumping straight into capitalizing it through the
                    global and universal appeal by building a community. We’re
                    looking for the early believers who understand the
                    difference between making a quick buck and owning an entire
                    industry.
                </p>
                <p class="bottom-text">Want to learn more about our vision?</p>
            </div>
        </div>
        <div
        class="btn">
            <Button2
                state = "Investor"
                text="Join Investors Council"
                BackColor="#FF007F"
                TextColor2="black"
                borderClr="0.1vw transparent"
                BorderClrAfter="0.1vw transparent"
            />
        </div>
    </div>

    <div class="right-box">
        <img src="images/invest/OpusAmbassador-34.png" alt="" />
    </div>
</div>

<style>
    .main-div {
        position: relative;
        font-family: "Orbitron";
        display: flex;
        justify-content: space-between;
        height: 100vh;
    }

    .inner-div {
        width: 35vw;
        height: 43vw;
        background-color: #d5f5ff;
        border-bottom-left-radius: 2.083vw;
        border-bottom-right-radius: 2.083vw;
        margin: auto;
    }
    p {
        margin: 0;
    }
    .top-text {
        font-size: 2.083vw;
        font-weight: 600;
        width: 24.271vw;
        padding-top: 7.167vw;
        margin-left: 3.99vw;
    }

    .bottom-text {
        font-size: 0.729vw;
        width: 27.396vw;
        font-weight: 400;
        letter-spacing: 0.058vw;
        line-height: 1.021vw;
    }

    .bottom-text span {
        font-weight: 800;
    }

    .left-box {
        width: 48.75vw;
    }

    .right-box {
        width: 51.25vw;
        height: 49.25vw;
        pointer-events: none;
    }
    .right-box img {
        width: 100%;
        height: 100%;
    }

    .btn {
        width: fit-content;
        margin: auto;
        margin-top: 1.719vw !important;
    }
</style>
