<script>
import {NotFirstVisitGamer} from '../store';
import {fade} from 'svelte/transition';
let show = true;
setTimeout(()=> {
    show=false;
    NotFirstVisitGamer.update((n) => true);
}, 8500);
</script>
<section>
    {#if show}
        <div class="main"  transition:fade="{{duration: 1090}}">
            <div class="videogif" >
                <video width="100%" height="100%" autoplay loop muted>
                    <source src= '/images/Gamer/mainGIf.mp4' type="video/mp4">
                </video>
            </div>
        </div>
        {#if $NotFirstVisitGamer}
            <div class="skipButton" on:click="{()=>{
                show=false;
                }}">
                <h1>Skip</h1>
            </div>
        {/if}
    {/if}
</section>
<style>
    .main{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: black;
        z-index: 2;
    }
    .videogif{
        background-color: black;
        position: absolute;
        width: 100vw;
        height: 100vh;
        animation: move 10s linear;
    }
    @keyframes move {
        0%{
            transform: scale(0.05);
        }
        25%{
            transform:scale(0.1);
        }
        50%{
            transform:scale(0.3);
        }
        75%{
            transform:scale(0.6);
        }
        100%{
            transform: scale(0.9);
        }
    }
    .skipButton{
        position: absolute;
        bottom: 2vh;
        right: 2vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: fit-content;
        text-align: center;
        height: 5vh;
        width: fit-content;
        z-index: 1000;
        cursor: pointer;
    }
    .skipButton h1{
        font-size: 1vw;
        color:white;
        font-family: "Roboto Mono";
        font-weight: 800;
    }
</style>