<script>
    import AlphabetAnimation from '../../../reusableitems/alphabetAnimation.svelte';
    import TwoInOnebutton from '../../../reusableitems/TwoInOnebutton.svelte';
    let currentWord = 0;
    // let displayN = false;
    /**
    *
    *
    */
    // function timeUp() {
    //     displayN = false;
    // }
    // $: if (currentWord === 3) {
    //     displayN = true;
    //     setTimeout(timeUp, 5000);
    // }
</script>

<div class="main-div">
    <div class="main-text">
        <p class="text1">OUR</p>
        <p class="text2">TECHNOLOGY</p>
        <p class="text3">turns text to</p>
        <!-- {#if displayN === true}
            <p class="text4">N</p>
        {/if} -->
    </div>
    <div class="animated-word">
        <AlphabetAnimation bind:currentWord/>
    </div>

    <div class="middle_content">
        <div class="content-text1"><p>OpusAI</p></div>
        <hr />
        <div class="content-text2">
            <p style="margin-bottom: 1.5vw;"> 
                Let’s build the future of decentralized entertainment together. 
            </p>
            <p >
                We are a self sustaining decentralized global community of 
                <span>Dreamers, Gamers, Artists, Storytellers, Musicians, Advertisers, Investors and Technical Creators</span>
            </p>
        </div>
    </div>
    <div
        style="display: flex;margin-top:3.241vh;"
    >
        <div class="social_links">
            <a href="https://twitter.com/OpusAIInc" target="_blank">
                <img 
                    class="social1"
                    src="/images/story_section/twitter.svg"
                    alt=""
                />
            </a>
            <a href="https://www.linkedin.com/company/18782408/admin/" target="_blank">
                <img 
                    class="social2"
                    src="/images/story_section/linkdin.svg"
                    alt=""
                />
            </a>
            <a href="https://www.youtube.com/c/opusai" target="_blank">
                <img 
                    class="social3"
                    src="/images/story_section/youtube.svg"
                    alt=""
                />
            </a>
            <!-- <img on:click={() => {
                    window.open('https://discord.gg/Mz3t8NsN', '_blank');
                }}
                class="social4"
                src="/images/story_section/Discord.svg"
                alt=""
            /> -->
        </div>

        <div class="btn">
            <TwoInOnebutton />
        </div>

        <div class="scroll_div">
            <p>Scroll / Drag</p>
            <img src="/images/story_section/arrow.svg" alt="" />
        </div>
    </div>
</div>

<style>
    .animated-word {
        display: flex;
        justify-content: center;
    }
    p{
        margin: 0;
    }
    .main-div {
        width: max(88.889vh, 480px);
        height: 100%;
        /* padding-top: 5.648vh; */
        font-family: "Orbitron";
        background-color: black;
    }
    .main-text {
        margin-left: max(12.685vh , 78.5px);
        width: 29.698vw;
    }
    .text1 {
        color: white;
        font-size: max( 3.704vh , 20px);
        font-weight: 900;
        line-height: max(4.644vh, 25.8px);
        text-transform: uppercase;
        padding-top: max(18.889vh , 102px);
        margin: 0;
    }
    .text2 {
        color: black;
        font-size: max(7.407vh , 40px);
        font-weight: 900;
        line-height: max(7.611vh , 38.7px);
        text-transform: uppercase;
        -webkit-text-stroke: max(0.185vh , 1px) white;
        margin: 0;
    }
    .text3 {
        color: white;
        font-size: 2.083vw;
        font-weight: 900;
        line-height: max(5.967vh , 37.62px);
        text-transform: uppercase;
        margin: 0;
        text-align: end;
        padding-right: 1.2vw;
    }
    /* .text4 {
        color: white;
        font-size: max(5.556vh, 30px);
        font-weight: 900;
        line-height: max(5.967vh , 37.62px);
        text-transform: uppercase;
        margin-left: max(38.3vh,206.82px);
        margin-top: -5.77vh;
        margin-bottom: 0;
        animation: ani 5s 0.001s ;
    } */
    @keyframes ani {
        0% {
            opacity: 0;
        }
        20%{
            opacity: 0.5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    /* .text4 {
        color: #da22ff;
        font-size: 9.259vh;
        font-weight: 900;
        line-height: 7.611vh;
        text-transform: uppercase;
        margin: 0;
    } */
    .middle_content {
        margin-top: max(13.519vh, 73px);
        display: flex;
        justify-content: center;
        margin-left: max(7.7vh , 41.58px);
    }

    hr {
        margin: 0 3.704vh;
    border: none;
    background-color: white;
    width: max(0.093vh , 0.5px);
    }
    .content-text1 {
        color: white;
        font-size: max(1.852vh , 10px);
        line-height: max(2.322vh , 12.54px);
        font-weight: 400;
        display: flex;
        align-items: center;
    }
    .content-text2{
        padding: 1vw 0vw;
    }
    .content-text2 p {
        width: max(31.889vh, 152.5px);
        font-size: max(1.296vh , 7px);
        font-weight: 400;
        line-height: max(1.944vh , 10.5px);
        color: white;
    }
    .content-text2 span {
        font-size: max(1.296vh , 7px);
        font-weight: 900;
        line-height: max(1.944vh , 10.5px);
        color: white;
    }
    .social_links {
        width: max(4vh, 21.6px);
        text-align: center;
        cursor: pointer;
        position: relative;
        top: max(4vh,54.5px);
        left: max(6vh,29px);
    }
    .social1 {
        margin-bottom: max(3.241vh , 17.5px); 
        width: max(2.407vh, 13px);
        height: max(1.852vh, 10px);
    }
    .social2 {
        margin-bottom: max(3.241vh , 17.5px);   
        width: max(2.444vh, 13.2px);
        height: max(2.444vh, 13.195px);
    }
    .social3 {
        margin-bottom: max(3.241vh, 17.5px);    
        width: max(2.778vh, 15px);
        height: max(2.13vh, 11.5px);
    }
    .btn {
        margin-top: max(2.315vh, 12.5px);
        margin-left: 38.8vh;
    }
    .scroll_div {
        display: flex;
        margin-left: 22vh;
        align-items: end;
    }
    .scroll_div p {
        font-size: max(1.667vh, 9px);
        font-weight: 700;
        line-height: max(1.857vh, 10.03px);
        color: white;
        margin: 0;
    }
    .scroll_div img {
        width: max(6.574vh, 35.5px);
        margin-bottom: 0.4vh;
        margin-left: max(2vh , 15px); 
    }
</style>
