<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
</script>
<section>
    <div class="maindiv">
        <div class="backimg">
            <img src="/images/Artist/backimg.png" alt="" width="100%" height="100%">
        </div>
        <div class="menimg">
            <img src="/images/Artist/men.png" alt="" width="100%" height="100%">
        </div>
        <div class="onediv">
            <p>5</p>
        </div>
        <div class="virtualhead">
            <p>Virtual Fashion &<br> Apparel Designers</p>
        </div>
        <div class="descriptiontext" >
            <p>For the first time ever, we’re giving fashion designers a front row seat into the world of gaming and metaverse. </p>
                <p style="padding: 1.85vh 0;">No longer will the characters be wearing skin tight bodysuits (unless if that’s the fashion), rather it’s the time to bring them equal to their human counterparts.</p>
                
               <p style="font-weight: 700;">Our simulation technology enables clothes and accessories to demonstrate near-to-real physics using state-of-the-art technology. The future is here and you’re invited to be part of it.</p>  
        </div>
        <div class="btn">
            <DesktopButton2 
                state = "Artists & Musicians"
                AnimationColor='#00FFFF' 
                text1='Join Visual Art Governance Council'
                text3='25'
                TextColorAfter='black'
                text2='(Character Seat)'/>
        </div>
    </div>
</section>
<style>
    .maindiv{
        width: max(195.556vh , 1056px);
        height: max(100vh, 540px);
        background-color: #59E7F1;
        position: relative;
        font-family: 'Orbitron';
    }
    .backimg{
        width: max(98.333vh , 532px);
        height: max(68.333vh , 568px);
        position: absolute;
        top: 0;
        right: max(10.463vh , 56.5px);
    }
    .menimg{
        width: max(107.870vh , 582.5px);
        height: max(96.852vh , 523px);
        position: absolute;
        bottom: 0;
        right: max(4.444vh , 24px);
    }
    .menimg::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0)(255, 255, 255);
    }
    p{
        margin: 0;
    }
    .onediv{
        font-size: max(55.556vh , 300px);
        color: rgba(255, 255, 255, 0.3);
        text-transform: uppercase;
        font-weight: 700;
        line-height: max(55.556vh , 300px);
        position: absolute;
        left: max(8.889vh , 48px);
        top: max(2.315vh , 12.5px);
    }
    .virtualhead{
        font-weight: 700;
        font-size: max(5.556vh , 30px);
        line-height: max(5.556vh , 30px);
        text-transform: uppercase;
        color: #000000;
        letter-spacing: 0.25vh;
        position: absolute;
        left: max(24.444vh , 232px);
        top: max(29vh , 167px);
    }
    .descriptiontext{
        position: absolute;
        left: max(15.741vh , 85px);
        top: max(55.685vh , 311.5px);
        width: max(73.963vh , 392px);
        font-size: max(1.852vh ,10px);
        font-weight: 400;
        line-height: max(2.593vh , 14px);
        color: #000000;
        letter-spacing: 0.12vh;
    }
    .btn{
        position: absolute;
        left: max(15.741vh , 85px);
        top: max(84.167vh , 454.5px);
    }
</style>