<script>
	let divv;
	export let view_port_height, scroll_y;
	let distance_from_top, distance_from_bottom;

	// we will use distance from top and bottom
	$: if (divv && scroll_y && view_port_height) {
		distance_from_top = divv.offsetTop - scroll_y;
		distance_from_bottom = divv.offsetTop - scroll_y - view_port_height;

		console.log('------------------------');
		console.log('offset',divv.offsetTop);
		console.log('offset',divv.offsetTop);
		console.log('scroll_y', scroll_y); 
		console.log('view_port_height', view_port_height);
		console.log('distance_from_top', distance_from_top);
		console.log('distance_from_bottom', distance_from_bottom);
	}
</script>
<div class="main">
	<div class="d1"></div>
	<div class="d2"></div>
	<div class="d3" bind:this={divv}></div>
	<div class="d1"></div>
	<div class="d2"></div>

</div>

<style>
	div {
		height: 130vh;
		width: 100%;
	}
	.d1 {background-color: red;}
	.d2 {background-color: blue;}
	.d3 {background-color: yellow;}

</style>