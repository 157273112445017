<script>
    import DesktopButton2 from '../../reusableitems/DesktopButton2.svelte';
    let image;
</script>

<div class="main-div">
    <div class="image" bind:this={image}>
        <img
            src="/images/createmain/section4.png"
            alt=""
            height="100%"
            width="100%"
        />
    </div>
    <div
        class="second-div"
        on:mouseenter={() => {
            image.style.filter = 'none';
        }}
        on:mouseleave={() => {
            image.style.filter = 'grayscale(1)';
        }}
    >
        <p class="text1">STORYTELLERS & Opus</p>
        <p class="text2">
            FICTION
            <br />
            WRITERS
        </p>
        <p class="text3">
            Whether you are particular about how it should have ended or
            disagree on how little action was given to your favorite character
            in a series, you don’t idly sit, you alter, extend and add depth to
            your favorite story and characters.
        </p>

        <p class="text4">
            We’re building a platform that makes producing movies and games just
            as much a collaborative process for everyone as fanfiction writing
            is. More importantly, the derivatives are just as real as the
            original.

            <br />
            <br />
            Fix that ending, join our governing council now!
        </p>
        <div style="margin-top:max(5vh, 27px);">
            <DesktopButton2
                state = "StoryTeller"
                text1="Join Storytellers Governace Council"
                text2="(Fiction Author Seat)"
                AnimationColor = #A53BD6
                width="max(28.611vh, 154.5px)"
                BorderColorAfter="0.0052vw solid #A53BD6"
            />
        </div>
    </div>
</div>

<style>
    p {
        font-family: "Orbitron";
    }
    .main-div {
        width: max(145.463vh, 785.5px);
        height: 100vh;
        position: relative;
    }
    .second-div {
        width: max(48.611vh, 262.5px);
        position: absolute;
        height: max(92.87vh, 501.5px);
        background-color: white;
        top: 0;
        left: max(16.019vh, 86.5px);
        padding-left: max(5.926vh, 32px);
    }
    .text1 {
        font-size: max(1.296vh, 7px);
        font-weight: 400;
        padding-top: max(24.352vh, 131.5px);
    }
    .text2 {
        color: #a53bd6;
        font-size: max(5.556vh, 30px);
        line-height: max(5.556vh, 30px);
        font-weight: 800;
        margin: 0;
    }
    .text3 {
        font-size: max(1.296vh, 7px);
        font-weight: 400;
        width: max(37.037vh, 200px);
        margin-top: max(6.204vh, 33.5px);
    }
    .text4 {
        font-size: max(1.296vh, 7px);
        width: max(39.722vh, 214.5px);
        font-weight: 700;
    }
    .image {
        transition-duration: 1.5s;
        filter: grayscale(1);
        width: max(145.463vh, 785.5px);
        height: 100%;
    }
    .image::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0) (255, 255, 255);
    }
</style>
