<script>
    export let fontColor = '';
    export let prevWord = '';
    export let current = '';
    let move = 0;
    let gap = 0;
    let start = 0;
    let translateAmount;
    let translateAmountEnd;
    let index = 0;
    const alpha = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
        'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const widths =['max(8vh,43.2px)', 'max(8.1vh,43.74px)', 'max(8.1vh,43.47px)', 'max(8.08vh,43.632px)',
        'max(7.5vh,40.5px)', 'max(7.5vh,40.5px)', 'max(8vh,43.2px)', 'max(8.2vh,44.28px)', 'max(1.9vh,10.26px)',
        'max(7.5vh,40.55px)', 'max(7.9vh,42.66px)', 'max(8vh,43.2px)', 'max(9vh,48.6px)', 'max(8vh,43.2px)',
        'max(8vh,43.2px)', 'max(8.1vh,43.74px)', 'max(8.9vh,48.06px)', 'max(8.1vh,43.74px)', 'max(8.1vh,43.74px)',
        'max(7.8vh,42.12px)', 'max(8.1vh,43.74px)', 'max(10.3vh,55.62px)', 'max(11vh,59.4px)', 'max(8vh,43.2px)',
        'max(8.4vh,45.36px)', 'max(8vh,43.2px)'];
    for (let i=0; i<alpha.length; i++) {
        if (prevWord < current) {
            move++;
            if (prevWord < alpha[i]) {
                gap++;
            }
            if (alpha[i]===current) {
                index = i;
                translateAmountEnd = gap* -8.5;
                translateAmount = translateAmountEnd -4;
                break;
            }
        } else {
            if (current === alpha[i]) {
                index = i;
                start = i;
                move++;
            }
            if (current < alpha[i]) {
                move++;
                gap++;
            }
            if (alpha[i]===prevWord) {
                translateAmountEnd = gap * -7;
                translateAmount = translateAmountEnd -4;
                break;
            }
        }
    }
</script>

<div class="ContentHolder">
    {#each Array(move) as _,i}
        {#if prevWord < current}
            {#if prevWord <= alpha[i]}
                <h1 class="moving" style="background-color: 'white';color:{fontColor}; --translateEnd: {translateAmountEnd}vh; --translate: {translateAmount}vh; width: {widths[index]};" >{alpha[i].toUpperCase()}</h1>
            {/if}
            {:else if prevWord > current}
                <h1 class="moving-up" style="background-color: 'white';color:{fontColor}; --translateEnd: {translateAmountEnd}vh; width: {widths[index]};" >{alpha[start+i].toUpperCase()}</h1>
            {:else if prevWord === current}
            <h1 class="moving" style="background-color: 'white';color:{fontColor}; --translateEnd: {translateAmountEnd}vh; --translate: {translateAmountEnd}vh; width: {widths[index]};" >{current.toUpperCase()}</h1>
        {/if}
    {/each} 
</div>
<style>
    .ContentHolder {
        font-family: "Orbitron";
        height: 7.5vh;
        overflow: hidden;
        background-color: black;
    }
    .moving {
        margin: 0;
        height: max(7.5vh,48.6px);
        font-size: max(9.259vh, 50px);
        padding-bottom: max(1vh,2.7px);
        font-weight: 800;
        line-height: 7vh;
        animation: scroll 2s 0.001s ;
        animation-fill-mode: forwards;
    }
    @keyframes scroll {
        0% {
            transform: translateY(0vh);
        }
        50% {
            transform: translateY(var(--translate));
        }
        100% {
            transform: translateY(var(--translateEnd));
        }
    }
    .moving-up {
        padding-bottom: max(1vh,2.7px);
        height: max(7.5vh,40.5px);
        margin: 0;
        font-size: max(9.259vh, 50px);
        font-weight: 800;
        line-height: 7vh;
        animation: scroll-up 2s 0.001s ;
        animation-fill-mode: forwards;
    }
    @keyframes scroll-up {
        0% {
            transform: translateY(var(--translateEnd));
        }
        50% {
            transform: translateY(3.5vh);
        }
        100% {
            transform: translateY(0vh);
        }
    }
</style>
